import { Dispatch, LAB_TECHNICIAN_RESPONSE_SCHEMA } from '../../services';

export const Technician = {
	CLEAR_TECHNICIAN_STATE: '@store/clear-technician-state',
	ADD_LAB_TECHNICIAN_SUCCESS: '@@api/post-lab-technician-success',
	ADD_LAB_TECHNICIAN_FAIL: '@@api/post-lab-technician-fail',
	STORE_LAB_TECHNICIANS: 'STORE_LAB_TECHNICIANS'
};

export interface TechnicianState {
	technician: LAB_TECHNICIAN_RESPONSE_SCHEMA | null;
	error: any;
	technicians: Array<LAB_TECHNICIAN_RESPONSE_SCHEMA> | null;
}

const defaultState: TechnicianState = {
	technician: null,
	error: null,
	technicians: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Technician.ADD_LAB_TECHNICIAN_SUCCESS:
			const newtechnician = action.payload;
			const newTechnicians = (state.technicians || []).concat(newtechnician);
			return {
				...state,
				technician: newtechnician,
				technicians: newTechnicians,
				error: null
			};

		case Technician.ADD_LAB_TECHNICIAN_FAIL:
			return { ...state, technician: null, error: action.payload };

		case Technician.CLEAR_TECHNICIAN_STATE:
			return { ...state, ...defaultState };

		case Technician.STORE_LAB_TECHNICIANS:
			return { ...state, technicians: action.payload, error: null };

		default:
			return state;
	}
};
