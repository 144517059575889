import React from 'react';
import Label from './Label';

interface IProps {
	cross?: boolean;
	label?: string;
	check?: boolean;
}

export default function Checkbox({ cross, check, label }: IProps) {
	function renderChecked() {
		if (cross) return renderCross();
		if (check) return renderCheck();
		return null;
	}

	function renderCross() {
		return (
			<>
				<div style={styles.checkboxCrossCheckLeftDiagonal}></div>
				<div style={styles.checkboxCrossCheckRightDiagonal}></div>
			</>
		);
	}

	function renderCheck() {
		return (
			<>
				<div style={styles.checkLeft}></div>
				<div style={styles.checkRight}></div>
			</>
		);
	}

	return (
		<div style={styles.checkboxContainer}>
			<div style={styles.checkboxItem}>{renderChecked()}</div>
			{label && <Label style={{ marginLeft: '5pt' }}>{label}</Label>}
		</div>
	);
}

const styles = {
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	checkboxItem: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#000',
		width: '10pt',
		height: '10pt',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	checkboxCrossCheckRightDiagonal: {
		width: 1,
		height: 12,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#000',
		transform: 'rotate(45deg)',
		marginLeft: -1,
		borderRight: 'none'
	},
	checkboxCrossCheckLeftDiagonal: {
		width: 1,
		height: 12,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#000',
		transform: 'rotate(-45deg)',
		borderLeft: 'none'
	},

	checkLeft: {
		width: 1,
		height: 6,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#000',
		transform: 'translateX(-3px) translateY(2px) rotate(-45deg)',
		borderLeft: 'none'
	},
	checkRight: {
		width: 1,
		height: 9,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#000',
		transform: 'translateX(2px) rotate(45deg)',
		borderRight: 'none'
	}
} as const;
