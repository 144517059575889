import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import NhisScheme from '../NHISClaimForm/NHIS';

interface UserClaimFormProps {
	request: SAMPLES_RESPONSE_SCHEMA;
	user: NonNullable<AppStore['user']['user']>;
	monthOfClaim: string | Date;
}

function UserClaimForm({ request, user, monthOfClaim }: UserClaimFormProps) {
	const lab = user?.lab_technician?.lab ?? {};

	return (
		<NhisScheme
			request={request}
			lab={lab}
			style={{ marginBottom: '1rem', pageBreakAfter: 'always' }}
			monthOfClaim={monthOfClaim}
		/>
	);
}

export default UserClaimForm;
