import { useEffect, useReducer } from 'react';
import { PaginationProps } from 'semantic-ui-react';

type State<T> = {
	data: T[];
	dataToRender: T[];
	activePage: number;
	totalPages: number;
	pageSize: number;
	dataSize: number;
};

type UsePagination<T> = State<T> & { onPageChange: (_: any, props: PaginationProps) => void };

const PaginationActionTypes = {
	STORE_DATA_IN_STATE: 'STORE_DATA_IN_STATE',
	PAGE_CHANGE: 'PAGE_CHANGE'
};

const initialState: State<any> = {
	data: [],
	dataToRender: [],
	dataSize: 0,
	totalPages: 0,
	pageSize: 10,
	activePage: 1
};

const paginationReducer = <T>(state = initialState, { type, payload }: any): State<T> => {
	switch (type) {
		case PaginationActionTypes.STORE_DATA_IN_STATE: {
			const start = (state.activePage - 1) * payload.pageSize;
			const dataToRender = payload.data.slice(start, start + payload.pageSize);
			const totalPages = Math.ceil(payload.data.length / payload.pageSize);
			return {
				...state,
				data: payload.data,
				dataToRender,
				dataSize: payload.data.length,
				totalPages,
				pageSize: payload.pageSize
			};
		}

		case PaginationActionTypes.PAGE_CHANGE: {
			const start = (payload - 1) * state.pageSize;
			const dataToRender = state.data.slice(start, start + state.pageSize);
			return { ...state, dataToRender, activePage: payload };
		}

		default:
			return state;
	}
};

const usePagination = <T>(originalData: T[], pageSize = 16): UsePagination<T> => {
	const [state, dispatch] = useReducer(paginationReducer, initialState);

	useEffect(() => {
		dispatch({
			type: PaginationActionTypes.STORE_DATA_IN_STATE,
			payload: { data: originalData || [], pageSize }
		});
	}, [originalData, dispatch, pageSize]);

	const onPageChange = (_: any, props: PaginationProps) => {
		const { activePage } = props;
		dispatch({ type: PaginationActionTypes.PAGE_CHANGE, payload: activePage });
	};

	return { ...(state as State<T>), onPageChange };
};

export default usePagination;
