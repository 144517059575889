import { Dispatch, REGION_RESPONSE_SCHEMA } from '../../services';

export type Level = 'region' | 'district' | 'sub_district' | 'community' | string;

const Levels = {
	DISTRICT: 'district',
	SUB_DISTRICT: 'sub_district',
	COMMUNITY: 'community',
	REGION: 'region'
};

export const Region = {
	STORE_REGION_IN_STORE: '@store/load-region-to-store',
	SET_FORM_REGION: '@store/set-form-region',
	SET_FORM_DISTRICT: '@store/set-form-district',
	SET_FORM_SUB_DISTRICT: '@store/set-form-sub-district',
	SET_FORM_COMMUNITY: '@store/set-form-community',
	CLEAR_LEVEL_STATE: '@store/clear-level-state',
	CLEAR_FORM_STATE: '@store/clear-form-state',
	CLEAR_ERROR_STATE: '@@api/clear-level-error',
	ADD_LEVEL_SUCCESS: '@@api/post-level-success',
	ADD_LEVEL_FAIL: '@@api/post-level-fail',
	STORE_REGIONS: 'STORE_REGIONS'
};

export interface RegionState {
	region: REGION_RESPONSE_SCHEMA | null;
	level: Level | string;
	id: string | null;
	error: any;
	places: Array<string>;
	regions: Array<REGION_RESPONSE_SCHEMA> | null;
}

const defaultState: RegionState = {
	region: null,
	level: Levels.REGION,
	id: null,
	error: null,
	places: [],
	regions: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Region.STORE_REGION_IN_STORE:
			return { ...state, ...defaultState, region: action.payload, level: Levels.REGION };

		case Region.SET_FORM_REGION:
			return { ...state, level: Levels.REGION, id: null, error: null };

		case Region.SET_FORM_DISTRICT:
			return {
				...state,
				level: Levels.DISTRICT,
				id: action.payload.id,
				error: null
			};

		case Region.SET_FORM_SUB_DISTRICT:
			return {
				...state,
				level: Levels.SUB_DISTRICT,
				id: action.payload.id,
				error: null
			};

		case Region.SET_FORM_COMMUNITY:
			return {
				...state,
				level: Levels.COMMUNITY,
				id: action.payload.id,
				error: null
			};

		case Region.CLEAR_LEVEL_STATE:
			return { ...state, ...defaultState };

		case Region.CLEAR_ERROR_STATE:
			return { ...state, error: null };

		case Region.ADD_LEVEL_SUCCESS:
			return { ...state, error: null, region: action.payload };

		case Region.ADD_LEVEL_FAIL:
			return { ...state, region: null, error: action.payload };

		case Region.CLEAR_FORM_STATE:
			return { ...state, id: null, error: null, level: Levels.REGION };

		case Region.STORE_REGIONS:
			return { ...state, error: null, regions: action.payload };

		default:
			return state;
	}
};
