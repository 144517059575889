import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Constants } from '../constants';
import { AppStore } from '../store';
import {
	REGION_RESPONSE_SCHEMA,
	SAMPLES_RESPONSE_SCHEMA,
	USERS_RESPONSE_SCHEMA
} from './schemeService';

type TUser = AppStore['user']['user'] | USERS_RESPONSE_SCHEMA;
type TDate = number | string | null | Date;
type TItem<T> = (item: T) => string;

/**
 * the data structure for the option used in semantic select
 * component
 */
interface IOption {
	text: string;
	value: string;
	key: string;
}

/**
 * convertToReadable removes all code connection strings
 * from the pass param into a human readable format
 * @param data string
 */
export const convertToReadable = (data: string) => {
	const readableText = data.replace(/[^a-zA-Z0-9]/g, ' ').replace("'", '');
	const firstLetter = readableText.charAt(0).toUpperCase();
	return firstLetter + readableText.substring(1);
};

/**
 * getFullName returns the name of the user by extracting
 * relevant property that make up the name such as first_name
 * @param data T
 */
export const getFullName = <T extends { user: TUser }>(data?: T) => {
	if (data && data.user) {
		const { first_name, middle_name, surname } = data.user;
		const name = `${surname} ${first_name} ${middle_name || ''}`.trim();
		return name;
	}
	return 'N/A';
};

/**
 * convertToReadableDate formats the date into a readable
 * format for the user such as converting a timestamp into
 * Month Day Year format
 * @param date number | string
 */
export const convertToReadableDate = (date: TDate, shortenDate = false, isTime = false) => {
	if (!date) {
		return 'Not available';
	}
	const formatDateStructure = isTime ? 'LTS' : shortenDate ? 'l' : 'LL';
	const formatDate = moment(date).format(formatDateStructure);
	return formatDate;
};

/**
 * getUserDetails is used to get the readable data from the user
 * data such as telephone, gender, and others
 * @param data T | null
 */
export const getUserDetails = <T extends { user: TUser }>(data?: T) => {
	if (data && data.user) {
		const { dob, gender, telephone } = data.user;
		return {
			name: getFullName(data),
			'date Of Birth': convertToReadableDate(dob),
			gender: (gender as string).toLowerCase().match('m') ? 'Male' : 'Female',
			telephone: telephone
		};
	}
	return {
		name: 'N/A',
		'date Of Birth': 'N/A',
		gender: 'N/A',
		telephone: 'N/A'
	};
};

/**
 * getDateTimestamp returns the timestamp of the date given
 * @param date string
 */
export const getDateTimestamp = (date: string) => {
	return new Date(date).getTime();
};

export const convertListToDropdownItems = <T>(list: T[], callback?: TItem<T>): IOption[] => {
	return list.length < 1
		? []
		: list.map((item: any) => ({
				text: (callback && callback(item as T)) || item.name,
				value: item.id,
				key: item.id
		  }));
};

/**
 * checks if the item should be displayed or not when found to
 * be an object or the item is a hidden item
 * @param obj object
 * @param key string
 */
export const isHidden = (obj: { [key: string]: any }, key: string): boolean => {
	return typeof obj[key] === 'object' || Constants.app.data.HIDDEN_FIELD.includes(key);
};

/**
 * calculates the age of the person from the current date
 * the calculation is based on the timestamp of the date
 * @param date string | number
 */
export const calculateUserAge = (date: string | number): number => {
	const age = new Date().getFullYear() - new Date(date).getFullYear();
	return age;
};

export const getName = (data: any) => {
	if (!data) return null;
	return data.name ? data.name : getFullName(data);
};

/**
 * the function converts the date into the date picker format
 * that the DatePicker component can make use of
 * @param date date
 */
export const convertToDatePickerFormat = (date: TDate) => {
	if (!date) return '';
	return moment(date).format('YYYY-MM-DD');
};

/**
 * the function returns the status of the user in the system
 * such as whether the user is a technician, administrator, etc.
 * if the user does not have a status in the application an
 * empty string is returned
 * @param data user
 */
export const getUserStatus = <T extends { user: TUser }>(data: T): string => {
	const { user } = data;
	if (isEmpty(user)) {
		return '';
	}
	const status = {
		PRIVILEGE_LAB_TECHNICIAN: 'Technician',
		PRIVILEGE_LAB_ADMIN: 'Administrator',
		PRIVILEGE_CLINIC_ADMIN: 'Administrator'
	};
	return (status as any)[(data as any).privileges[0]];
};

/**
 * the function returns the id and the list of places associate
 * to the name passed to it such as getting the id and districts
 * when the name of a region and district is given
 * @param regions regions stored in database
 */
export const getLevelsFromName = (regions: REGION_RESPONSE_SCHEMA[]) => (regionName: string) => (
	districtName: string
) => (subDistrictName: string) => (communityName: string) => {
	const region = regions.find(({ name }) => name === regionName);
	const district = region && region.districts.find(({ name }) => name === districtName);
	const subDistrict =
		district && district.sub_districts.find(({ name }) => name === subDistrictName);
	const community =
		subDistrict && subDistrict.communities.find(({ name }) => name === communityName);
	return {
		region: {
			id: (region && region.id) || '',
			items: regions
		},
		district: {
			id: (district && district.id) || '',
			items: (region && region.districts) || []
		},
		subDistrict: {
			id: (subDistrict && subDistrict.id) || '',
			items: (district && district.sub_districts) || []
		},
		community: {
			id: (community && community.id) || '',
			items: (subDistrict && subDistrict.communities) || []
		}
	};
};

/**
 * the function sorts the list of items by the name property,
 * it's a callback function passed to the sort array method to
 * iterate through the array.
 * @param {any} prevValue
 * @param {any} currValue
 * @returns {number} sorting value
 */
export const sortByName = (prevValue: any, currValue: any) => {
	return getName(prevValue) - getName(currValue);
};

export const getValuesFromKey = (obj: any): any[] => {
	if (Array.isArray(obj)) {
		return obj;
	}
	const [data] = Object.values(obj);
	return data as any;
};

export const convertRequestToListFormat = (requests: SAMPLES_RESPONSE_SCHEMA[]): any[] => {
	return requests.map((request) => {
		const {
			patient: { midwife: requester, ...patient },
			...other
		} = request;
		return { ...other, requester, patient };
	});
};

// function get the age of the user
export const getCurrentAgeFromDOB = (date: string | number) => {
	const dateYear = new Date(date).getFullYear();
	const currentYear = new Date().getFullYear();
	return currentYear - dateYear;
};
