import { Constants } from '../constants';

export const NotifcationSubscription = {
	userId: ''
};

export const subscribeToNotifications = () => {
	const OneSignal = (window as any).OneSignal || [];
	OneSignal.push(function() {
		OneSignal.init({
			appId: Constants.api.onesignal.APP_ID,
			autoResubscribe: true,
			notifyButton: {
				enable: true
			}
		});

		OneSignal.setSubscription(true);

		OneSignal.getUserId(function(userId: string) {
			NotifcationSubscription.userId = userId;
		});
	});
};
