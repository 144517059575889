import React from 'react';
import { connect } from 'react-redux';
import curvy from '../../resource/assets/images/curvy.svg';
import { Navbar } from '../../resource/components';
import { Constants as K } from '../../resource/constants';
import { useAuthenticatedUser } from '../../resource/hooks';
import { AppStore } from '../../resource/store';
import { pagesRoutes } from '../routes';
import './css/home.css';
import Showcase from './Showcase';

export const Landing = () => {
	const { authenticatedStatus, authenticatedUserRoute, user } = useAuthenticatedUser();

	return (
		<div className="landing wrapper" style={styles.landing}>
			<Navbar
				authenticatedUser={user}
				authenticatedUserRoute={authenticatedUserRoute}
				activePageUrl={K.app.routes.HOME}
				pagesRoutes={pagesRoutes}
			/>
			<Showcase text={authenticatedStatus} url={authenticatedUserRoute} />
		</div>
	);
};

const styles = {
	landing: {
		backgroundImage: `url(${curvy})`
	}
};

const mapStateToProps = (state: AppStore) => ({
	user: state.user.user
});

export default connect(mapStateToProps)(Landing);
