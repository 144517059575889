import PropTypes from 'prop-types';
import React from 'react';
import { FormCheckbox, Icon, Table } from 'semantic-ui-react';
import { TableWrap } from '../../../resource/components';
import { getFullName, SAMPLES_RESPONSE_SCHEMA, STATUS_SCHEMA } from '../../../resource/services';
import { formatRequestDate } from '../_helpers';
import './css/requests.css';

type Props = {
	requests: SAMPLES_RESPONSE_SCHEMA[];
	onRequestSelect: (request: SAMPLES_RESPONSE_SCHEMA) => void;
	activePage: number;
	pageSize: number;
	onSelectDelete: (isChecked: boolean, request: SAMPLES_RESPONSE_SCHEMA) => void;
	isDelete: boolean;
};

const StatusColorCodes: any = {
	BAD: '#ff0000',
	OK: '#00ff00',
	PENDING: '#ffaa00',
	PARTIAL_PENDING: '#74287b',
};

const statusColor = (status: STATUS_SCHEMA) => {
	return {
		background: StatusColorCodes[status],
		width: '10px',
		height: '10px',
		borderRadius: '50%',
		margin: 'auto',
	};
};

const RequestsListView = (props: Props) => {
	const { requests, onRequestSelect, activePage, pageSize, onSelectDelete, isDelete } = props;
	const renderBody = () => {
		return requests.map((item: SAMPLES_RESPONSE_SCHEMA, i: number) => {
			const number = (activePage - 1) * pageSize + i + 1;
			return (
				<Table.Row key={item.id} onClick={() => !isDelete && onRequestSelect(item)}>
					<Table.Cell>
						{isDelete ? (
							<FormCheckbox
								disabled={item.status !== 'PENDING'}
								onChange={(_: any, data) => {
									onSelectDelete(!!data.checked, item);
								}}
							/>
						) : (
							number
						)}
					</Table.Cell>
					<Table.Cell style={styles.cell}>{getFullName(item.patient)}</Table.Cell>
					<Table.Cell style={styles.cell}>{item.clinic.name}</Table.Cell>
					<Table.Cell style={styles.cell}>{getFullName(item.midwife)}</Table.Cell>
					<Table.Cell style={styles.cell}>
						{item.has_insurance ? 'Insured' : 'Uninsured'}
					</Table.Cell>
					<Table.Cell style={styles.cell}>{item.sample_code}</Table.Cell>
					<Table.Cell style={styles.cell}>
						{formatRequestDate(item.date_of_request)}
					</Table.Cell>
					<Table.Cell style={styles.cell}>
						{item.rider &&
							(item.status === 'PENDING' || item.status === 'PARTIAL_PENDING') && (
								<Icon
									name="check"
									style={{ ...styles.pickedSample, color: 'green' }}
								/>
							)}
						<div style={statusColor(item.status)} />
					</Table.Cell>
				</Table.Row>
			);
		});
	};

	return (
		<TableWrap>
			<Table basic="very" sortable selectable>
				<Table.Header>
					<Table.Row style={{ backgroundColor: 'teal' }}>
						<Table.HeaderCell style={{ ...styles.header, width: '1.7rem' }}>
							#
						</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header, width: '10rem' }}>
							Client
						</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header }}>Clinic</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header }}>
							Requested By
						</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header }}>Insurance</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header }}>
							Identification Code
						</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header, width: '7.5rem' }}>
							Date
						</Table.HeaderCell>
						<Table.HeaderCell style={{ ...styles.header, width: '1rem' }} />
					</Table.Row>
				</Table.Header>
				<Table.Body>{renderBody()}</Table.Body>
			</Table>
		</TableWrap>
	);
};

const styles = {
	header: {
		fontWeight: 'normal',
		fontFamily: 'Open sans',
		fontSize: '1.2rem',
		padding: '0.75rem 1rem',
		color: 'white',
	},
	cell: {
		padding: '1.15rem 1rem',
	},
	pickedSample: {
		color: '#0056ff',
		marginBottom: '0.75rem',
		marginRight: '0',
		fontSize: '1.117rem',
	},
};

RequestsListView.propTypes = {
	requests: PropTypes.arrayOf(PropTypes.object).isRequired,
	onRequestSelect: PropTypes.func.isRequired,
};

export default React.memo(RequestsListView);
