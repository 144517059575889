import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Pagination } from 'semantic-ui-react';
import { EmptyState, Widget } from '../../../resource/components';
import { usePagination } from '../../../resource/hooks';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import RequestsListView from './RequestsListView';

type Props = {
	requests: SAMPLES_RESPONSE_SCHEMA[];
	onRequestSelect: (request: SAMPLES_RESPONSE_SCHEMA) => void;
	onSelectDelete: (isChecked: boolean, request: SAMPLES_RESPONSE_SCHEMA) => void;
	isDelete: boolean;
};

const RequestsList = (props: Props) => {
	const { requests, onRequestSelect, onSelectDelete, isDelete } = props;
	const {
		dataToRender,
		pageSize,
		dataSize,
		onPageChange,
		activePage,
		totalPages
	} = usePagination(requests);

	if (isEmpty(requests)) {
		return (
			<EmptyState
				title="No requests"
				description="There are no pending sample request made to laboratory"
			/>
		);
	}
	return (
		<Widget className="sample list">
			<RequestsListView
				requests={dataToRender}
				activePage={activePage}
				pageSize={pageSize}
				onRequestSelect={onRequestSelect}
				onSelectDelete={onSelectDelete}
				isDelete={isDelete}
			/>
			{dataSize > pageSize && (
				<div className="pagination">
					<Pagination
						totalPages={totalPages}
						activePage={activePage}
						onPageChange={onPageChange}
						firstItem={null}
						lastItem={null}
					/>
				</div>
			)}
		</Widget>
	);
};

RequestsList.propTypes = {
	requests: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RequestsList;
