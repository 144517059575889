import React, { ReactNode } from 'react';
import { Header } from 'semantic-ui-react';
import './css/maincontent.css';
import { Container } from './Container';

type Props = {
	title: string;
	toolbar?: ReactNode;
	description?: string;
	children: ReactNode;
};

export const MainContent = (props: Props) => (
	<div className="main-content">
		<nav className="nav">
			<Container className="navbar">
				<Header className="main">
					<div className="headings">
						<span className="title">{props.title}</span>
						<span className="subTitle">{props.description}</span>
					</div>
					{props.toolbar}
				</Header>
			</Container>
		</nav>
		<div className="view-content">{props.children}</div>
	</div>
);
