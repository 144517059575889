import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import SchemeDetail from './SchemeDetail';
import SchemesList from './SchemesList';
import ToolBar from './ToolBar';

const options: WrappedListViewOptions = {
	title: 'NHIS Schemes',
	form: Constants.app.routes.dashboard.NHIS_ADD,
	description: 'Manage the NHIS schemes in RDLS',
	storeKey: 'nhis',
	stateKey: 'nhiss'
};

export default wrappedAdvancedListView(
	SchemesList as any,
	SchemeDetail as any,
	ToolBar as any,
	options
);
