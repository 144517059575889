import React, { PureComponent, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';
import { Widget, EmptyState, TableWrap } from '../../../resource/components';
import { REGION_RESPONSE_SCHEMA } from '../../../resource/services';
import { Direction } from '../../../resource/hocs';

export interface IRegionListProps {
	data: Array<REGION_RESPONSE_SCHEMA> | null;
	onSort: (column: string) => void;
	sortedColumn: string;
	sortedDirection: Direction;
	onSelect: (data: REGION_RESPONSE_SCHEMA) => void;
	activePage: number;
	pageSize: number;
	dataToRender: Array<REGION_RESPONSE_SCHEMA> | null;
}

export default class RegionsList extends PureComponent<IRegionListProps> {
	public render(): ReactNode {
		return this.props.data ? (
			this.renderTable()
		) : (
			<EmptyState
				title="No Regions"
				description="There are no registered regions. Click on the add button to add a new region."
			/>
		);
	}

	/**
	 * renderTable is a method which renders a table with the
	 * list of regions attached with the total number of districts
	 * sub_districts and communitiies
	 * @returns ReactNode
	 */
	public renderTable = (): ReactNode => {
		const { sortedColumn, sortedDirection, activePage, pageSize, dataToRender } = this.props;
		return (
			<Widget>
				<TableWrap>
					<Table selectable sortable basic="very">
						<Table.Header>
							<Table.Row style={{ backgroundColor: 'teal' }}>
								<Table.HeaderCell style={{ ...styles.header, width: '1.7rem' }}>
									No.
								</Table.HeaderCell>
								<Table.HeaderCell
									style={styles.header}
									onClick={() => this.props.onSort('name')}
									sorted={sortedColumn === 'name' ? sortedDirection : undefined}
								>
									Name
								</Table.HeaderCell>
								<Table.HeaderCell style={styles.header}>Districts</Table.HeaderCell>
								<Table.HeaderCell style={styles.header}>
									Sub Districts
								</Table.HeaderCell>
								<Table.HeaderCell style={styles.header}>
									Communities
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{dataToRender &&
								dataToRender.map((region: REGION_RESPONSE_SCHEMA, i: number) => {
									const { districts } = region;
									const subDistricts = districts.reduce((acc, district) => {
										return acc + district.sub_districts.length;
									}, 0);
									const communities = districts.reduce((acc, district) => {
										return (
											acc +
											district.sub_districts.reduce((a, sub) => {
												return a + sub.communities.length;
											}, 0)
										);
									}, 0);
									return (
										<Table.Row
											style={styles.cell}
											key={region.id}
											onClick={() => this.props.onSelect(region)}
										>
											<Table.Cell>
												{(activePage - 1) * pageSize + i + 1}
											</Table.Cell>
											<Table.Cell style={styles.cell}>
												{region.name}
											</Table.Cell>
											<Table.Cell style={styles.cell}>
												{districts.length}
											</Table.Cell>
											<Table.Cell style={styles.cell}>
												{subDistricts}
											</Table.Cell>
											<Table.Cell style={styles.cell}>
												{communities}
											</Table.Cell>
										</Table.Row>
									);
								})}
						</Table.Body>
					</Table>
				</TableWrap>
			</Widget>
		);
	};
}
const styles: { [key: string]: React.CSSProperties } = {
	header: {
		fontWeight: 300,
		fontFamily: 'Open sans',
		fontSize: '1.14rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '0.85rem 1rem'
	},
	numCol: {
		width: '1.8rem'
	}
};
