import { Constants as SharedConstants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedConstants,
	form: UserForm.userDetails,
	work: [
		{
			title: '',
			path: SharedConstants.app.routes.dashboard.LAB_PROFILE_EDIT,
			description: 'Enter the new details to update the health facility',
			fields: [
				{
					type: 'text',
					name: 'name',
					placeholder: 'Enter laboratory name'
				},
				{
					type: 'tel',
					name: 'telephone',
					placeholder: 'Enter telephone of laboratory'
				}
			],
			showLevels: true,
			name: 'lab'
		},
		{
			path: SharedConstants.app.routes.dashboard.CLINIC_PROFILE_EDIT,
			title: '',
			description: 'Enter the new details to update the clinic',
			fields: [
				{
					type: 'text',
					name: 'name',
					placeholder: 'Name of clinic'
				},
				{
					type: 'text',
					name: 'clinic_code',
					placeholder: 'Clinic code'
				},
				{
					type: 'tel',
					name: 'telephone',
					placeholder: 'Clinic telephone number'
				}
			],
			showLevels: true,
			name: 'clinic'
		}
	]
};
