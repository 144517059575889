import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
	Alert,
	Container,
	MainContent,
	RestrictedAccess,
	Widget
} from '../../../resource/components';
import { AppStore } from '../../../resource/store';
import './css/form.css';
import Toolbar from './Toolbar';

interface Props extends RouteComponentProps {}

const ProfileForm: React.FC<Props> = props => {
	const [showStatus] = useState(false);
	const [loading] = useState(false);
	const user = useSelector(({ user: { user } }: AppStore) => user);
	const { history } = props;

	const onSave = () => {};

	const onCancel = () => {
		history.goBack();
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<MainContent
				title="Profile"
				description="Manage your profile settings"
				toolbar={
					<Toolbar
						loading={loading}
						disabled={!Boolean(user)}
						onCancel={onCancel}
						onSave={onSave}
					/>
				}
			>
				<Container>
					<Widget>
						<div className="profile edit-layout">
							<div className="form">Re design form</div>
						</div>
					</Widget>
				</Container>
				<Alert
					success
					open={showStatus}
					onClose={onCancel}
					title="Updated"
					message="Your profile has been updated successfully."
				/>
			</MainContent>
		</RestrictedAccess>
	);
};

export default ProfileForm;
