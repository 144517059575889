import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './css/tools.css';

type Props = {
	img: string;
	text: string;
	className: string;
	avatar?: boolean;
	size?: string;
};

const Image = (props: Props) => {
	const { className, img, text, avatar, size } = props;
	const initial = text.substr(0, 1).toUpperCase();
	let styling = `imagebox ${className || ''} ${size}`.trim();
	styling += avatar ? ' avatar' : '';
	return (
		<div className={styling}>
			{img ? (
				<img src={img} alt={text} className="image" />
			) : (
				<h2 className="text">{initial}</h2>
			)}
		</div>
	);
};

Image.propTypes = {
	img: PropTypes.string,
	text: PropTypes.string.isRequired,
	className: PropTypes.string,
	avatar: PropTypes.bool,
	size: PropTypes.string
};

Image.defaultProps = {
	img: '',
	className: '',
	avatar: false,
	size: ''
};

export default memo(Image);
