import { Constants as K } from '../../constants';
import { Dispatch, LAB_TEST_RESPONSE_SCHEMA } from '../../services';

export const LabTest = {
	STORE_LAB_TEST_SCHEME_TYPE: '@store/store-lab-test',
	CLEAR_LAB_TEST_SCHEME_TYPE: '@store/clear-lab-test-state',
	LAB_TEST_ADD_SUCCESS: '@@api/post-lab-test-success',
	LAB_TEST_ADD_FAIL: '@@api/post-lab-test-fail',
	STORE_LAB_TESTS_TYPE: '@@api/store-lab-tests',
	ADD_LAB_TEST_TO_STORE_TYPE: '@@api/add-lab-test',
	LOAD_LAB_TESTS_FAIL: '@@api/load-lab-tests-fail'
};

export interface LabTestState {
	test: LAB_TEST_RESPONSE_SCHEMA | null;
	error: any;
	tests: Array<LAB_TEST_RESPONSE_SCHEMA> | null;
}

const defaultState: LabTestState = {
	test: null,
	error: null,
	tests: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case LabTest.STORE_LAB_TEST_SCHEME_TYPE:
			const test = action.payload === null ? defaultState : { ...action.payload };
			return { ...state, test, error: null };

		case LabTest.LAB_TEST_ADD_SUCCESS:
			return { ...state, test: action.payload, error: null };

		case LabTest.LAB_TEST_ADD_FAIL:
			return { ...state, error: action.payload, test: null };

		case LabTest.STORE_LAB_TESTS_TYPE:
			return { ...state, tests: action.payload, error: null };

		case LabTest.ADD_LAB_TEST_TO_STORE_TYPE:
			const tests = (state.tests || []).concat(action.payload);
			return { ...state, tests, error: null };

		case K.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };

		case LabTest.CLEAR_LAB_TEST_SCHEME_TYPE:
			return { ...state, ...defaultState };

		default:
			return state;
	}
};
