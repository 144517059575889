export const Constants = {
	app: {
		APP_NAME: 'Rural Diagnostic Laboratory Service',
		SHORT_NAME: 'RDLS',
		PHRASE: 'Developed by Milliennum Promise Alliance',
		DATABASE_NAME: 'RDLS-LOCALDB',
		DATABASE_VERSION: 4,
		db: {
			LAB: 'lab',
			CLINIC: 'clinic',
			SCHEME: 'scheme',
			TEST: 'test',
			TECHNICIAN: 'technician',
			MIDWIFE: 'midwife',
			COURIER: 'courier',
			REGION: 'region',
			SAMPLE_TYPE: 'type',
			REQUEST: 'request'
		},
		routes: {
			HOME: '/',
			ACCOUNT: '/account',
			SIGNIN: '/signin',
			PRIVACY_POLICY: '/privacy-policy',
			dashboard: {
				DASHBOARD: '/dashboard',

				USER_PROFILE: '/dashboard/user/profile',
				USER_PROFILE_EDIT: '/dashboard/user/profile/edit',
				WORK_PROFILE_EDIT: '/dashboard/user/profile/work',
				LAB_PROFILE_EDIT: '/dashboard/user/profile/work/lab',
				CLINIC_PROFILE_EDIT: '/dashboard/user/profile/work/clinic',

				LABS: '/dashboard/labs',
				ADD_LAB: '/dashboard/labs/add',
				ADD_LAB_ADMIN: '/dashboard/labs/add/admin',
				VIEW_LAB: '/dashboard/labs/view/lab',

				CLINICS: '/dashboard/clinics',
				ADD_CLINIC: '/dashboard/clinics/add',
				ADD_CLINIC_DETAIL: '/dashboard/clinics/add/detail',
				ADD_CLINIC_ADMIN: '/dashboard/clinics/add/admin',

				NHIS_VIEW: '/dashboard/nhis',
				NHIS_EDIT: '/dashboard/nhis/edit',
				NHIS_ADD: '/dashboard/nhis/add',

				LAB_TESTS: '/dashboard/tests',
				LAB_TEST_ADD: '/dashboard/tests/add',
				LAB_TEST_EDIT: '/dashboard/tests/edit',

				SAMPLE_REQUESTS: '/dashboard/requests',
				SAMPLE_REQUEST_VIEW: '/dashboard/request',
				SAMPLE_REQUEST_PATIENT: '/dashboard/request/patient',
				SAMPLE_RESULT: '/dashboard/request/result',
				SAMPLE_REPORT: '/dashboard/request/report',
				SAMPLE_STATUS: '/dashboard/request/status',
				SUMMARY_REPORT: '/dashboard/request/summary/report',
				SAMPLE_COURIER_ASSIGN: '/dashboard/request/courier/assign',

				ADD_LAB_TECHNICIAN: '/dashboard/technicians/add',
				TECHNICIANS: '/dashboard/technicians',
				EDIT_TECHNICIAN: '/dashboard/technicians/edit',

				MIDWIFES: '/dashboard/midwifes',
				ADD_MIDWIFE: '/dashboard/midwifes/add',

				ADD_COURIER: '/dashboard/couriers/add',
				COURIERS: '/dashboard/couriers',

				REGIONS: '/dashboard/regions',
				ADD_REGION: '/dashboard/regions/add',
				ADD_DISTRICT: '/dashboard/region/districts/add',
				ADD_SUB_DISTRICT: '/dashboard/region/sub_districts/add',
				ADD_COMMUNITY: '/dashboard/region/community/add',
				VIEW_REGION: '/dashboard/region/view'
			}
		},
		theme: {
			PRIMARY: 'teal',
			SECONDARY: 'teal',
			ACCENT: '',
			GRAYSCALE: {
				light: '#f4f4f4',
				medium: '#777',
				dark: '#212121'
			},
			WHITE: '#fff',
			FONTS: {
				font: ['Open sans', 'Lato', 'sans-serif'].join(', ')
			},
			size: {
				MEDIUM: 'medium'
			}
		},
		data: {
			HIDDEN_FIELD: ['id', 'Privileges']
		}
	},
	store: {
		types: {
			DUMP_DATA_TYPE: '@store/dump-data',
			CLEAR_MISC_DATA_TYPE: '@store/clear-misc-state',
			SERVER_UPLOAD_SUCCESS: '@@api/server-misc-upload-success',
			SERVER_UPLOAD_FAILED: '@@api/server-misc-upload-fail',
			CLEAR_MISC_ERROR: '@store/clear-misc-error-state',
			STORE_EDIT_DATA_TYPE: '@store/update-misc-edit-data',
			CLEAR_EDIT_DATA: '@store/clear-miscc-edit-data',

			// global user state
			STORE_USER_DATA_TYPE: '@store/store-user-profile-state-type',
			DATA_STORE_SYNCHED: '@store/sync-data-store',
			DATA_STORE_SYNC_FAIL: '@store/sync-data-store-fail',
			CLEAR_DATA_STORE: '@store/clear-app-data-store',
			CLEAR_USER_CACHE: '@store/clear-user-cache'
		},
		filters: {
			edit: 'edit'
		}
	},
	api: {
		BASE_URL: 'https://rdls-bc9c5.appspot.com/api',
		routes: {
			USER_AUTHENTICATE: '/verify_user',

			ADD_LAB_TEST: '/lab/lab_test',
			GET_LAB_TESTS: '/lab/lab_test',
			DELETE_LAB_TEST: '/lab/lab_test',
			UPDATE_LAB_TEST: '/lab/lab_test',

			GET_LAB_REQUESTS: '/lab/samples',
			POST_LAB_TEST_RESPONSE: '/lab/sample/submit',
			CONFIRM_LAB_REQUEST: '/lab/samples/confirm',
			ASSIGN_RIDER: '/lab/sample/assign/multiple',
			DELETE_LAB_REQUESTS: '/clinic/samples/delete/bulk',

			GET_LABS: '/labs/all',
			POST_LAB: '/setup/lab',
			UPDATE_LAB: '/lab/update',
			DELETE_LAB: '/lab',

			POST_CLINIC: '/setup/clinic',
			GET_CLINICS: '/clinics/all',
			UPDATE_CLINIC: '/clinic/update',
			DELETE_CLINIC: '/clinic',

			UPDATE_ADMIN: '/admins/update',

			ADD_LAB_TECHNICIAN: '/labs/register/technician',
			UPDATE_LAB_TECHNICIAN: '/lab/technician/update',
			DELETE_LAB_TECHNICIAN: '/lab/technician/delete',
			GET_LAB_TECHNICIANS: '/lab/technicians/all',

			ADD_MIDWIFE: '/clinic/register/midwife',
			MIDWIVES: '/clinic/midwives',
			DELETE_MIDWIFE: '/clinic/midwife/delete',

			ADD_COURIER: '/lab/riders',
			DELETE_COURIER: '/lab/courier/delete',
			COURIERS: '/lab/riders/all',
			UPDATE_COURIER: '/lab/riders/update',

			REGIONS: '/regions/all',
			ADD_REGIONS: '/regions/register',
			ADD_DISTRICTS: '/regions/districts/register',
			ADD_SUB_DISTRICTS: '/regions/districts/sub_districts/register',
			ADD_COMMUNITY: '/regions/districts/sub_districts/communities/register',
			UPDATE_REGION: '/regions/update',
			UPDATE_DISTRICT: '/regions/districts/update',
			UPDATE_SUB_DISTRICT: '/regions/districts/sub_districts/update',
			UPDATE_COMMUNITY: '/regions/districts/sub_districts/communities/update',

			NHIS_SCHEMES: '/insurance',
			ADD_NHIS_SCHEME: '/insurance',
			UPDATE_SCHEME: '/insurance'
		},
		onesignal: {
			APP_ID: '55da2dde-e4ce-4a9b-be04-9cea4996e7b1',
			REST_API_KEY: 'M2NkNzBmYjQtODQ0YS00NzA3LWE3MDctNGVjYWMxNjIzMjM4',
			USER_AUTH_KEY: 'NTQzMmNjOWMtYTA1Zi00NTAxLTg5OTgtMjI3NDBjYTI0NWJl'
		}
	}
};
