import { COURIER_RESPONSE_SCHEMA, Dispatch } from '../../services';

export const Courier = {
	CLEAR_COURIER_STATE: '@store/clear-courier-state',
	ADD_COURIER_SUCCESS: '@@api/post-courier-success',
	ADD_COURIER_FAIL: '@@api/post-courier-fail',
	STORE_COURIERS: 'STORE_COURIERS'
};

export interface CourierState {
	courier: COURIER_RESPONSE_SCHEMA | null;
	error: any;
	couriers: Array<COURIER_RESPONSE_SCHEMA> | null;
}

const defaultState: CourierState = {
	courier: null,
	error: null,
	couriers: null
};

export default (state: CourierState = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Courier.ADD_COURIER_SUCCESS:
			const newCourier = action.payload;
			const newCouriers = (state.couriers! || []).concat(newCourier);
			return { ...state, courier: newCourier, couriers: newCouriers, error: null };

		case Courier.ADD_COURIER_FAIL:
			return { ...state, courier: null, error: action.payload };

		case Courier.CLEAR_COURIER_STATE:
			return { ...state, ...defaultState };

		case Courier.STORE_COURIERS:
			return { ...state, couriers: action.payload, error: null };

		default:
			return state;
	}
};
