import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
	Container,
	Loader as Loda,
	MainContent,
	RestrictedAccess
} from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import { getAllRegions, Storage } from '../../../resource/dataService';
import { Direction } from '../../../resource/hocs';
import { Access, REGION_RESPONSE_SCHEMA, USERS_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import { setFormToRegion, storeRegionInState } from '../_helpers';
import RegionsList from './RegionsList';
import Toolbar from './Toolbar';

export interface IRegionsProps extends RouteComponentProps {
	user: AppStore['user']['user'];
	storeRegionInState: (region: REGION_RESPONSE_SCHEMA) => void;
	setFormToRegion: () => void;
}

export interface IRegionsState {
	column: string;
	direction: Direction;
	activePage: number;
	pageSize: number;
	loading: boolean;
	data: Array<REGION_RESPONSE_SCHEMA> | null;
	dataToRender: Array<REGION_RESPONSE_SCHEMA> | null;
	dataSize: number;
}

class Regions extends Component<IRegionsProps, IRegionsState> {
	public readonly state: IRegionsState = {
		column: 'name',
		direction: 'ascending',
		activePage: 1,
		pageSize: 16,
		loading: false,

		data: null,
		dataToRender: null,
		dataSize: 0
	};

	public static propTypes: PropTypes.InferProps<IRegionsProps> = {
		user: PropTypes.shape<PropTypes.ValidationMap<USERS_RESPONSE_SCHEMA>>({}),
		storeRegionInState: PropTypes.func.isRequired,
		setFormToRegion: PropTypes.func.isRequired
	};

	public static defaultProps: Partial<IRegionsProps> = {
		user: null
	};

	public componentWillReceiveProps(props: Readonly<IRegionsProps>) {
		if (props !== this.props) {
			if (props.user) {
				Access.subscribe(props.user);
			}
		}
	}

	public async componentDidMount() {
		this.setState(() => ({ loading: true }));
		const data = await getAllRegions();
		this.setStateData(data);
		Storage.listener.subscribe(Storage.listener.listeners.AREARS, this.onDataUpdate);
	}

	public render(): ReactNode {
		return (
			<RestrictedAccess {...this.props} user={this.props.user}>
				<MainContent
					title="Regions"
					toolbar={<Toolbar {...this.props} onAddRegion={this.onAddRegion} />}
					description="Manage the regions setup in RDLS"
				>
					{this.state.loading ? (
						<Loda active size="massive" />
					) : (
						<Container>
							<RegionsList
								data={this.state.data}
								onSort={this.onSortColumn}
								sortedColumn={this.state.column}
								sortedDirection={this.state.direction}
								onSelect={this.onSelectRow}
								activePage={this.state.activePage}
								pageSize={this.state.pageSize}
								dataToRender={this.state.dataToRender}
							/>
						</Container>
					)}
				</MainContent>
			</RestrictedAccess>
		);
	}

	public componentWillUnmount() {
		Storage.listener.unsubscribe(Storage.listener.listeners.AREARS, this.onDataUpdate);
	}

	/**
	 * onSortColumn is a method which sorts the data in
	 * acsending or descending order to the selected header
	 * column
	 * @param column string
	 */
	public onSortColumn = (column: string): void => {
		if (column !== this.state.column) {
			const data = sortBy(this.state.data, [column]);
			this.setState(
				(prevState: Readonly<IRegionsState>) => ({
					column,
					direction: 'ascending'
				}),
				() => {
					this.setStateData(data);
				}
			);
			return;
		}
		const data = (this.state.data as []).reverse();
		this.setState(
			(prevState: Readonly<IRegionsState>) => ({
				direction: prevState.direction === 'ascending' ? 'descending' : 'ascending'
			}),
			() => {
				this.setStateData(data);
			}
		);
	};

	/**
	 * onSelectRow is a method which will set the redux store of the
	 * selected region and move the user to the region page
	 * @param region REGION_RESPONSE_SCHEMA
	 */
	public onSelectRow = (region: REGION_RESPONSE_SCHEMA): void => {
		this.props.storeRegionInState(region);
		this.props.history.push(Constants.app.routes.dashboard.VIEW_REGION);
	};

	/**
	 * setStateData is a method which is called when the data is fetched
	 * from the server.
	 * it structures the data into the format of the component state
	 * @param data REGION_RESPONSE_SCHEMA[] | null
	 * @returns void
	 */
	public setStateData = (data: Array<REGION_RESPONSE_SCHEMA> | null) => {
		if (data) {
			this.setState((prevState: Readonly<IRegionsState>) => ({
				data,
				loading: false,
				dataSize: data.length,
				dataToRender: data.slice(0, prevState.pageSize)
			}));
			return;
		}
		this.setState(() => ({ loading: false }));
	};

	/**
	 * onAddRegion moves the user to the add region page where they
	 * can add a new region to in the system
	 */
	public onAddRegion = (): void => {
		this.props.setFormToRegion();
		this.props.history.push(Constants.app.routes.dashboard.ADD_REGION);
	};

	public onDataUpdate = (data: any) => {
		const regions: any = Storage.listener.getDataForKey(
			Storage.listener.listeners.AREARS,
			data
		);
		this.setStateData(regions);
	};
}

const mapStateToProps = (state: AppStore) => ({
	user: state.user.user
});

const mapDispatchToProps = {
	storeRegionInState,
	setFormToRegion
};

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
