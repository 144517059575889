import React, { useEffect, useRef, useState } from 'react';
import './css/tools.css';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
	label?: string;
	[key: string]: any;
}

export const TextField: React.FC<Props> = props => {
	const [hasValue, setHasValue] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const styling = `textfield__input ${hasValue ? 'active' : ''}`;
	const { label = '' } = props;

	useEffect(() => {
		if (inputRef.current) {
			const { value, placeholder, type } = inputRef.current;
			setHasValue(type === 'date' || !!placeholder || !!value);
		}
	}, []);

	const onTextLabelClicked = () => {
		setHasValue(true);
		inputRef.current!.focus();
	};

	const onTextFieldFocused = () => {
		const { value, placeholder, type } = inputRef.current!;
		setHasValue(type === 'date' || !!placeholder || !!value);
	};

	const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, type } = event.target;
		if (type === 'tel' && (value.match(/[0-9]/) || Boolean(value))) {
			event.target.value = String(value.replace(/[a-zA-Z]/, ''));
		}
		setHasValue(Boolean(value));
		props.onChange && props.onChange(event);
	};

	return (
		<div className="textfield">
			<input
				{...props}
				className={styling}
				onFocus={onTextFieldFocused}
				onChange={onTextChange}
				ref={inputRef}
			/>
			<label className="textfield__label" onClick={onTextLabelClicked}>
				{label}
			</label>
		</div>
	);
};
