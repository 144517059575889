import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

interface Props {
	activePage: number;
	onPageChange: (isNextPage: boolean) => void;
	pages: number;
	onCancel: () => void;
	loading: boolean;
	disabled: boolean;
}

const LabFormActions: React.FC<Props> = props => {
	const { activePage, pages, onPageChange, onCancel, loading } = props;

	return (
		<>
			<div style={styles.actions}>
				<Button
					type="button"
					className="toolbar-btn iw btn"
					size="large"
					onClick={() => onPageChange(false)}
					style={{
						visibility: activePage === 1 ? 'hidden' : 'visible'
					}}
				>
					Previous
				</Button>
				<Button
					type="button"
					className="toolbar-btn iw btn"
					size="large"
					onClick={() => onPageChange(true)}
					style={{
						visibility: activePage === pages ? 'hidden' : 'visible'
					}}
				>
					Next
				</Button>
			</div>
			<div style={{ textAlign: 'center' }}>
				<Button loading={loading} type="submit" color="teal" size="large">
					Submit
				</Button>
				<Button type="reset" color="red" size="large" onClick={onCancel}>
					Cancel
				</Button>
			</div>
		</>
	);
};

const styles = {
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '1.2rem'
	}
};

LabFormActions.propTypes = {
	activePage: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	pages: PropTypes.number.isRequired
};

export default LabFormActions;
