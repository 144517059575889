import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { LAB_RESPONSE_SCHEMA } from '../../../resource/services';
import { TableWrap } from '../../../resource/components';
import { ListViewProps, Direction } from '../../../resource/hocs';

export const LabsListView = (props: ListViewProps<LAB_RESPONSE_SCHEMA>) => (
	<TableWrap>
		<Table basic="very" sortable selectable>
			<Table.Header>
				<Table.Row style={{ backgroundColor: 'teal' }}>
					<Table.HeaderCell style={{ ...styles.header, ...styles.cell, width: '1.7rem' }}>
						#
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('name')}
						sorted={props.sortedColumn === 'name' ? props.sortedDirection : undefined}
					>
						Name
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('community')}
						sorted={
							props.sortedColumn === 'community' ? props.sortedDirection : undefined
						}
					>
						Community
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('district')}
						sorted={
							props.sortedColumn === 'district' ? props.sortedDirection : undefined
						}
					>
						District
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('region')}
						sorted={props.sortedColumn === 'region' ? props.sortedDirection : undefined}
					>
						Region
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{props.dataToRender.map((lab: LAB_RESPONSE_SCHEMA, i: number) => (
					<Table.Row key={lab.id} onClick={() => props.onSelect(lab)}>
						<Table.Cell>
							{((props.activePage as number) - 1) * props.pageSize + i + 1}
						</Table.Cell>
						<Table.Cell style={styles.cell}>{lab.name}</Table.Cell>
						<Table.Cell style={styles.cell}>{lab.community}</Table.Cell>
						<Table.Cell style={styles.cell}>{lab.district}</Table.Cell>
						<Table.Cell style={styles.cell}>{lab.region}</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	</TableWrap>
);

/**
 * the inline styles for the labslistview component
 */
const styles: { [key: string]: React.CSSProperties } = {
	header: {
		fontWeight: 300,
		fontFamily: 'Open sans',
		fontSize: '1.14rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '0.85rem 1rem'
	}
};

LabsListView.propTypes = {
	onSort: PropTypes.func.isRequired,
	sortedColumn: PropTypes.string.isRequired,
	sortedDirection: PropTypes.oneOf<Direction>(['ascending', 'descending']).isRequired,
	onSelect: PropTypes.func.isRequired,
	dataToRender: PropTypes.arrayOf(
		PropTypes.shape<PropTypes.ValidationMap<LAB_RESPONSE_SCHEMA>>({
			name: PropTypes.string.isRequired,
			community: PropTypes.string.isRequired,
			district: PropTypes.string.isRequired,
			region: PropTypes.string.isRequired,
			telephone: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
			id: PropTypes.string.isRequired
		})
	),
	activePage: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired
};
