import React from 'react';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { getInputDate } from '../_helpers/utilities';
import Checkbox from '../_shared/components/Checkbox';
import Fieldset from '../_shared/components/Fieldset';
import Input from '../_shared/components/Input';
import Title from '../_shared/components/Title';
import { Column, Field, Row, Section } from './styles';

interface IProps {
	request: SAMPLES_RESPONSE_SCHEMA;
}

export default function ClientInformation({ request }: IProps) {
	const { surname, first_name, middle_name, gender, dob, age } = request.patient.user;
	const clientAge = (age || '').toString().padStart(3, '0');

	return (
		<Section>
			<Title style={{ paddingBottom: '1pt' }} content="Client Information" />
			<Row alignItems="center">
				<Column>
					<Field>
						<Input label="Surname" required length={25} value={surname} />
					</Field>
					<Field>
						<Input
							label="Other Names"
							length={25}
							value={`${first_name} ${middle_name}`}
						/>
					</Field>
				</Column>
				<Fieldset title="Gender" required style={{ paddingBottom: 5 }}>
					<Checkbox label="Male" cross={gender === 'M'} />
					<Checkbox label="Female" cross={gender === 'F'} />
				</Fieldset>
			</Row>
			<Row alignItems="center" justifyContent="space-between">
				<Field>
					<Input label="Date of Birth" length={10} value={getInputDate(dob)} />
				</Field>
				<Field>
					<Input label="Age" length={3} value={clientAge} />
				</Field>
				<Field>
					<Input
						value={request.insurance_code}
						label="Member Number"
						required
						length={8}
					/>
				</Field>
			</Row>
			<Row alignItems="center" justifyContent="space-between">
				<Field>
					<Input
						label="Hospital Record No."
						length={11}
						value={request.patient.record_number}
					/>
				</Field>
				<Field>
					<Input label="Card Serial Number" required length={13} />
				</Field>
			</Row>
		</Section>
	);
}
