import { Constants as K } from '../../constants';
import { Dispatch } from '../../services';

export interface DataState {
	isSynched: boolean;
	error: any;
}

const defaultState: DataState = {
	isSynched: false,
	error: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case K.store.types.DATA_STORE_SYNCHED:
			return { ...state, isSynched: true, error: null };

		case K.store.types.DATA_STORE_SYNC_FAIL:
			return { ...state, isSynched: true, error: action.payload };

		case K.store.types.CLEAR_DATA_STORE:
			return { ...state, isSynched: true, error: null };

		default:
			return state;
	}
};
