import React from 'react';
import { Loader as Loda, StrictLoaderProps } from 'semantic-ui-react';
import './css/tools.css';

interface ILoaderProps extends StrictLoaderProps {
	processing: boolean;
	text: string;
}

export const Loader = ({ text, processing, ...props }: ILoaderProps) => {
	const display = props.active ? 'block' : 'none';
	const styling = `iw loader ${processing ? 'processing' : ''}`.trim();
	return (
		<div className={styling} style={{ display }}>
			<Loda {...props} /> <span className="iw text">{text}</span>
		</div>
	);
};

Loader.defaultProps = {
	size: 'big',
	processing: false,
	text: ''
};
