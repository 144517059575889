import { Constants as SharedConstants } from '../../../resource/constants';

export const Constants = {
	...SharedConstants,
	configs: [
		{
			fields: [
				{
					type: 'text',
					placeholder: 'Enter name of scheme',
					name: 'name'
				},
				{
					type: 'text',
					placeholder: 'Enter scheme number',
					name: 'scheme_number'
				},
				{
					type: 'text',
					placeholder: 'Enter health insurance code',
					name: 'health_insurance_code'
				}
			],
			title: 'NHIS Scheme',
			description: 'Fill the required details of the NHIS Scheme',
			path: SharedConstants.app.routes.dashboard.NHIS_ADD,
			name: 'scheme',
			showLevels: true,
			onlyDistrict: true
		}
	]
};
