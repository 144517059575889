import React, { useContext } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { Text, Widget } from '.';
import EmptyStateImage from '../assets/images/no_data.svg';
import { AppContext } from '../store/contexts/AppContext';
import './css/tools.css';

export interface EmptyStateProps {
	title: string;
	description: string;
}

export const EmptyState = (props: EmptyStateProps) => {
	const { loading } = useContext(AppContext);

	if (loading) {
		return (
			<div style={{ position: 'relative', padding: '2rem 0' }}>
				<Loader active size="massive" />
				<Text className="iw text" style={{ marginTop: '8rem', textAlign: 'center' }}>
					Loading data from server. Please wait...
				</Text>
			</div>
		);
	}

	return (
		<Widget className="empty no-data" style={{ position: 'relative' }}>
			<img src={EmptyStateImage} alt="empty" />
			<Header textAlign="center" icon>
				{props.title}
			</Header>
			<Text className="iw text">{props.description}</Text>
		</Widget>
	);
};
