import difference from 'lodash/difference';
import PropTypes from 'prop-types';
import React, { Component, CSSProperties, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, DropdownProps, StrictGridProps } from 'semantic-ui-react';
import {
	AdvancedForm,
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	ToolbarButton,
	Widget
} from '../components';
import { AppStore, clearDataForEdit, clearError, clearMiscState, uploadToServer } from '../store';
import './css/list.css';
import {
	IWrappedAdvancedFormOptions,
	IWrappedAdvancedFormProps,
	IWrappedAdvancedFormState
} from './_helpers';

const wrappedAdvancedForm = <T extends {}>(
	formatter: (
		state: IWrappedAdvancedFormState
	) => T & { url: string; method: string; store: string },
	dataFormatter: (data: AppStore['misc']['data']) => T,
	options: IWrappedAdvancedFormOptions
) => {
	const { forms, title } = options;
	return class extends Component<IWrappedAdvancedFormProps, IWrappedAdvancedFormState> {
		private _isMounted = false;
		public columns: StrictGridProps['columns'] = 1;
		public readonly state: IWrappedAdvancedFormState = {
			activePage: 0,
			level: {},
			loading: false,
			showStatus: false
		};

		public static propTypes: PropTypes.InferProps<IWrappedAdvancedFormProps> = {
			children: PropTypes.any,
			onlyDistrict: PropTypes.bool.isRequired,
			onlySubDistrict: PropTypes.bool.isRequired,
			showAll: PropTypes.bool.isRequired,
			uploadToServer: PropTypes.func.isRequired,
			error: PropTypes.any,
			resp: PropTypes.any,
			clearError: PropTypes.func.isRequired,
			clearDataForEdit: PropTypes.func.isRequired,
			data: PropTypes.any
		};

		public static defaultProps: Partial<IWrappedAdvancedFormProps> = {
			onlyDistrict: false,
			onlySubDistrict: false,
			showAll: true,
			children: null
		};

		public componentDidMount(): void {
			this._isMounted = true;
			if (this.props.data && this._isMounted) {
				this.setState(() => dataFormatter(this.props.data));
			}
		}

		public componentWillReceiveProps(props: IWrappedAdvancedFormProps) {
			if (props !== this.props && (props.error || props.resp)) {
				this.setState(() => ({
					loading: false,
					showStatus: Boolean(props.resp)
				}));
			}
		}

		public render(): ReactNode {
			return (
				<RestrictedAccess {...this.props} user={this.props.user}>
					<ErrorHandler error={this.props.error} onExit={this.onExit}>
						<MainContent title={title} toolbar={this.renderToolBar()}>
							<Container>
								<Widget>
									{this.renderForm()}
									{this.renderActions()}
								</Widget>
							</Container>
							<Alert
								success
								open={this.state.showStatus}
								onConfirmed={this.onClose}
								title="Successful"
								message="The form has been uploaded successfully to the server."
							/>
						</MainContent>
					</ErrorHandler>
				</RestrictedAccess>
			);
		}

		componentWillUnmount() {
			this._isMounted = false;
			this.props.clearMiscInAppStore();
			this.props.clearDataForEdit();
		}

		public renderForm = () => {
			const { activePage } = this.state;
			return forms.map((form, i) =>
				activePage === i ? (
					<AdvancedForm
						key={i}
						fields={form.fields}
						name={form.name}
						title={form.title}
						description={form.description}
						onTextChange={this.onTextChange}
						onSelect={this.onSelect}
						onLevelSelect={this.onLevelSelect}
						showLevels={form.showLevels}
						values={this.state}
						onlyDistrict={form.onlyDistrict}
					/>
				) : null
			);
		};

		public renderActions = () => {
			const { activePage } = this.state;
			return (
				<div style={styles.actions}>
					<Button
						className="toolbar-btn iw btn"
						size="large"
						onClick={() => this.onPageChange(false)}
						style={{
							visibility: activePage === 0 ? 'hidden' : 'visible'
						}}
					>
						Previous
					</Button>
					<Button
						className="toolbar-btn iw btn"
						size="large"
						onClick={() => this.onPageChange()}
						style={{
							visibility: activePage === forms.length - 1 ? 'hidden' : 'visible'
						}}
					>
						Next
					</Button>
				</div>
			);
		};

		public renderToolBar = (): ReactNode => {
			const isFilled = difference([...forms.map(form => form.name)], Object.keys(this.state));
			return (
				<div style={styles.toolbar} className="toolbar">
					<ToolbarButton
						loading={this.state.loading && Boolean(this.props.error)}
						iconName="cloud upload"
						content="Upload"
						onClick={this.onSave}
						disabled={Boolean(isFilled.length)}
					/>
					<ToolbarButton
						danger
						iconName="close"
						content="Cancel"
						onClick={this.onClose}
					/>
				</div>
			);
		};

		public onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
			event.persist();
			const target = event.target;
			this.setState((prevState: Readonly<IWrappedAdvancedFormState>) => {
				const data = { ...prevState[target.id], [target.name]: target.value };
				return { [target.id]: data };
			});
		};

		public onClose = (): void => {
			this.setState(() => ({ showStatus: false }));
			const [dashboard, path] = this.props.location.pathname.split('/').slice(1);
			const returnPathName = `/${dashboard}/${path}`;
			this.props.history.replace(returnPathName);
		};

		public onSave = (): void => {
			this.setState(() => ({ loading: true }));
			this.props.uploadToServer(formatter(this.state));
		};

		public onSelect = (
			event: React.SyntheticEvent<HTMLElement, Event>,
			data: DropdownProps
		): void => {
			event.persist && event.persist();
			if (data.options) {
				const value = data.options.filter(value => value.value === data.value)[0];
				this.setState((prevState: Readonly<IWrappedAdvancedFormState>) => ({
					[data.id]: {
						...prevState[data.id],
						[data.name]: value
					}
				}));
			}
		};

		public onLevelSelect = (data: any): void => {
			this.setState(prevState => {
				return {
					...prevState,
					level: { ...prevState.level, ...data }
				};
			});
		};

		public onPageChange = (isNext = true) => {
			const { activePage } = this.state;
			const nextPage = isNext ? activePage + 1 : activePage - 1;
			this.setState(() => ({ activePage: nextPage, showStatus: false }));
		};

		public onExit = () => {
			this.props.clearError();
		};
	};
};

const styles: { [key: string]: CSSProperties } = {
	toolbar: {
		display: 'flex',
		alignItems: 'center'
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between'
	}
};

const mapStateToProps = (state: AppStore) => ({
	resp: state.misc.serverResponse,
	store: state,
	user: state.user.user,
	error: state.misc.error,
	data: state.misc.data
});

const mapDispatchToProps = {
	clearMiscInAppStore: clearMiscState,
	uploadToServer,
	clearError,
	clearDataForEdit
};

export default <T extends {}>(
	stateFormatter: (
		data: IWrappedAdvancedFormState
	) => T & { url: string; method: string; store: string },
	dataFormatter: (data: AppStore['misc']['data']) => T,
	options: IWrappedAdvancedFormOptions
) => {
	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(wrappedAdvancedForm(stateFormatter, dataFormatter, options));
};
