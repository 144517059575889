import React, { ComponentType } from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Image } from '../components';
import { Constants as K } from '../constants';
import { Access, getFullName } from '../services';
import { AppStore, clearAppStore, clearDataErrorState } from '../store';
import './css/toolbar.css';

interface IProps extends RouteComponentProps {
	clearAppStore: () => void;
	clearDataErrorState: () => void;
}

const wrappedToolbar = (Toolbar?: ComponentType<any>) => {
	return (props: IProps) => {
		const user = useSelector(({ user }: AppStore) => user.user);
		const privilege = (user && user.privileges[0]) || '';
		const { clearAppStore } = props;

		const onUserSignOut = () => {
			clearAppStore();
			Access.clearAccess();
			props.history.push(K.app.routes.SIGNIN);
		};

		const onViewUserProfile = () => {
			props.history.push(K.app.routes.dashboard.USER_PROFILE);
		};

		return (
			<div className="wrap-toolbar toolbar">
				{Toolbar && <Toolbar {...props} />}
				<Dropdown
					className="user-tool"
					trigger={<Image avatar text={(user && getFullName(user as any)) || ''} />}
				>
					<Dropdown.Menu>
						{privilege && privilege !== 'PRIVILEGE_SUPER_ADMIN' && (
							<Dropdown.Item
								icon="user"
								text="Profile"
								className="user-item"
								onClick={onViewUserProfile}
							/>
						)}
						<Dropdown.Item
							icon="sign out"
							text="Sign Out"
							className="user-item"
							onClick={onUserSignOut}
						/>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	};
};

const mapDispatchToProps = {
	clearAppStore,
	clearDataErrorState
};

export default (Toolbar?: ComponentType<any>): any => {
	return connect(
		null,
		mapDispatchToProps
	)(withRouter(wrappedToolbar(Toolbar)));
};
