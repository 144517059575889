import React from 'react';
import { Button, Select } from 'semantic-ui-react';
import { Selector, Text } from '../../../resource/components';
import './css/statusform.css';

type Props = {
	riders: any[];
	onStatusSelect: (e: React.FormEvent<HTMLInputElement>) => void;
	onSelectRider: (id: string | any) => void;
	onSubmit: () => void;
	loading: boolean;
	status: 'OK' | 'BAD';
	disabled: boolean;
	riderId: string;
};

const StatusForm = (props: Props) => {
	const {
		riders,
		status,
		onStatusSelect,
		onSelectRider,
		onSubmit,
		loading,
		disabled,
		riderId
	} = props;

	return (
		<div className="status-form">
			<Text>Select the rider assigned to pick up the sample. *</Text>
			<Select
				fluid
				basic
				onChange={(_, data) => onSelectRider(data.value)}
				options={riders}
				placeholder="Select rider"
				defaultValue={riderId}
			/>
			<Text>Select the status of the sample. *</Text>
			<div className="status">
				<Selector
					defaultChecked={'OK' === status}
					onChange={onStatusSelect}
					label="Good"
					name="status"
					value="OK"
				/>
				<Selector
					defaultChecked={'BAD' === status}
					onChange={onStatusSelect}
					label="Bad"
					name="status"
					value="BAD"
				/>
			</div>
			<Button
				color="teal"
				disabled={loading || disabled}
				onClick={onSubmit}
				loading={loading}
			>
				Submit
			</Button>
		</div>
	);
};

export default StatusForm;
