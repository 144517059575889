import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Image, Text, ToolbarButton } from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import {
	convertToReadableDate,
	getFullName,
	getUserDetails,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';
import './css/requestdetail.css';

type Props = {
	request: SAMPLES_RESPONSE_SCHEMA;
	onLabResult: () => void;
	onReport: () => void;
	onStatus: () => void;
};

const RequestDetail = (props: Props) => {
	const { request, onLabResult, onReport, onStatus } = props;
	const patient = getUserDetails(props.request.patient);
	const midwife = getUserDetails(props.request.midwife);

	const renderPatientContent = () => {
		return (
			<div className="section patient">
				<Image size={Constants.app.theme.size.MEDIUM} text={patient.name} />
				<div className="patient-content">
					<Text className="details-text">
						<span>Name</span> {patient.name}
					</Text>
					<Text className="details-text">
						<span>Date of birth</span> {patient['date Of Birth']}
					</Text>
					<Text className="details-text">
						<span>Gender</span> {patient.gender}
					</Text>
					<Text className="details-text">
						<span>Mobile</span> {patient.telephone}
					</Text>
				</div>
			</div>
		);
	};

	const renderMidwifeContent = () => {
		return (
			<div className="midwife">
				<Image size={Constants.app.theme.size.MEDIUM} text={midwife.name} />
				<div className="content">
					<Text className="details-text">
						<span>Name</span> {midwife.name}
					</Text>
					<Text className="details-text">
						<span>Date of birth</span> {midwife['date Of Birth']}
					</Text>
					<Text className="details-text">
						<span>Gender</span> {midwife.gender}
					</Text>
					<Text className="details-text">
						<span>Mobile</span> {midwife.telephone}
					</Text>
				</div>
			</div>
		);
	};

	const renderClinicContent = () => {
		const { clinic } = request;
		return (
			<div className="clinic">
				<div className="content">
					<Text className="details-text">
						<span>Name</span> {clinic.name}
					</Text>
					<Text className="details-text">
						<span>Code</span> {clinic.clinic_code}
					</Text>
					<Text className="details-text">
						<span>Address</span> {clinic.region}
					</Text>
					<Text className="details-text">
						<span>Telephone</span> {clinic.telephone}
					</Text>
				</div>
			</div>
		);
	};

	const renderRequestOverview = () => {
		return (
			<div className="request-overview">
				<Text className="details-text">
					<span>Date of request</span>
					{convertToReadableDate(request.date_of_request)}
				</Text>
				<Text className="details-text">
					<span>Date of pickup</span>
					{convertToReadableDate(request.date_of_pickup)}
				</Text>
				<Text className="details-text">
					<span>Date of delivery</span>
					{convertToReadableDate(request.date_of_delivery)}
				</Text>
				<div className="space" />
				<Text className="details-text">
					<span>Request by</span> {midwife.name}
				</Text>
				<Text className="details-text">
					<span>Assigned to</span> {getFullName(request.rider)}
				</Text>
				<Text className="details-text">
					<span>Received by</span> {getFullName(request.receiver)}
				</Text>
			</div>
		);
	};

	const renderRequestStatus = () => {
		const { midwife_confirmation, date_of_delivery, rider } = request;
		const message = rider
			? !midwife_confirmation
				? `${
						midwife.name
				  } is yet to confirm that the sample has been picked up. Please call ${
						midwife.telephone
				  } to verify that the sample has been collected`
				: !date_of_delivery
				? "The sample hasn't been confirmed it's status. Please click on the check icon above to assess the status of the sample"
				: ''
			: 'No riders have been assigned to collect the samples';
		return (
			message && (
				<Text danger info className="status" style={styles.status}>
					{message}
				</Text>
			)
		);
	};

	const tabPanes = [
		{ menuItem: 'Midwife', render: () => renderMidwifeContent() },
		{ menuItem: 'Clinic', render: () => renderClinicContent() },
		{ menuItem: 'Request', render: () => renderRequestOverview() }
	];

	return (
		<div className="request-detail">
			<div className="section">
				<ToolbarButton
					showText
					iconName="file"
					content="Test Result"
					onClick={onLabResult}
				/>
				<ToolbarButton showText iconName="print" content="Report" onClick={onReport} />
				<ToolbarButton showText iconName="check" content="Status" onClick={onStatus} />
			</div>
			{renderRequestStatus()}
			{renderPatientContent()}
			<div className="section tabs">
				<Tab menu={{ text: true }} panes={tabPanes} />
			</div>
		</div>
	);
};

const styles = {
	status: {
		padding: '0.35rem 0.75rem !important',
		margin: '0 0.55rem !important'
	}
};

export default RequestDetail;
