import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import {
	Alert,
	Container,
	EmptyState,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Text,
	Widget
} from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import { convertListToDropdownItems, getFullName } from '../../../resource/services';
import { AppStore, Sample } from '../../../resource/store';
import { isRequestsAssigned } from '../_helpers';
import './css/courierassign.css';
import RequestsList from './RequestsList';
import Toolbar from './Toolbar';
import useAssignFetch from './useAssignFetch';

type Props = RouteComponentProps;

type Assign = { sample_id: string; rider_id: string };

const CourierAssignment = (props: Props) => {
	const [assignRequests, setAssignRequests] = useState<Assign[]>([]);
	const user = useSelector(({ user }: AppStore) => user.user);
	const sample = useSelector(({ sample }: AppStore) => sample);
	const { clinicRequests, riders, loading, show, sendAssignments, setShow } = useAssignFetch();
	const dispatch = useDispatch();
	const { error } = sample;

	const onSubmit = () => {
		sendAssignments(assignRequests, clinicRequests);
	};

	const renderAssignmnts = () => {
		return (
			<Widget className="rider-assign">
				<Text>Select a rider to pick up the samples from each clinics. </Text>
				<RequestsList
					clinicRequests={clinicRequests}
					riders={convertListToDropdownItems(riders, rider => getFullName(rider))}
					onSelectRider={(id, riderId) => {
						const { requests } = clinicRequests[id];
						const assign = requests.map(request => ({
							sample_id: request,
							rider_id: riderId
						}));
						setAssignRequests([...assignRequests, ...assign]);
					}}
				/>
				<div style={{ textAlign: 'right' }}>
					<Button
						className="assign-btn"
						disabled={Boolean(loading || isEmpty(assignRequests))}
						loading={loading}
						onClick={onSubmit}
						color="teal"
					>
						Submit
					</Button>
				</div>
			</Widget>
		);
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<ErrorHandler
				error={error}
				onExit={() => {
					dispatch({ type: Sample.CLEAR_ERROR_STATE });
				}}
			>
				<MainContent
					title="Courier Assignment"
					description="Assign riders to pick up samples"
					toolbar={<Toolbar />}
				>
					<Container>
						{!isRequestsAssigned(clinicRequests) ? (
							<EmptyState
								title="No requests"
								description="There are no requests available for riders to be assigned to"
							/>
						) : (
							renderAssignmnts()
						)}
					</Container>
					<Alert
						title="Assignment Successful"
						message="Requests have been assigned riders for pick up."
						open={show}
						onConfirmed={() => {
							setShow(false);
							props.history.push(Constants.app.routes.dashboard.SAMPLE_REQUESTS);
						}}
					/>
				</MainContent>
			</ErrorHandler>
		</RestrictedAccess>
	);
};

export default CourierAssignment;
