import React from 'react';
import { Loader, Header, Button, Icon } from 'semantic-ui-react';
import { LAB_TEST_RESPONSE_SCHEMA } from '../../../resource/services';
import { DetailProps } from '../../../resource/hocs';
import { Text } from '../../../resource/components';

const LabTestDetail = ({ data, loading, ...props }: DetailProps<LAB_TEST_RESPONSE_SCHEMA>) => {
	return (
		<div className="detail-view">
			<div className="detail-block detail-block--small">
				{data && (
					<div>
						<Button
							icon
							className="detail-block-btn"
							onClick={() => props.handleEdit(data)}
						>
							<Icon name="pencil" color="green" />
						</Button>
						<Button
							icon
							className="detail-block-btn"
							onClick={() => props.handleDelete(data)}
						>
							<Icon name="trash" color="red" />
						</Button>
					</div>
				)}
				<Button size="medium" icon className="detail-block-btn" onClick={props.handleClose}>
					<Icon name="close" color="red" />
				</Button>
			</div>
			{loading ? (
				<Loader active={loading} size="large" />
			) : (
				<React.Fragment>
					{data && (
						<React.Fragment>
							<div className="detail-block">
								<Header className="header" textAlign="center">
									Lab Test
								</Header>
								<Text>List of the details of the lab test.</Text>
								<div>
									<Text className="ix text">
										<span>Test name</span> {data.name}
									</Text>
									<Text className="ix text">
										<span>NHIS code</span> {data.NHIS_CODE}
									</Text>
									<Text className="ix text">
										<span>GDRG code</span> {data.GDRG_CODE}
									</Text>
								</div>
							</div>
							<div className="detail-block">
								<Header className="header" textAlign="center">
									Lab Test Fees
								</Header>
								<Text>List of cost for the lab test.</Text>
								<div>
									<Text className="ix text">
										<span>NHIS fee</span> {data.NHIS_FEE}
									</Text>
									<Text className="ix text">
										<span>Cash fee</span> {data.CASH_FEE}
									</Text>
								</div>
							</div>
							<div className="detail-block">
								<Header className="header" textAlign="center">
									Possible Results
								</Header>
								<Text>List of the possible results for the lab test.</Text>
								<div>
									{data.possible_results.map((result: string, _i: number) => (
										<Text key={_i} className="ix text">
											{result}
										</Text>
									))}
								</div>
							</div>
						</React.Fragment>
					)}
					{data === null && (
						<div className="detail-block">
							<Header
								textAlign="center"
								className="details-header details-header--error"
							>
								No Details
							</Header>
							<p className="details-text details-text--error">
								There is no information about the laboratory. Please enter the
								details for the lab by clicking on the edit icon above.
							</p>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default LabTestDetail;
