import React from 'react';
import { getName, SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import Checkbox from '../_shared/components/Checkbox';
import Fieldset from '../_shared/components/Fieldset';
import Input from '../_shared/components/Input';
import Label from '../_shared/components/Label';
import Title from '../_shared/components/Title';
import { Box, Column, Field, Row, Section, Text } from './styles';

interface IProps {
	request: SAMPLES_RESPONSE_SCHEMA;
}

export default function ServicesProvided({ request }: IProps) {
	return (
		<Section>
			<Title
				content="SERVICES PROVIDED"
				description="(to be filled-in by all health care providers)"
			/>
			<Row gap={20}>
				<Column>
					<Fieldset title="Type of Service" required style={{ padding: 0 }}>
						<Row alignItems="center">
							<Column
								borderRight="1px solid #000"
								paddingBottom="5pt"
								padding="5pt"
								flex={2}
							>
								<Field>
									<Text>(a.) select only one</Text>
								</Field>
								<Row
									alignItems="center"
									flexWrap="wrap"
									justifyContent="space-between"
								>
									<Field>
										<Checkbox label="Outpatient" />
									</Field>
									<Field>
										<Checkbox label="In-patient" />
									</Field>
									<Field>
										<Checkbox label="Diagnostic" cross />
									</Field>
								</Row>
							</Column>
							<Column padding={12}>
								<Checkbox label="Pharmacy" />
							</Column>
						</Row>
						<Row
							alignItems="center"
							borderTop="1px solid #000"
							justifyContent="space-between"
							padding="5pt"
							paddingBottom="5pt"
						>
							<Text>(b.)</Text>
							<Checkbox label="All Inclusive" />
							<Checkbox label="Unbundled" />
						</Row>
					</Fieldset>
					<Fieldset title="Outcome" required style={{ paddingBottom: '4pt' }}>
						<Row flexWrap="wrap" justifyContent="space-between">
							<Field>
								<Checkbox label="Discharged" />
							</Field>
							<Field>
								<Checkbox label="Died" />
							</Field>
							<Field>
								<Checkbox label="Transferred out" />
							</Field>
							<Field>
								<Checkbox label="Absconded" />
							</Field>
							<Field>
								<Checkbox label="Discharged Against Medical Advice" />
							</Field>
						</Row>
					</Fieldset>
				</Column>
				<Column>
					<Fieldset
						title="Date(s) of Service Provision"
						required
						style={{ paddingBottom: '8pt' }}
					>
						<Row flexWrap="wrap" justifyContent="flex-end">
							<Field style={{ paddingBottom: '8pt', paddingTop: '5pt' }}>
								<Input
									length={10}
									label="1st Visit/Admission"
									placeholder="dd/mm/yyyy"
								/>
							</Field>
							<Field style={{ paddingBottom: '8pt' }}>
								<Input
									length={10}
									label="2nd Visit/Discharge"
									placeholder="dd/mm/yyyy"
								/>
							</Field>
							<Field style={{ paddingBottom: '8pt' }}>
								<Input length={10} label="3rd Visit" placeholder="dd/mm/yyyy" />
							</Field>
							<Field style={{ paddingBottom: '8pt' }}>
								<Input length={10} label="4th Visit" placeholder="dd/mm/yyyy" />
							</Field>
							<Field>
								<Input length={4} label="Length of Duration (days)" />
							</Field>
						</Row>
					</Fieldset>
				</Column>
			</Row>
			<Fieldset title="Type of Attendance">
				<Row alignItems="center" justifyContent="space-between">
					<Checkbox label="Chronic Follow-up" />
					<Checkbox label="Emergency" />
					<Checkbox label="Acute Episode" />
					<Input label="Specialty Code:" length={4} value="INVE" />
				</Row>
			</Fieldset>
			<Row gap="10pt" justifyContent="space-between">
				<Column>
					<Row gap="6pt" alignItems="center" justifyContent="space-between">
						<Label required style={{ margin: 0 }}>
							Physician/Clinician Name
						</Label>
						<Box
							minHeight="8pt"
							fontSize="10pt"
							padding="6pt"
							border="1px solid #000"
							flex={1}
						>
							<span>{getName(request.midwife)}</span>
						</Box>
					</Row>
					<Row gap="6pt" alignItems="center" marginTop="10pt">
						<Label required style={{ margin: 0 }}>
							Clinic
						</Label>
						<Box
							minHeight="8pt"
							fontSize="10pt"
							padding="6pt"
							border="1px solid #000"
							flex={1}
						>
							<span>{request.clinic.name}</span>
						</Box>
					</Row>
				</Column>
				<Column>
					<Input label="Physician/Clinician ID" length={13} />
				</Column>
			</Row>
		</Section>
	);
}
