import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Pagination } from 'semantic-ui-react';
import { EmptyState, Widget } from '../../../resource/components';
import { usePagination } from '../../../resource/hooks';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import SummaryReportListView from './SummaryReportListView';

type Props = {
	samples: SAMPLES_RESPONSE_SCHEMA[];
	summaryTableId: string;
	totalAmount: number;
	showCashReport: boolean;
};

const SummaryReportList = (props: Props) => {
	const { samples, summaryTableId, totalAmount, showCashReport } = props;
	const {
		dataToRender,
		pageSize,
		dataSize,
		onPageChange,
		activePage,
		totalPages
	} = usePagination(samples, 50);

	if (isEmpty(samples)) {
		return (
			<EmptyState
				title="No Summary"
				description="There are no requests to generate a summary report"
			/>
		);
	}
	return (
		<Widget className="summary">
			<SummaryReportListView
				activePage={activePage}
				pageSize={pageSize}
				samples={dataToRender}
				summaryTableId={summaryTableId}
				totalAmount={totalAmount}
				showCashReport={showCashReport}
			/>
			{dataSize > pageSize && (
				<div className="pagination">
					<Pagination
						totalPages={totalPages}
						activePage={activePage}
						onPageChange={onPageChange}
						firstItem={null}
						lastItem={null}
					/>
				</div>
			)}
		</Widget>
	);
};

export default SummaryReportList;
