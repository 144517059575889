import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container, MainContent, RestrictedAccess } from '../../resource/components';
import { Access } from '../../resource/services';
import { AppStore } from '../../resource/store';
import { Router, routes } from '../Dashboard/routes';
import './css/overview.css';
import OverviewDetail from './OverviewDetail';
import Toolbar from './Toolbar';
import { Constants } from './_helpers';

interface Props extends RouteComponentProps {}

const isRouteAccessible = (route: Router) => {
	const isAccessible = Access.isAccessible(route.path);
	const condition =
		route.subComponents &&
		route.subComponents.length > 0 &&
		route.show === undefined &&
		isAccessible &&
		route.path !== Constants.app.routes.dashboard.USER_PROFILE;
	return condition;
};

const Overview = (props: Props) => {
	const state = useSelector(state => state);
	const user = useSelector(({ user }: AppStore) => user.user);
	const accessibleRoutes = routes.filter(isRouteAccessible);

	const overal = accessibleRoutes.map((route, _i) => {
		const [stateKey, propertyPath] = Constants.routesStateKey[route.path];
		const values = (state as any)[stateKey][propertyPath] as any[];
		return (
			<OverviewDetail
				key={_i}
				title={route.name}
				subTitle={route.heading}
				icon={route.icon}
				description={route.description}
				path={route.path}
				total={(values && values.length) || 0}
			/>
		);
	});

	return (
		<RestrictedAccess {...props} user={user}>
			<MainContent
				title="Overview"
				description={`Manage your account from ${Constants.app.SHORT_NAME} dashboard`}
				toolbar={<Toolbar {...props} />}
			>
				<Container className="overview contain">
					<div className="grid">{overal}</div>
				</Container>
			</MainContent>
		</RestrictedAccess>
	);
};

export default Overview;
