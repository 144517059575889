import React from 'react';
import Label from './Label';

interface IProps {
	label?: string;
	length: number;
	value?: string;
	required?: boolean;
	style?: React.CSSProperties;
	placeholder?: string;
}

export default function Input({
	label,
	length,
	value = '',
	required,
	style,
	placeholder = ''
}: IProps) {
	const items = Array.from({ length }, getValueAtIndex);

	function getValueAtIndex(_: unknown, index: number) {
		if (value[index]) return value[index];
		if (placeholder[index]) return <span style={placeholderStyle}>{placeholder[index]}</span>;
		return null;
	}

	return (
		<Container style={style}>
			{label && <Label required={required}>{label}</Label>}
			<List>{React.Children.toArray(items.map((val) => <ListItem>{val}</ListItem>))}</List>
		</Container>
	);
}

function Container({ children, style = {} }: React.HTMLAttributes<HTMLDivElement>) {
	const styles = { display: 'flex', alignItems: 'center', ...style };
	return <div style={styles}>{children}</div>;
}

function List({ children }: React.HTMLAttributes<HTMLUListElement>) {
	const style = { display: 'flex', alignItems: 'center', margin: 0, padding: 0 };
	return <ul style={style}>{children}</ul>;
}

function ListItem({ children }: React.HTMLAttributes<HTMLLIElement>) {
	const style: React.CSSProperties = {
		border: '1px solid #000',
		width: '12pt',
		height: '12pt',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '10pt',
		textTransform: 'uppercase'
	};
	return <li style={style}>{children}</li>;
}

const placeholderStyle: React.CSSProperties = {
	color: '#ddd'
};
