import { Constants as SharedContants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedContants,
	configs: [
		{
			title: 'Midwife',
			description: 'Fill the required details of the midwife',
			fields: UserForm.userFields,
			path: SharedContants.app.routes.dashboard.ADD_MIDWIFE,
			name: 'midwife'
		}
	]
};
