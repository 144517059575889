import { Dispatch as Action } from '../../services';
import { Constants as K } from '../../constants';

/**
 * the interface or data structure for the properties
 * or states in the misc reducer stored in the app store
 */
export interface MiscState {
	[key: string]: any;
	error: any;
	serverResponse: any;
	data: any;
}

const defaultState: MiscState = {
	error: null,
	serverResponse: null,
	data: null
};

export default (state: any = defaultState, action: Action) => {
	switch (action.type) {
		case K.store.types.DUMP_DATA_TYPE:
			return { ...state, ...action.payload, error: null };

		case K.store.types.CLEAR_MISC_DATA_TYPE:
			return { ...state, ...defaultState, [K.store.filters.edit]: null };

		case K.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };

		case K.store.types.SERVER_UPLOAD_SUCCESS:
			return { ...state, serverResponse: action.payload, error: null };

		case K.store.types.SERVER_UPLOAD_FAILED:
			return { ...state, serverResponse: null, error: action.payload };

		case K.store.types.CLEAR_MISC_ERROR:
			return { ...state, error: null };

		case K.store.types.STORE_EDIT_DATA_TYPE:
			return { ...state, data: action.payload, error: null };

		case K.store.types.CLEAR_EDIT_DATA:
			return { ...state, data: null, error: null };

		default:
			return state;
	}
};
