import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import healthcare from '../../resource/assets/images/healthcare.svg';
import { Text } from '../../resource/components';
import { Constants as K } from '../../resource/constants';

type Props = {
	text: string;
	url: string;
};

const Showcase = (props: Props) => {
	const { text, url } = props;

	return (
		<Container>
			<Header textAlign="center" className="title large">
				{K.app.APP_NAME}
				<Header.Subheader>{K.app.PHRASE}</Header.Subheader>
			</Header>
			<div className="content">
				<div className="desc">
					<Text>
						{K.app.SHORT_NAME} provides a means for rural clinics to access medical
						laboratories to provide excellent health care for patients
					</Text>
					<Link to={url} className="cta">
						{text}
					</Link>
				</div>
				<img src={healthcare} alt="healthcare" className="image" />
			</div>
		</Container>
	);
};

Showcase.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default Showcase;
