import { Constants as SharedConstants } from '../../../resource/constants';

const routes = SharedConstants.app.routes.dashboard;

export const Constants = {
	...SharedConstants,
	routesStateKey: {
		[routes.LABS]: ['lab', 'labs'],
		[routes.CLINICS]: ['clinic', 'clinics'],
		[routes.NHIS_VIEW]: ['nhis', 'nhiss'],
		[routes.LAB_TESTS]: ['test', 'tests'],
		[routes.REGIONS]: ['regional', 'regions'],
		[routes.SAMPLE_REQUESTS]: ['sample', 'requests'],
		[routes.TECHNICIANS]: ['technician', 'technicians'],
		[routes.MIDWIFES]: ['midwife', 'midwives'],
		[routes.COURIERS]: ['courier', 'couriers']
	}
};
