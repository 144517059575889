import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getLabCouriers } from '../../../resource/dataService';
import {
	CLINIC_RESPONSE_SCHEMA,
	COURIER_RESPONSE_SCHEMA,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';

export type LAB_COURIERS = Array<COURIER_RESPONSE_SCHEMA>;

type Requests = {
	[id: string]: {
		clinic: CLINIC_RESPONSE_SCHEMA;
		requests: string[];
	};
};

export const getLabRiders = async (riders: LAB_COURIERS | null): Promise<LAB_COURIERS | null> => {
	if (riders) {
		return riders;
	}
	return await getLabCouriers();
};

export const isRequestSubmitted = (request: SAMPLES_RESPONSE_SCHEMA): boolean => {
	const result = request.lab_tests.some(test => !test.lab_result);
	return Boolean(request.midwife_confirmation && result);
};

export const formatRequestDate = (requestDate: string): string => {
	return moment(requestDate).format('DD-MM-YYYY');
};

export const isRequestsAssigned = (requests: SAMPLES_RESPONSE_SCHEMA[] | Requests) => {
	if (isEmpty(requests)) return false;
	if (Array.isArray(requests)) {
		return requests.some(request => isEmpty(request.rider));
	}
	const totalRequestsAvailable = Object.keys(requests).reduce((acc, id) => {
		return acc + requests[id].requests.length;
	}, 0);
	return totalRequestsAvailable > 0;
};
