import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dimmer, Header, Sidebar } from 'semantic-ui-react';
import {
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
} from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import { getFullName, SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore, Sample } from '../../../resource/store';
import { isRequestsAssigned } from '../_helpers';
import './css/requests.css';
import RequestDetail from './RequestDetail';
import RequestsList from './RequestsList';
import Toolbar from './Toolbar';
import useDelete from './useDelete';

type Props = RouteComponentProps;

type FilterKey = 'ALL' | 'PENDING' | 'OK' | 'BAD';

const Requests = (props: Props) => {
	const [sidebarActive, setSidebarActive] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [deleteRequests, setDeleteRequest] = useState([] as SAMPLES_RESPONSE_SCHEMA[]);
	const [isDelete, setIsDelete] = useState(false);
	const { success, onDelete, reset, message, showAlert: deleteAlert } = useDelete();
	const user = useSelector((state: AppStore) => state.user.user);
	const sample = useSelector(({ sample }: AppStore) => sample);
	const dispatch = useDispatch();
	const { requests, error, selectedRequest } = sample;
	const mounted = useRef(false);
	const isAvailableRequests = useMemo(() => isRequestsAssigned(requests), [requests]);

	useEffect(() => {
		setShowAlert(isAvailableRequests);
	}, [isAvailableRequests]);

	useEffect(() => {
		mounted.current = true;
		return () => {
			setShowAlert(false);
			mounted.current = false;
		};
	}, []);

	const onPageChange = (url: string) => () => {
		if (mounted.current) {
			setShowAlert(false);
		}
		props.history.push(url);
	};

	const onDeleteRequest = async () => {
		if (isDelete) {
			onDelete(deleteRequests);
		}
		setIsDelete(true);
	};

	const cancelDeleteRequest = () => {
		setDeleteRequest([]);
		setIsDelete(false);
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<ErrorHandler error={error} onExit={() => dispatch({ type: Sample.CLEAR_ERROR_STATE })}>
				<MainContent
					title="Request"
					description="Manage request submitted by clinics"
					toolbar={
						<Toolbar
							isAssign
							onAssign={onPageChange(
								Constants.app.routes.dashboard.SAMPLE_COURIER_ASSIGN
							)}
							onFilter={(filterKey: FilterKey) => {
								dispatch({ type: Sample.FILTER_SAMPLES, payload: filterKey });
							}}
							onSummaryReport={onPageChange(
								Constants.app.routes.dashboard.SUMMARY_REPORT
							)}
							onDeleteRequest={onDeleteRequest}
							isDelete={isDelete}
							cancelDeleteRequest={cancelDeleteRequest}
						/>
					}
				>
					<Sidebar.Pushable className="hoc-listView-sidebar">
						<Sidebar
							animation="push"
							width="wide"
							direction="right"
							visible={sidebarActive}
						>
							{selectedRequest && (
								<RequestDetail
									request={selectedRequest}
									onLabResult={onPageChange(
										Constants.app.routes.dashboard.SAMPLE_RESULT
									)}
									onReport={onPageChange(
										Constants.app.routes.dashboard.SAMPLE_REPORT
									)}
									onStatus={onPageChange(
										Constants.app.routes.dashboard.SAMPLE_STATUS
									)}
								/>
							)}
						</Sidebar>
						<Sidebar.Pusher>
							<Dimmer.Dimmable
								className="hoc-listView-dimmed"
								dimmed={sidebarActive}
								blurring
							>
								<Dimmer
									active={sidebarActive}
									inverted
									onClick={() => setSidebarActive(false)}
								>
									<Header
										textAlign="center"
										size="large"
										className="hoc-listView-header"
										color="black"
									>
										{selectedRequest && getFullName(selectedRequest.patient)}
									</Header>
								</Dimmer>
								<Container>
									<RequestsList
										requests={requests}
										onRequestSelect={(request) => {
											setSidebarActive(true);
											dispatch({
												type: Sample.STORE_SELECTED_REQUEST,
												payload: request,
											});
										}}
										onSelectDelete={(isChecked, request) => {
											let oldDeleteRequests = [
												...deleteRequests,
											] as SAMPLES_RESPONSE_SCHEMA[];
											const deleteRequest = oldDeleteRequests.find(
												(item) => item.id === request.id
											);
											if (isChecked) {
												if (deleteRequest) return;
												oldDeleteRequests = [...oldDeleteRequests, request];
											} else {
												if (deleteRequest) {
													oldDeleteRequests = oldDeleteRequests.filter(
														(item) => item.id !== request.id
													);
												}
											}
											setDeleteRequest(oldDeleteRequests);
										}}
										isDelete={isDelete}
									/>
									{mounted.current && (
										<Alert
											success={success}
											warning={!success}
											open={showAlert || deleteAlert}
											title={
												(success && 'Delete Samples') ||
												'Courier Assignment'
											}
											message={
												message ||
												'Some of the requests have not been assigned riders. Click on the button below to assign riders'
											}
											onConfirmed={() => {
												const {
													SAMPLE_COURIER_ASSIGN,
												} = Constants.app.routes.dashboard;
												if (!isDelete) {
													onPageChange(SAMPLE_COURIER_ASSIGN)();
													return;
												}
												reset();
												cancelDeleteRequest();
											}}
											onClose={() => setShowAlert(false)}
										/>
									)}
								</Container>
							</Dimmer.Dimmable>
						</Sidebar.Pusher>
					</Sidebar.Pushable>
				</MainContent>
			</ErrorHandler>
		</RestrictedAccess>
	);
};

export default Requests;
