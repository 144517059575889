import { Constants } from '../../constants';
import { Dispatch as Action, NHIS_SCHEMA } from '../../services';

export const NHIS = {
	STORE_NHIS_SCHEME_TYPE: '@store/store-nhis',
	CLEAR_NHIS_SCHEME_TYPE: '@store/clear-nhis-state',
	ADD_NHIS_SCHEME_SUCCESS: '@@api/post-scheme-success',
	ADD_NHIS_SCHEME_ERROR: '@@api/post-scheme-error',
	STORE_NHIS: 'STORE_NHIS'
};

export interface NhisState {
	scheme: NHIS_SCHEMA | null;
	error: any;
	nhiss: Array<NHIS_SCHEMA> | null;
}

const defaultState: NhisState = {
	scheme: null,
	error: null,
	nhiss: null
};

export default (state = defaultState, action: Action) => {
	switch (action.type) {
		case NHIS.STORE_NHIS_SCHEME_TYPE:
			const scheme = action.payload === null ? defaultState : action.payload;
			return { ...state, ...scheme, error: null };

		case Constants.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };

		case NHIS.CLEAR_NHIS_SCHEME_TYPE:
			return { ...state, ...defaultState };

		case NHIS.ADD_NHIS_SCHEME_SUCCESS:
			const newNhis = action.payload;
			const newNhiss = (state.nhiss || []).concat(newNhis);
			return { ...state, ...defaultState, scheme: newNhis, nhiss: newNhiss };

		case NHIS.ADD_NHIS_SCHEME_ERROR:
			return { ...state, ...defaultState, error: action.payload };

		case NHIS.STORE_NHIS:
			return { ...state, error: null, nhiss: action.payload };

		default:
			return state;
	}
};
