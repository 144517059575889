import React from 'react';
import { ToolbarButton } from '../../../resource/components';

interface IProps {
	onSave: () => void;
	onCancel: () => void;
	disabled: boolean;
	loading: boolean;
}

const Toolbar = (props: IProps) => (
	<div className="toolbar">
		<ToolbarButton
			loading={props.loading}
			disabled={props.disabled}
			iconName="cloud upload"
			content="save"
			onClick={props.onSave}
		/>
		<ToolbarButton iconName="cancel" danger content="cancel" onClick={props.onCancel} />
	</div>
);

export default Toolbar;
