import React, { CSSProperties } from 'react';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import NHIS_LOGO from '../images/nhis-logo.jpg';
import Checkbox from '../_shared/components/Checkbox';
import Fieldset from '../_shared/components/Fieldset';
import Input from '../_shared/components/Input';
import { Box, Row, Text } from './styles';

interface IProps {
	lab: any;
	request: SAMPLES_RESPONSE_SCHEMA;
	monthOfClaim: string | Date;
}

export default function Heading({ lab, monthOfClaim, request }: IProps) {
	const date = `${(new Date(monthOfClaim).getMonth() + 1)
		.toString()
		.padStart(2, '0')}/${new Date().getFullYear()}`;
	return (
		<nav>
			<div style={styles.headingNav}>
				<div>
					<img src={NHIS_LOGO} alt="nhis" style={styles.headingImage} />
					<Text style={{ maxWidth: '156pt', fontWeight: 'bold' }}>
						MILLENNIUM PROMISE TROPICAL LABORATORY INITIATIVE
					</Text>
				</div>
				<header>
					<p style={styles.headingTitle}>National Health Insurance Scheme</p>
					<div style={styles.headingContent}>
						<div style={styles.headingLeftContent}>
							<p style={styles.headingSubTitle}>Claim Form</p>
							<Text>(Regulation 62)</Text>
						</div>
						<div style={styles.headingRightContent}>
							<p style={styles.headingFormText}>Form No.</p>
							<Input
								label="Health Facility Code"
								required
								length={13}
								value="03-AS-00385"
							/>
							<Box marginBottom="0.5em" />
							<Input
								label="Claims Check Code"
								required
								length={5}
								value={request.ccc || ''}
							/>
						</div>
					</div>
				</header>
			</div>
			<Fieldset style={{ padding: '5pt', marginBottom: '5pt' }}>
				<Text style={{ marginBottom: '5pt', fontSize: '10pt' }}>
					Important! The form should be completed in CAPITAL LETTERS using a BLACK or DARK
					BLUE ballpoint/fountain pen. Characters and marks used should be similar in the
					style to the following:
				</Text>
				<Row gap="6pt" alignItems="flex-end">
					<Input length={36} value="abcdefghijklmnopqrstuvwxyz1234567890" />
					<Row>
						<Checkbox cross />
						<Checkbox check />
					</Row>
				</Row>
			</Fieldset>
			<FooterRow>
				<Input length={3} label="Scheme Code" required value="amw" />
				<Input
					length={7}
					label="Month of Claim (Batch)"
					required
					placeholder="mm/yyyy"
					value={date}
				/>
			</FooterRow>
		</nav>
	);
}

function FooterRow({ children }: React.PropsWithChildren<{}>) {
	return (
		<Row
			borderBottom="1px solid #000"
			alignItems="center"
			justifyContent="space-between"
			paddingBottom="5pt"
		>
			{children}
		</Row>
	);
}

const styles: { [style: string]: CSSProperties } = {
	headingNav: {
		display: 'flex',
		alignItems: 'center',
		gap: '20pt',
		marginBottom: '1em',
	},
	headingImage: {
		height: 60,
		width: 100,
		background: '#000',
	},
	headingContent: {
		display: 'flex',
		gap: 50,
	},
	headingSubTitle: {
		textDecoration: 'underline',
		margin: 0,
		fontSize: '10pt',
		fontWeight: 'bold',
	},
	headingRightContent: {
		flex: 1,
	},
	headingLeftContent: {
		fontSize: 12,
	},
	headingTitle: {
		margin: 0,
		fontSize: '14pt',
		fontWeight: 'bold',
		marginBottom: '3pt',
	},
	headingFormText: {
		margin: 0,
		marginTop: 0,
		marginLeft: 0,
		marginRight: 0,
		fontSize: '10pt',
		marginBottom: '3pt',
	},
};
