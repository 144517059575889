import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import { Navbar, Text, Widget } from '../../resource/components';
import { Constants } from '../../resource/constants';
import { useAuthenticatedUser } from '../../resource/hooks';
import { pagesRoutes } from '../routes';
import './css/privacypolicy.css';

const PrivacyPolicy = () => {
	const { authenticatedUserRoute, user } = useAuthenticatedUser();

	return (
		<div className="privacy-policy">
			<Navbar
				authenticatedUser={user}
				authenticatedUserRoute={authenticatedUserRoute}
				pagesRoutes={pagesRoutes}
				activePageUrl={Constants.app.routes.PRIVACY_POLICY}
			/>
			<Container>
				<Widget>
					<div className="privacy-policy-content">
						<Header size="large">Privacy Policy</Header>
						<Text>
							Millennium Promise built the cRDLS app as not for commercial app. This
							SERVICE is provided by Millennium Promise and is intended for use to
							expand coverage.
						</Text>
						<Text>
							This page is used to inform visitors regarding our policies with the
							collection, use, and disclosure of Personal Information if anyone
							decided to use our Service.
						</Text>
						<Text>
							If you choose to use our Service, then you agree to the collection and
							use of information in relation to this policy. The Personal Information
							that we collect is used for providing and improving the Service. We will
							not use or share your information with anyone except as described in
							this Privacy Policy.
						</Text>
						<Text>
							The terms used in this Privacy Policy have the same meanings as in our
							Terms and Conditions, which is accessible at cRDLS unless otherwise
							defined in this Privacy Policy.
						</Text>
						<Text>
							<strong>Information Collection and Use</strong>
						</Text>
						<Text>
							For a better experience, while using our Service, we may require you to
							provide us with certain personally identifiable information. The
							information that we request will be retained by us and used as described
							in this privacy policy.
						</Text>
						<Text>
							The app does use third party services that may collect information used
							to identify you.
						</Text>
						<Text>
							Link to privacy policy of third party service providers used by the app
						</Text>
						<Text>
							<a href="https://www.google.com/policies/privacy/">
								Google Play Services
							</a>
						</Text>
						<Text>
							<strong>Log Data</strong>
						</Text>
						<Text>
							We want to inform you that whenever you use our Service, in a case of an
							error in the app we collect data and information (through third party
							products) on your phone called Log Data. This Log Data may include
							information such as your device Internet Protocol (“IP”) address, device
							name, operating system version, the configuration of the app when
							utilizing our Service, the time and date of your use of the Service, and
							other statistics.
						</Text>
						<Text>
							<strong>Cookies</strong>
						</Text>
						<Text>
							Cookies are files with a small amount of data that are commonly used as
							anonymous unique identifiers. These are sent to your browser from the
							websites that you visit and are stored on your device's internal memory.
						</Text>
						<Text>
							This Service does not use these “cookies” explicitly. However, the app
							may use third party code and libraries that use “cookies” to collect
							information and improve their services. You have the option to either
							accept or refuse these cookies and know when a cookie is being sent to
							your device. If you choose to refuse our cookies, you may not be able to
							use some portions of this Service.
						</Text>
						<Text>
							<strong>Service Providers</strong>
						</Text>
						<Text>
							We may employ third-party companies and individuals due to the following
							reasons:
						</Text>
						<ul>
							<li>
								<Text>To facilitate our Service;</Text>
							</li>
							<li>
								<Text>To provide the Service on our behalf;</Text>
							</li>
							<li>
								<Text>To perform Service-related services; or</Text>
							</li>
							<li>
								<Text>To assist us in analyzing how our Service is used.</Text>
							</li>
						</ul>
						<Text>
							We want to inform users of this Service that these third parties have
							access to your Personal Information. The reason is to perform the tasks
							assigned to them on our behalf. However, they are obligated not to
							disclose or use the information for any other purpose.
						</Text>
						<Text>
							<strong>Security</strong>
						</Text>
						<Text>
							We value your trust in providing us your Personal Information, thus we
							are striving to use commercially acceptable means of protecting it. But
							remember that no method of transmission over the internet, or method of
							electronic storage is 100% secure and reliable, and we cannot guarantee
							its{' '}
						</Text>
						<Text>
							<strong>Changes to This Privacy Policy</strong>
						</Text>
						<Text>
							We may update our Privacy Policy from time to time. Thus, you are
							advised to review this page periodically for any changes. We will notify
							you of any changes by posting the new Privacy Policy on this page. These
							changes are effective immediately after they are posted on this page.
						</Text>
						<Text>
							<strong>Contact Us</strong>
						</Text>
						<Text>
							If you have any questions or suggestions about our Privacy Policy, do
							not hesitate to contact us at +233 26 928 7004.
						</Text>
					</div>
				</Widget>
			</Container>
		</div>
	);
};

export default PrivacyPolicy;
