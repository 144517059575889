import { Constants as SharedConstants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedConstants,
	configs: [
		{
			title: 'Lab Technician',
			description: 'Fill the required details of the lab technician',
			fields: UserForm.userFields,
			path: SharedConstants.app.routes.dashboard.ADD_LAB_TECHNICIAN,
			name: 'technician'
		}
	]
};
