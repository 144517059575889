import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { deleteMidwife } from '../_helpers';
import MidwifeDetail from './MidwifeDetail';
import MidwifeList from './MidwifeList';
import ToolBar from './Toolbar';

const options: WrappedListViewOptions = {
	title: 'Midwives',
	form: Constants.app.routes.dashboard.ADD_MIDWIFE,
	description: 'Mange the registered midwifes in the clinic',
	storeKey: 'midwife',
	stateKey: 'midwives'
};

export default wrappedAdvancedListView(
	MidwifeList as any,
	MidwifeDetail as any,
	ToolBar as any,
	options,
	deleteMidwife
);
