import {
	COURIER_RESPONSE_SCHEMA,
	Dispatch as Action,
	SAMPLES_RESPONSE_SCHEMA
} from '../../services';

export const Sample = {
	CLEAR_RESPONSE_TYPE: '@sample-store/clear-sample-response',
	STORE_SELECTED_REQUEST: '@sample-store/store-selected-request',
	CLEAR_SELECTED_REQUEST: '@sample-store/clear-selected-request',

	FETCHING_SAMPLES: '@sample-store/fetching_samples',
	FETCH_SAMPLES_SUCCESS: '@sample-store/store-requests',
	FETCH_SAMPLES_ERROR: 'FETCH_SAMPLES_ERROR',

	CLEAR_ERROR_STATE: '@sample-store/clear-request-error-state',
	LAB_REQUEST_RESPONSE_SUCCESS: '@sample-api/post-lab-response-success',
	LAB_REQUEST_RESPONSE_FAIL: '@sample-api/post-lab-response-fail',

	STORE_LAB_COURIERS_SUCCESS: '@sample-api/get-lab-couriers-success',

	SAMPLE_REQUEST_CONFIRM_SUCCESS: '@sample-api/post-sample-request-confirm-success',
	SAMPLE_REQUEST_CONFIRM_ERROR: '@sample-api/post-sample-request-confirm-fail',

	ASSIGN_RIDER_TO_SAMPLE_SUCCESS: '@sample-api/assign-rider-success',
	ASSIGN_RIDER_TO_SAMPLE_FAIL: '@sample-api/assign-rider-fail',

	FILTER_SAMPLES: 'FILTER_SAMPLES',
	DELETE_SAMPLES_SUCCESS: 'DELETE_SAMPLES_SUCCESS',
	DELETE_SAMPLES_ERROR: 'DELETE_SAMPLES_ERROR'
};

export interface SampleState {
	requests: SAMPLES_RESPONSE_SCHEMA[];
	storeRequests: SAMPLES_RESPONSE_SCHEMA[];
	response: SAMPLES_RESPONSE_SCHEMA | null;
	error: any;
	riders: Array<COURIER_RESPONSE_SCHEMA> | null;
	confirmed: boolean;
	selectedRequest: SAMPLES_RESPONSE_SCHEMA | null;
	loading: boolean;
}

const defaultState: SampleState = {
	requests: [],
	response: null,
	error: null,
	riders: null,
	confirmed: false,
	selectedRequest: null,
	loading: false,
	storeRequests: []
};

export default (state = defaultState, action: Action) => {
	switch (action.type) {
		case Sample.LAB_REQUEST_RESPONSE_SUCCESS:
			return { ...state, selectedRequest: action.payload, error: null, riders: null };

		case Sample.LAB_REQUEST_RESPONSE_FAIL:
			return { ...state, error: action.payload, riders: null };

		case Sample.CLEAR_RESPONSE_TYPE:
			return { ...state, ...defaultState };

		case Sample.STORE_LAB_COURIERS_SUCCESS:
			return { ...state, riders: action.payload };

		case Sample.SAMPLE_REQUEST_CONFIRM_SUCCESS:
			return { ...state, confirmed: true, error: null, response: null };

		case Sample.SAMPLE_REQUEST_CONFIRM_ERROR:
			return { ...state, error: action.payload, confirmed: false };

		case Sample.ASSIGN_RIDER_TO_SAMPLE_SUCCESS:
			const assignedRequests = action.payload as SAMPLES_RESPONSE_SCHEMA[];
			const requestsWithAssignRiders = state.requests.map(request => {
				const newRequest = assignedRequests.find(({ id }) => request.id === id);
				return newRequest ? newRequest : request;
			});
			return {
				...state,
				confirmed: true,
				error: null,
				requests: requestsWithAssignRiders,
				storeRequests: requestsWithAssignRiders
			};

		case Sample.ASSIGN_RIDER_TO_SAMPLE_FAIL:
			return { ...state, confirmed: false, error: action.payload };

		case Sample.STORE_SELECTED_REQUEST:
			return { ...state, selectedRequest: action.payload };

		case Sample.FETCH_SAMPLES_SUCCESS:
			return {
				...state,
				requests: action.payload.filter(
					({ status }: any) => status === 'PENDING' || status === 'PARTIAL_PENDING'
				),
				storeRequests: action.payload,
				loading: false
			};

		case Sample.CLEAR_ERROR_STATE:
			return { ...state, error: null };

		case Sample.FETCH_SAMPLES_ERROR:
			return { ...state, requests: [], error: action.payload, loading: false };

		case Sample.FETCHING_SAMPLES:
			return { ...state, loading: true };

		case Sample.CLEAR_SELECTED_REQUEST:
			return { ...state, error: null, selectedRequest: null };

		case Sample.FILTER_SAMPLES:
			const filterStatus = action.payload;
			const { storeRequests } = state;
			const filteredRequests =
				filterStatus === 'ALL'
					? storeRequests
					: storeRequests.filter(({ status }) => {
							if (filterStatus === 'PENDING') {
								return status === 'PENDING' || status === 'PARTIAL_PENDING';
							}
							return status === filterStatus;
					  });
			return { ...state, requests: filteredRequests };

		case Sample.DELETE_SAMPLES_SUCCESS:
			const deletedRequests = action.payload as SAMPLES_RESPONSE_SCHEMA[];
			const filteredDeletedRequests = state.storeRequests.filter(request => {
				const newRequest = deletedRequests.find(({ id }) => request.id === id);
				return !Boolean(newRequest);
			});
			return {
				...state,
				error: null,
				requests: filteredDeletedRequests.filter(
					req => req.status === 'PENDING' || req.status === 'PARTIAL_PENDING'
				),
				storeRequests: filteredDeletedRequests
			};

		case Sample.DELETE_SAMPLES_ERROR:
			return { ...state, error: action.payload };

		default:
			return state;
	}
};
