import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import 'sweetalert/dist/sweetalert.css';
import AppRegistry from './AppRegistry';
import './resource/assets/css/app.css';
import { unregister } from './resource/services';

ReactDOM.render(<AppRegistry />, document.getElementById('root'));
unregister();
