import PropTypes from 'prop-types';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';
import {
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Text,
	TextField,
	Widget
} from '../../../resource/components';
import { convertToReadable, REGION_RESPONSE_SCHEMA, UserForm } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import {
	clearErrorState,
	clearLevelState,
	Input,
	Level,
	storeRegionInState,
	submitLevel
} from '../_helpers';
import './css/style.css';

export interface ILevelsFormProps extends RouteComponentProps {
	level: Level;
	user: AppStore['user']['user'];
	id: string | null;
	clearErrorState: () => void;
	clearLevelState: () => void;
	submitLevel: (places: Array<{ name: string }>) => Promise<void>;
	error: any;
	region: REGION_RESPONSE_SCHEMA | null;
	storeRegionInState: (region: REGION_RESPONSE_SCHEMA) => void;
}

export interface ILevelsFormState {
	level: Level;
	id: string | null;
	places: Array<Input>;
}

class LevelForm extends Component<ILevelsFormProps, ILevelsFormState> {
	public readonly state: ILevelsFormState = {
		level: 'region',
		id: null,
		places: []
	};

	public static propTypes: PropTypes.InferProps<ILevelsFormProps> = {
		level: PropTypes.oneOf<Level>(['region', 'district', 'sub_district', 'community']),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
		clearErrorState: PropTypes.func.isRequired,
		clearLevelState: PropTypes.func.isRequired,
		submitLevel: PropTypes.func.isRequired,
		error: PropTypes.any,
		region: PropTypes.oneOfType([
			PropTypes.any,
			PropTypes.shape<PropTypes.ValidationMap<REGION_RESPONSE_SCHEMA>>({})
		]),
		storeRegionInState: PropTypes.func.isRequired
	};

	public static defaultProps: Partial<ILevelsFormProps> = {
		id: null
	};

	public componentDidMount() {
		this.setState(() => ({ level: this.props.level, id: this.props.id }));
	}

	public componentWillReceiveProps(props: Readonly<ILevelsFormProps>) {
		if (props !== this.props) {
			if (this.props.region !== props.region && props.region) {
				this.props.storeRegionInState(props.region);
				this.props.history.goBack();
			}
			if (props.level !== this.props.level) {
				this.setState(() => ({ level: props.level, id: props.id }));
			}
		}
	}

	public render(): ReactNode {
		const { level } = this.state;
		return (
			<ErrorHandler error={this.props.error} onExit={this.onErrorExit}>
				<RestrictedAccess {...this.props} user={this.props.user}>
					<MainContent
						title={`${convertToReadable(level)} setup`}
						toolbar={this.renderToolbar()}
					>
						<Container>
							<Widget className="iw form">
								<Header className="header" textAlign="center">
									{convertToReadable(level)} Setup{' '}
									{this.props.region && `for ${this.props.region.name}`}
								</Header>
								<Text>Click on the add icon to add more {level}.</Text>
								{this.renderTextFields()}
								<div style={style.btnWrapper}>
									<Button
										icon
										className="iw icon btn"
										size="large"
										onClick={this.onAddInput}
									>
										<Icon name="add" />
									</Button>
								</div>
							</Widget>
						</Container>
					</MainContent>
				</RestrictedAccess>
			</ErrorHandler>
		);
	}

	/**
	 * renderTextFields is a method which renders a textfield for each
	 * item in the places component state
	 * @returns ReactNode
	 */
	public renderTextFields = (): ReactNode => {
		const { places, level } = this.state;
		return (
			places.length >= 1 &&
			places.map((val: Input, _i: number) => (
				<div key={_i} className="result-wrapper">
					<TextField
						type="text"
						label={`Enter the name of the ${level}`}
						onChange={this.onTextChange}
						value={val.value}
						name={val.name}
					/>
					<Button
						icon
						size="small"
						className="iw icon btn close"
						onClick={() => this.onDeleteInput(val)}
						style={{ marginLeft: '1rem' }}
					>
						<Icon name="close" />
					</Button>
				</div>
			))
		);
	};

	public renderToolbar = () => {
		return (
			<div style={style.toolbar} className="toolbar">
				<Button icon className="btn primary" onClick={this.onSave}>
					<Icon name="cloud upload" />
				</Button>
				<Button icon className="btn danger" onClick={this.onClose}>
					<Icon name="close" />
				</Button>
			</div>
		);
	};

	/**
	 * onAddInput is a method on the button click event which
	 * adds a new button to the dom and sets the component values
	 * state with a new field
	 * @returns void
	 */
	public onAddInput = (): void => {
		const input = { name: UserForm.UUID(), value: '' };
		this.setState((prevState: Readonly<ILevelsFormState>) => ({
			places: [...prevState.places, input]
		}));
	};

	/**
	 * onTextChange is a method on the textfield change event which
	 * sets the component state of with the value of the input element
	 * @param event ChangeEvent
	 */
	public onTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
		event.persist();
		const { name, value } = event.target;
		this.setState((prevState: Readonly<ILevelsFormState>) => ({
			places: prevState.places.map(val => {
				if (val.name === name) {
					val.value = value;
				}
				return val;
			})
		}));
	};

	/**
	 * onDeleteInput is a function which deletes an input field from the
	 * component state values array
	 * @param input Input
	 */
	public onDeleteInput = (input: Input): void => {
		this.setState((prevState: Readonly<ILevelsFormState>) => ({
			places: prevState.places.filter(val => val !== input)
		}));
	};

	/**
	 * onSave is an event listener on the submit button which
	 * takes the component state data and sends the data to the
	 * action creator submitLevel for saving on the server
	 * @returns void
	 */
	public onSave = (): void => {
		const { places } = this.state;
		const names = places.map(place => ({ name: place.value }));
		this.props.submitLevel(names);
	};

	/**
	 * onClose is an event listener on the close button which
	 * clears the component state and moves the user to the previous
	 * page. the level state in the store is also cleared
	 * any form submission is cancelled which it is triggered
	 * @returns void
	 */
	public onClose = (): void => {
		this.props.clearLevelState();
		this.props.history.goBack();
	};

	/**
	 * onErrorExit is an event listener on the exit event of the error
	 * handle component which clears the error field in the region state
	 * in the app store
	 * @returns void
	 */
	public onErrorExit = (): void => {
		this.props.clearErrorState();
	};
}

const style: { [key: string]: React.CSSProperties } = {
	btnWrapper: {
		textAlign: 'right'
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center'
	}
};

const mapStateToProps = (state: AppStore) => ({
	level: state.regional.level,
	id: state.regional.id,
	user: state.user.user,
	error: state.regional.error,
	region: state.regional.region
});

const mapDispatchToProps = {
	clearErrorState,
	clearLevelState,
	submitLevel,
	storeRegionInState
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LevelForm);
