import { Constants as K } from '../../../resource/constants';
import { NHIS_SCHEMA } from '../../../resource/services';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const createAndUpdateScheme = (data: any) => {
	const scheme = { ...data.scheme, ...data.level.scheme };
	const method = data.scheme.id ? 'PUT' : 'POST';
	const apiRoute = {
		POST: K.api.routes.ADD_NHIS_SCHEME,
		PUT: K.api.routes.UPDATE_SCHEME
	};
	if (method === 'PUT') {
		scheme.district_id = scheme.district.id;
		delete scheme.district;
	}
	return { url: apiRoute[method], method, store: K.app.db.SCHEME, ...scheme };
};

/**
 * the function formats the data that is stored in the misc
 * state in the app store to the form structure that the user
 * can make edit to the data
 * @param data nhis
 */
export const changeMiscStateToFormState = (data: any): any => {
	const scheme = data as NHIS_SCHEMA;
	return { id: scheme.id, scheme };
};

/**
 * the helper function deletes a scheme from the scheme list
 * by sending the selected scheme for deletion to the server
 * and the deletion api route
 * @param data scheme from list
 */
export const deleteScheme = (data: any) => {};
