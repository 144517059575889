import React from 'react';
import PropTypes from 'prop-types';
import './css/tools.css';

export const Container = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div {...props} className={`container ${className ? className : ''}`}>
		{props.children}
	</div>
);

Container.propTypes = {
	children: PropTypes.node
};
