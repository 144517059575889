import React from 'react';
import { Table } from 'semantic-ui-react';
import { TableWrap } from '../../../resource/components';
import { ListViewProps } from '../../../resource/hocs';
import {
	convertToReadableDate,
	getFullName,
	MIDWIFE_RESPONSE_SCHEMA
} from '../../../resource/services';

const MidwifeListView = (props: ListViewProps<MIDWIFE_RESPONSE_SCHEMA>) => {
	return (
		<TableWrap>
			<Table basic="very" sortable selectable>
				<Table.Header>
					<Table.Row style={{ backgroundColor: 'teal' }}>
						<Table.HeaderCell
							style={{ ...styles.header, ...styles.cell, width: '1.7rem' }}
						>
							#
						</Table.HeaderCell>
						<Table.HeaderCell
							style={{ ...styles.header, ...styles.cell }}
							onClick={() => props.onSort('name')}
							sorted={
								props.sortedColumn === 'name' ? props.sortedDirection : undefined
							}
						>
							Name
						</Table.HeaderCell>
						<Table.HeaderCell
							style={{ ...styles.header, ...styles.cell }}
							onClick={() => props.onSort('community')}
							sorted={
								props.sortedColumn === 'community'
									? props.sortedDirection
									: undefined
							}
						>
							Date of Birth
						</Table.HeaderCell>
						<Table.HeaderCell
							style={{ ...styles.header, ...styles.cell }}
							onClick={() => props.onSort('district')}
							sorted={
								props.sortedColumn === 'district'
									? props.sortedDirection
									: undefined
							}
						>
							Telephone
						</Table.HeaderCell>
						<Table.HeaderCell
							style={{ ...styles.header, ...styles.cell }}
							onClick={() => props.onSort('region')}
							sorted={
								props.sortedColumn === 'region' ? props.sortedDirection : undefined
							}
						>
							Username
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{props.dataToRender.map((midwife: MIDWIFE_RESPONSE_SCHEMA, i: number) => (
						<Table.Row key={midwife.id} onClick={() => props.onSelect(midwife)}>
							<Table.Cell>
								{((props.activePage as number) - 1) * props.pageSize + i + 1}
							</Table.Cell>
							<Table.Cell style={styles.cell}>{getFullName(midwife)}</Table.Cell>
							<Table.Cell style={styles.cell}>
								{convertToReadableDate(midwife.user.dob)}
							</Table.Cell>
							<Table.Cell style={styles.cell}>{midwife.user.telephone}</Table.Cell>
							<Table.Cell style={styles.cell}>{midwife.user.username}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</TableWrap>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	header: {
		fontWeight: 300,
		fontFamily: 'Open sans',
		fontSize: '1.14rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '0.85rem 1rem'
	}
};

export default MidwifeListView;
