import React from 'react';
import { Button, Icon, Loader, Divider, Header } from 'semantic-ui-react';
import { DetailProps } from '../../../resource/hocs';
import {
	convertToReadable,
	getUserDetails,
	CLINIC_RESPONSE_SCHEMA
} from '../../../resource/services';
import './css/detail.css';
import { Constants } from '../../../resource/constants';

const ClinicDetail = ({ data, loading, ...props }: DetailProps<CLINIC_RESPONSE_SCHEMA>) => {
	const admin = data ? getUserDetails(data.admin) : null;
	return (
		<div className="detail-view">
			<div className="detail-block detail-block--small">
				{data && (
					<div>
						<Button
							icon
							className="detail-block-btn"
							onClick={() => props.handleEdit(data)}
						>
							<Icon name="pencil" color="green" />
						</Button>
						<Button
							icon
							className="detail-block-btn"
							onClick={() => props.handleDelete(data)}
						>
							<Icon name="trash" color="red" />
						</Button>
					</div>
				)}
				<Button size="medium" icon className="detail-block-btn" onClick={props.handleClose}>
					<Icon name="close" color="red" />
				</Button>
			</div>
			{loading ? (
				<Loader active size="massive" />
			) : (
				<React.Fragment>
					{data && (
						<div className="detail-block">
							<Divider horizontal>
								<span className="block-header">Details</span>
							</Divider>
							<div style={{ marginTop: '0.98rem' }} />
							{Object.keys(data).map((key: string, _i: number) => (
								<p className="details-text" key={_i}>
									{typeof (data as any)[key] === 'object' ||
									Constants.app.data.HIDDEN_FIELD.includes(key) ? null : (
										<React.Fragment>
											<span className="detail-text">
												{convertToReadable(key)}
											</span>{' '}
											-{' '}
											<span style={{ marginLeft: '0.4rem' }}>
												{(data as any)[key]}
											</span>
										</React.Fragment>
									)}
								</p>
							))}
						</div>
					)}
					{admin && (
						<div className="detail-block">
							<Divider horizontal>
								<span className="block-header">Admin</span>
							</Divider>
							<div style={{ marginTop: '0.98rem' }} />
							{Object.keys(admin).map((key: string, _i: number) => (
								<p className="details-text" key={_i}>
									{typeof (admin as any)[key] === 'object' ||
									Constants.app.data.HIDDEN_FIELD.includes(key) ? null : (
										<React.Fragment>
											<span className="detail-text">
												{convertToReadable(key)}
											</span>{' '}
											-{' '}
											<span style={{ marginLeft: '0.4rem' }}>
												{(admin as any)[key]}
											</span>
										</React.Fragment>
									)}
								</p>
							))}
						</div>
					)}
					{data === null && (
						<div className="detail-block">
							<Header
								textAlign="center"
								className="details-header details-header--error"
							>
								No Details
							</Header>
							<p className="details-text details-text--error">
								There is no information about the clinic. Please enter the details
								for the clinic by clicking on the edit icon above.
							</p>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default ClinicDetail;
