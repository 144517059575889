import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Constants } from '../../../resource/constants';
import { SAMPLES_RESPONSE_SCHEMA, ServerRequest } from '../../../resource/services';
import { Sample } from '../../../resource/store';

const useDelete = () => {
	const [loading, setLoading] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [success, setSuccess] = useState(false);
	const [message, setMessage] = useState('');
	const dispatch = useDispatch();

	const onDelete = async (data: SAMPLES_RESPONSE_SCHEMA[]) => {
		setLoading(true);
		try {
			const sampleIds = data.map(sample => sample.id);
			await ServerRequest.uploadService(
				Constants.api.routes.DELETE_LAB_REQUESTS,
				sampleIds,
				true,
				'DELETE'
			);
			setMessage('Lab requests deleted successfully');
			setSuccess(true);
			setShowAlert(true);
			dispatch({ type: Sample.DELETE_SAMPLES_SUCCESS, payload: data });
		} catch (error) {
			setSuccess(false);
			dispatch({ type: Sample.DELETE_SAMPLES_ERROR, payload: error });
		} finally {
			setLoading(false);
		}
	};

	const reset = () => {
		setLoading(false);
		setSuccess(false);
		setMessage('');
		setShowAlert(false);
	};

	return { showAlert, success, message, loading, onDelete, reset };
};

export default useDelete;
