import * as idb from 'idb';
import partition from 'lodash/partition';
import { Constants as K } from '../constants';

export interface IAsyncStorageListeners {
	AREARS: 'AREARS';
	LABS: 'LABS';
	CLINICS: 'CLINICS';
	SCHEMES: 'SCHEMES';
	TESTS: 'TESTS';
	TECHNICIANS: 'TECHNICIANS';
	MIDWIVES: 'MIDWIVES';
	COURIERS: 'COURIERS';
	REQUESTS: 'REQUESTS';
}

class StorageObserver<P> {
	public listeners: IAsyncStorageListeners = {
		AREARS: 'AREARS',
		LABS: 'LABS',
		CLINICS: 'CLINICS',
		SCHEMES: 'SCHEMES',
		TESTS: 'TESTS',
		TECHNICIANS: 'TECHNICIANS',
		MIDWIVES: 'MIDWIVES',
		COURIERS: 'COURIERS',
		REQUESTS: 'REQUESTS'
	};

	public dataKeys: { [K in keyof IAsyncStorageListeners]: string } = {
		AREARS: 'area_data',
		LABS: 'labs',
		CLINICS: 'clinics',
		SCHEMES: 'insurances',
		TESTS: 'lab_tests',
		TECHNICIANS: 'lab_technicians',
		MIDWIVES: 'midwives',
		COURIERS: 'riders',
		REQUESTS: 'samples'
	};

	private observers = Object.keys(this.listeners).reduce((acc, val) => {
		acc[val as keyof IAsyncStorageListeners] = [];
		return acc;
	}, {} as { [P in keyof IAsyncStorageListeners]: Function[] });

	public subscribe = (key: keyof IAsyncStorageListeners, eventHandler: Function) => {
		this.observers[key].push(eventHandler);
	};

	public unsubscribe = (key: keyof IAsyncStorageListeners, eventHandler: Function) => {
		const filteredObservers = this.observers[key].filter((fn) => fn !== eventHandler);
		this.observers[key] = filteredObservers;
	};

	public emit = (key: keyof IAsyncStorageListeners, data: P) => {
		this.observers[key].forEach((observer) => {
			observer(data);
		});
	};

	public broadcast = (data: P) => {
		Object.keys(this.observers).forEach((observerKey) => {
			const key = observerKey as keyof IAsyncStorageListeners;
			this.emit(key, data);
		});
	};

	public broadcastByKey = (key: keyof IAsyncStorageListeners, data: any) => {
		this.observers[key].forEach(() => {
			this.emit(key, this.getDataForKey(key, data) as any);
		});
	};

	public getDataForKey = (key: keyof IAsyncStorageListeners, data: any[]) => {
		const [[extractedDataValue]] = partition(data, this.dataKeys[key]);
		const [values] = Object.values(extractedDataValue);
		return values;
	};
}

class Storage<P> {
	public conn: any;
	public store: any = null;

	public static ACCESS = true;
	public static listener = new StorageObserver();

	constructor(public storeName: string) {
		if (!('indexedDB' in window)) {
			Storage.ACCESS = false;
			return;
		}
		this.connect();
	}

	private connect = async () => {
		const { DATABASE_NAME: NAME, DATABASE_VERSION: VERSION } = K.app;
		this.conn = await idb.openDB(NAME, VERSION, {
			upgrade: (db) => {
				if (!(db as any).objectStoreNames.includes(this.storeName)) {
					this.store = db.createObjectStore(this.storeName);
				}
			}
		});
	};

	private getStore = async (mode: 'readonly' | 'readwrite') => {
		await this.connect();
		const databaseConnection = this.conn as idb.IDBPDatabase;
		const transaction = databaseConnection.transaction(this.storeName, mode);
		return transaction.objectStore(this.storeName);
	};

	public getAll = async () => {
		if (!Storage.ACCESS) {
			return null;
		}
		const store = await this.getStore('readonly');
		return await store.getAll();
	};

	public getItem = async (id: string) => {
		const items = await this.getAll();
		return items ? items.filter((item: any) => item.id === id)[0] : null;
	};

	public add = async (data: P) => {
		if (!Storage.ACCESS) return null;
		await this.connect();
		const databaseConnection = this.conn as idb.IDBPDatabase;
		const transaction = databaseConnection.transaction(this.storeName, 'readwrite');
		const store = transaction.objectStore(this.storeName);
		const storedData = await store.put(data);
		return storedData.valueOf() as P;
	};

	public addAll = async (data: P[]) => {
		let items: P[] = [];
		for await (const item of data) {
			this.add(item);
			items = [...items, item];
		}
		return items;
	};

	public remove = async (key: string) => {
		if (!Storage.ACCESS) return null;
		const store = await this.getStore('readwrite');
		await store.delete(key);
		return true;
	};

	public removeAll = async () => {
		if (!Storage.ACCESS) return null;
		const store = await this.getStore('readonly');
		const keys = await store.getAllKeys();
		for await (const key of keys) {
			this.remove(String(key));
		}
		return true;
	};

	public static store = <P>(storeName: string) => {
		return new Storage<P>(storeName);
	};

	public static init = async (storeNames: string[]) => {
		const { DATABASE_NAME, DATABASE_VERSION } = K.app;
		return await idb.openDB(DATABASE_NAME, DATABASE_VERSION, {
			upgrade: (db) => {
				storeNames.forEach((storeName) => {
					if (!(db as any).objectStoreNames.contains(storeName)) {
						const store = db.createObjectStore(storeName);
						store.createIndex('id', 'id', { unique: false });
					}
				});
			}
		});
	};
}

export const updateDataStore = async (method: string, storeName: string, data: any) => {
	try {
		if (method.toLowerCase() !== 'delete') {
			let newData = data.admin
				? { ...(data.lab || data.clinic), admin: data.admin }
				: Object.values(data)[0];
			if (typeof newData !== 'object') {
				newData = data;
			}
			await Storage.store(storeName).add(newData);
			return true;
		}
		const key = String(Object.values(data)[0]);
		await Storage.store(storeName).remove(key);
		return true;
	} catch (error) {
		return false;
	}
};

export default Storage;
