import isEmpty from 'lodash/isEmpty';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownProps, Loader } from 'semantic-ui-react';
import {
	AdvancedForm,
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	ToolbarButton,
	Widget
} from '../../../resource/components';
import { getAllLabs } from '../../../resource/dataService';
import {
	CLINIC_RESPONSE_SCHEMA,
	getDateTimestamp,
	LAB_RESPONSE_SCHEMA
} from '../../../resource/services';
import { AppStore, clearDataForEdit } from '../../../resource/store';
import { clearClinicState, Constants as K, submitClinicForm } from '../_helpers';
import LabList from './LabList';

interface IClinicForm extends RouteComponentProps {
	submitClinicForm: (data: any) => Promise<void>;
	clinic: CLINIC_RESPONSE_SCHEMA | null;
	error: any;
	clearClinicState: () => void;
	user: AppStore['user']['user'];
	profile: AppStore['misc']['data'];
	clearDataForEdit: () => void;
}

interface IState {
	labs: LAB_RESPONSE_SCHEMA[] | null;
	labId: string;
	[key: string]: any;
	level: any;
	activePage: number;
	showStatus: boolean;
	loading: boolean;
	fetching: boolean;
	isEdit: boolean;
}

class ClinicForm extends Component<IClinicForm, IState> {
	public _isMounted = false;
	public urls = [K.app.routes.dashboard.ADD_CLINIC, ...K.configs.map(form => form.path)];
	public readonly state: IState = {
		labs: null,
		labId: '',
		level: {},
		activePage: 0,
		showStatus: false,
		loading: false,
		fetching: false,
		isEdit: false
	};

	public componentWillReceiveProps(props: IClinicForm) {
		if (props !== this.props && props.clinic) {
			this.setState(() => ({
				showStatus: Boolean(props.clinic),
				loading: false
			}));
		}
	}

	public async componentDidMount() {
		this._isMounted = true;
		this.setState(() => ({ fetching: true }));
		const labs = await getAllLabs();
		if (this._isMounted) {
			this.initializeDataProps(labs, this.props);
		}
	}

	public render(): ReactNode {
		return (
			<ErrorHandler onExit={this.onExit} error={this.props.error}>
				<RestrictedAccess {...this.props} user={this.props.user}>
					<MainContent
						title="Clinic"
						description="Register new clinics"
						toolbar={this.renderToolbar()}
					>
						<Container>
							<Widget>
								{this.state.fetching ? (
									<Loader active size="massive" />
								) : (
									<React.Fragment>
										{this.renderForms()}
										{this.state.isEdit ? null : this.renderActions()}
									</React.Fragment>
								)}
							</Widget>
						</Container>
						<Alert
							success
							title="Successful"
							message={
								this.state.isEdit
									? 'Clinic is updated successfully'
									: 'Clinic was created successfully.'
							}
							open={this.state.showStatus}
							onClose={this.onClose}
							onConfirmed={this.onClose}
						/>
					</MainContent>
				</RestrictedAccess>
			</ErrorHandler>
		);
	}

	public componentWillUnmount() {
		this._isMounted = false;
	}

	public renderForms = () => {
		const { activePage } = this.state;
		if (activePage === 0) {
			return (
				<LabList
					labs={this.state.labs}
					labId={this.state.labId}
					onLabSelect={this.onSelectLab}
				/>
			);
		}
		return K.configs.map((form, i) => {
			return activePage === i + 1 ? (
				<AdvancedForm
					key={i}
					fields={form.fields}
					name={form.name}
					title={form.title}
					description={form.description}
					onTextChange={this.onTextChange}
					onSelect={this.onSelect}
					onLevelSelect={this.onLevelSelect}
					showLevels={form.showLevels}
					values={this.state}
				/>
			) : null;
		});
	};

	public renderActions = () => {
		const { activePage: page } = this.state;
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.3rem' }}>
				<Button
					className="toolbar-btn iw btn"
					size="large"
					onClick={() => this.onPageChange(false)}
					style={{
						visibility: page === 0 ? 'hidden' : 'visible'
					}}
				>
					Previous
				</Button>
				<Button
					className="toolbar-btn iw btn"
					size="large"
					onClick={() => this.onPageChange()}
					style={{
						visibility: page === this.urls.length - 1 ? 'hidden' : 'visible'
					}}
				>
					Next
				</Button>
			</div>
		);
	};

	public renderToolbar = () => {
		const { loading, labId, clinic } = this.state;
		const admin = this.state['clinic-admin'];
		const isFormFilled = !Boolean(labId && clinic && admin);
		return (
			<div className="toolbar">
				<ToolbarButton
					loading={loading && Boolean(this.props.error)}
					iconName="cloud upload"
					content="Upload"
					onClick={this.onFormSubmit}
					disabled={isFormFilled}
				/>
				<ToolbarButton danger iconName="close" content="Cancel" onClick={this.onClose} />
			</div>
		);
	};

	public initializeDataProps = (labs: LAB_RESPONSE_SCHEMA[] | null, props: any) => {
		const { profile } = props;
		let data: any = {};
		if (!isEmpty(profile)) {
			const clinic = profile as CLINIC_RESPONSE_SCHEMA;
			data = {
				id: clinic.id,
				clinic: clinic,
				'clinic-admin': (clinic.admin && clinic.admin.user) || {},
				labId: clinic.lab.id
			};
		}
		this.setState(() => ({ labs, fetching: false, isEdit: !isEmpty(data), ...data }));
		if (!isEmpty(data)) {
			// this.props.history.push(K.app.routes.dashboard.ADD_CLINIC_DETAIL);
		}
	};

	public onFormSubmit = (): void => {
		const { labId, clinic, level } = this.state;
		if (labId && clinic) {
			const data = {
				lab_id: labId,
				...clinic,
				...level.clinic,
				'clinic-admin': {
					user: {
						...this.state['clinic-admin'],
						dob: getDateTimestamp(this.state['clinic-admin'].dob)
					}
				}
			};
			this.setState(() => ({ loading: true }));
			this.props.submitClinicForm(data);
		}
	};

	public onExit = (): void => {
		// clear clinic state in app store
		this.props.clearClinicState();
	};

	public onSelectLab = (lab: LAB_RESPONSE_SCHEMA): void => {
		this.setState(() => ({ labId: lab.id }));
	};

	public onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		event.persist();
		const target = event.target;
		this.setState((prevState: Readonly<IState>) => {
			const data = { ...prevState[target.id], [target.name]: target.value };
			return { [target.id]: data };
		});
	};

	public onSelect = (_: any, data: DropdownProps): void => {
		if (data.options) {
			const value = data.options.find(({ value }) => value === data.value);
			this.setState((prevState: Readonly<IState>) => ({
				[data.id]: {
					...prevState[data.id],
					[data.name]: value
				}
			}));
		}
	};

	public onLevelSelect = (data: any): void => {
		this.setState(prevState => {
			return {
				...prevState,
				level: { ...prevState.level, ...data }
			};
		});
	};

	public onPageChange = (isNext = true) => {
		let page = this.state.activePage;
		if (isNext) {
			page++;
		} else {
			page--;
		}
		this.setState(() => ({ activePage: page }));
	};

	public onClose = () => {
		this.setState({ showStatus: false });
		this.props.clearDataForEdit();
		this.props.clearClinicState();
		this.props.history.push(K.app.routes.dashboard.CLINICS);
	};
}

const mapStateToProps = (state: AppStore) => ({
	clinic: state.clinic.clinic,
	error: state.clinic.error,
	user: state.user.user,
	profile: state.misc.data
});

const mapDispatchToProps = {
	submitClinicForm,
	clearClinicState,
	clearDataForEdit
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClinicForm);
