import { Constants as K } from '../../constants';
import { Dispatch, LAB_RESPONSE_SCHEMA } from '../../services';

export const Lab = {
	STORE_LAB_FACILITY_TYPE: '@store/store-lab',
	CLEAR_LAB_FACILITY_TYPE: '@store/clear-lab-state',
	ADD_LAB_SUCCESS: '@@api/post-lab-success',
	ADD_LAB_FAIL: '@@api/post-lab-fail',
	STORE_LABS: 'STORE_LABS',
	REMOVE_STORED_LABORATORY: 'REMOVE_STORED_LABORATORY',
	UPDATE_STORED_LABORATORY: 'UPDATE_STORED_LABORATORY'
};

export interface LabState {
	lab: LAB_RESPONSE_SCHEMA | null;
	error: any;
	labs: Array<LAB_RESPONSE_SCHEMA> | null;
}

const defaultState: LabState = {
	lab: null,
	error: null,
	labs: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Lab.STORE_LAB_FACILITY_TYPE:
			return { ...state, lab: action.payload };

		case Lab.CLEAR_LAB_FACILITY_TYPE:
			return { ...state, ...defaultState };

		case K.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };

		case Lab.ADD_LAB_SUCCESS:
			const newLab = action.payload;
			const newLabs = (state.labs || []).concat(newLab).sort();
			return { ...state, labs: newLabs, error: null };

		case Lab.ADD_LAB_FAIL:
			return { ...state, lab: null, error: action.payload };

		case Lab.STORE_LABS:
			return { ...state, labs: action.payload, error: null };

		case Lab.REMOVE_STORED_LABORATORY:
			return { ...state, lab: null };

		case Lab.UPDATE_STORED_LABORATORY:
			const labs = state.labs!.map(lab => {
				if (lab.id === action.payload.id) return action.payload;
				return lab;
			});
			return { ...state, lab: null, labs };

		default:
			return state;
	}
};
