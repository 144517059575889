import { AppStore } from '../../../resource/store';
import { getDateTimestamp, MIDWIFE_RESPONSE_SCHEMA } from '../../../resource/services';
import { Constants as K } from '../../../resource/constants';

/**
 * getMidwifeInStore is a function which retrieves the default
 * values of the midwife from the app store
 * @param store AppStore
 */
export const getMidwifeInStore = (store: AppStore) => ({ user: {} });

/**
 * getMidwifeInMiscState is a function which retrieves the midwife
 * in the misc state of the app store
 * @param state MiscState
 */
export const getMidwifeInMiscState = (state: AppStore['misc']) => {
	return { user: state.user };
};

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const formatFormState = (data: any) => {
	const format = { ...data.midwife, dob: getDateTimestamp(data.midwife.dob) };
	return { url: K.api.routes.ADD_MIDWIFE, method: 'POST', user: format, store: K.app.db.MIDWIFE };
};

/**
 * the function formats the data that is stored in the misc
 * state in the app store to the form structure that the user
 * can make edit to the data
 * @param data midwife
 */
export const formatDataToState = (data: any) => {
	return {};
};

/**
 * the function deletes a midwife from the list of midwives
 * from lab by returning the particular midwife that is to
 * be deleted with the url and http method
 * @param data selected midwife from list
 */
export const deleteMidwife = (data: any) => {
	const { id } = data as MIDWIFE_RESPONSE_SCHEMA;
	return {
		url: K.api.routes.DELETE_MIDWIFE,
		method: 'DELETE',
		midwife_id: id,
		store: K.app.db.MIDWIFE
	};
};
