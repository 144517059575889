import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Pagination } from 'semantic-ui-react';
import { EmptyState, Widget } from '../../../resource/components';
import { ListProps } from '../../../resource/hocs';
import { usePagination } from '../../../resource/hooks';
import { NHIS_SCHEMA } from '../../../resource/services';
import SchemesListView from './SchemesListView';

interface Props extends ListProps<NHIS_SCHEMA> {}

const SchemesList = (props: Props) => {
	const { data, onSort, onSelect, sortedColumn, sortedDirection } = props;
	const {
		dataToRender,
		pageSize,
		dataSize,
		onPageChange,
		activePage,
		totalPages
	} = usePagination(data || []);

	if (isEmpty(data)) {
		return (
			<EmptyState
				title="No registered NHIS Scheme"
				description="There are no registered NHIS scheme. Click on the add button to add a new scheme."
			/>
		);
	}
	return (
		<Widget>
			<SchemesListView
				onSort={onSort}
				onSelect={onSelect}
				sortedColumn={sortedColumn}
				sortedDirection={sortedDirection}
				dataToRender={dataToRender}
				activePage={activePage}
				pageSize={pageSize}
			/>
			{dataSize > pageSize && (
				<div className="pagination">
					<Pagination
						totalPages={totalPages}
						activePage={activePage}
						onPageChange={onPageChange}
						firstItem={null}
						lastItem={null}
					/>
				</div>
			)}
		</Widget>
	);
};

export default SchemesList;
