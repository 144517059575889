import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { deleteClinic } from '../_helpers';
import ClinicDetail from './ClinicDetail';
import ClinicsList from './ClinicsList';
import Toolbar from './Toolbar';

const scripts: WrappedListViewOptions = {
	title: 'Clinics',
	form: Constants.app.routes.dashboard.ADD_CLINIC,
	description: 'Manage registered clinics in RDLS',
	storeKey: 'clinic',
	stateKey: 'clinics'
};

export default wrappedAdvancedListView(
	ClinicsList as any,
	ClinicDetail as any,
	Toolbar as any,
	scripts,
	deleteClinic
);
