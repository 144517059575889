import React from 'react';
import { Header, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Authenticate from './images/authenticate.svg';
import { Text } from '../../resource/components';
import { Constants } from '../../resource/constants';
import './css/authenticate.css';

export const UnauthenticatedUser = () => {
	return (
		<div className="un-authenticate wrapper">
			<Container className="contain">
				<Header>Authentication Error</Header>
				<img src={Authenticate} alt="authenticate" />
				<Text>You must sign in first be accessing the dashboard.</Text>
				<Link to={Constants.app.routes.SIGNIN} className="link-btn">
					Sign In
				</Link>
			</Container>
		</div>
	);
};

export default UnauthenticatedUser;
