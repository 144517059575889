import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { deleteCourier } from '../_helpers';
import CourierDetail from './CourierDetail';
import CourierLists from './CourierLists';
import ToolBar from './Toolbar';

const options: WrappedListViewOptions = {
	title: 'Couriers',
	form: Constants.app.routes.dashboard.ADD_COURIER,
	description: 'Manage the registered couriers/riders for your laboratory',
	storeKey: 'courier',
	stateKey: 'couriers'
};

export default wrappedAdvancedListView(
	CourierLists as any,
	CourierDetail as any,
	ToolBar as any,
	options,
	deleteCourier
);
