import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import {
	Container,
	DatePicker,
	ErrorHandler,
	MainContent,
	RestrictedAccess
} from '../../../resource/components';
import { useDownload } from '../../../resource/hooks';
import {
	convertToDatePickerFormat,
	printHtmlContent,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import { sortRequestInAscOrderByDate } from '../_helpers/requestService';
import PrintNhisClaims from './PrintNhisClaims';
import SummaryReportList from './SummaryReportList';
import SummaryToolbar from './Toolbar';

type Props = RouteComponentProps;

const SummaryReport = (props: Props) => {
	const [showCashReport, setShowCashReport] = useState(false);
	const user = useSelector(({ user: { user } }: AppStore) => user);
	const stateRequests = useSelector(({ sample: { storeRequests } }: AppStore) => storeRequests);
	const [filteredRequests, setFilteredRequests] = useState<SAMPLES_RESPONSE_SCHEMA[]>([]);
	const [originalFilteredRequests, setOriginalFilteredRequests] = useState<
		SAMPLES_RESPONSE_SCHEMA[]
	>([]);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [disableToolbarActions, setDisableToolbarActions] = useState(true);
	const [amount, setAmount] = useState(0);
	const [dateRange, setDateRange] = useState<Date[]>([]);
	const [dateRangeIndex, setDateRangeIndex] = useState(0);
	const summaryTableId = 'onDownloadTable';
	const printId = 'printClaimId';
	const { downloadTableAsPdf, downloadAsExcel } = useDownload({
		htmlElementId: summaryTableId,
		fileName: 'summary-report'
	});

	useEffect(() => {
		const requests = (stateRequests || []).filter(({ date_of_delivery, has_insurance }) =>
			date_of_delivery && showCashReport ? !has_insurance : has_insurance
		);
		setFilteredRequests(requests);
		setOriginalFilteredRequests(requests);
		setDisableToolbarActions(requests.length < 0);
	}, [stateRequests, showCashReport]);

	useEffect(() => {
		const totalAmount = filteredRequests.reduce(
			(acc, sample) =>
				acc + sample.lab_tests.reduce((a, test) => a + test.lab_test.NHIS_FEE, 0),
			0
		);
		setAmount(totalAmount);
	}, [filteredRequests]);

	useEffect(() => {
		if (dateRange.length !== 2) return setFilteredRequests(originalFilteredRequests);
		const startTime = new Date(dateRange[0]).getTime();
		const endTime = new Date(dateRange[1]).getTime();
		const filteredRequestsFromDateRange = originalFilteredRequests.filter(
			({ date_of_delivery }) => {
				if (!date_of_delivery) return false;
				const deliveredTime = new Date(date_of_delivery.trim()).getTime();
				return deliveredTime >= startTime && deliveredTime <= endTime;
			}
		);
		setFilteredRequests(filteredRequestsFromDateRange);
	}, [dateRange, originalFilteredRequests]);

	const onCashReport = () => {
		const isCashReport = !showCashReport;
		const filterRequests = stateRequests.filter(({ has_insurance, date_of_delivery }) =>
			date_of_delivery && isCashReport ? !has_insurance : has_insurance
		);
		setShowCashReport(isCashReport);
		setFilteredRequests(filterRequests);
	};

	const onViewDatePicker = (isShow: boolean, index?: number) => () => {
		if (index) setDateRangeIndex(index);
		setShowDatePicker(isShow);
	};

	const onSelectDate = (date: Date) => {
		const newDateRange = [...dateRange];
		newDateRange[dateRangeIndex] = date;
		if (dateRangeIndex === 0) delete newDateRange[1];
		setDateRange(newDateRange);
		onViewDatePicker(false)();
	};

	const onClear = () => {
		setDateRange([]);
		setDateRangeIndex(0);
	};

	const renderToolbar = () => (
		<SummaryToolbar
			onDownloadPdf={() => {
				downloadTableAsPdf({
					styles: { fontSize: 7, valign: 'middle' },
					columnStyles: {
						0: { columnWidth: 8 },
						1: { columnWidth: 'auto' },
						2: { columnWidth: 'auto' },
						3: { columnWidth: 10 },
						4: { columnWidth: 18 },
						5: { columnWidth: 'auto' },
						6: { columnWidth: 'auto' },
						7: { columnWidth: 'auto' },
						8: { columnWidth: 'auto' }
					}
				});
			}}
			onDownloadExcel={downloadAsExcel}
			onCashReport={onCashReport}
			isCashReport={showCashReport}
			disableToolbarActions={disableToolbarActions}
			onPrintClaims={() => printHtmlContent(printId)}
		/>
	);

	return (
		<ErrorHandler>
			<RestrictedAccess {...props} user={user}>
				<MainContent
					title="Summary Report"
					description="Report for request by clients"
					toolbar={renderToolbar()}
				>
					<Container>
						<Grid>
							<GridRow verticalAlign="middle">
								<GridColumn width="7">
									<Form.Input
										fluid
										iconPosition="left"
										placeholder="Select the start date"
										icon="calendar"
										onClick={onViewDatePicker(true, 0)}
										value={convertToDatePickerFormat(dateRange[0])}
										disabled={disableToolbarActions}
									/>
								</GridColumn>
								<GridColumn width="7">
									<Form.Input
										disabled={disableToolbarActions}
										fluid
										iconPosition="left"
										placeholder="Select the end date"
										icon="calendar"
										onClick={onViewDatePicker(true, 1)}
										value={convertToDatePickerFormat(dateRange[1])}
									/>
								</GridColumn>
								<GridColumn>
									<Button color="teal" onClick={onClear}>
										Clear
									</Button>
								</GridColumn>
							</GridRow>
						</Grid>
						<SummaryReportList
							summaryTableId={summaryTableId}
							samples={sortRequestInAscOrderByDate(filteredRequests)}
							totalAmount={amount}
							showCashReport={showCashReport}
						/>
					</Container>
					<PrintNhisClaims
						requests={filteredRequests}
						user={user as any}
						printId={printId}
						isRendered={dateRange.length === 2}
						monthOfClaim={dateRange[0]}
					/>
					<DatePicker
						onClose={onViewDatePicker(false)}
						onSelect={onSelectDate}
						open={showDatePicker}
					/>
				</MainContent>
			</RestrictedAccess>
		</ErrorHandler>
	);
};

export default SummaryReport;
