import React from 'react';
import SweetAlert from 'sweetalert-react';

type Props = {
	open: boolean;
	onClose?: () => void;
	title: string;
	message?: string | null;
	error?: boolean;
	success?: boolean;
	confirm?: boolean;
	onConfirmed?: () => void;
	warning?: boolean;
};

export const Alert = (props: Props) => {
	const { open, title, error, success, message, onClose, onConfirmed, warning } = props;
	const type = warning ? 'warning' : error ? 'error' : success ? 'success' : undefined;

	return (
		<SweetAlert
			type={type}
			show={open}
			title={title}
			text={message as string | undefined}
			onConfirm={onConfirmed}
			onClose={onClose}
			onEscapeKey={onClose}
		/>
	);
};

Alert.defaultProps = {
	confirm: true,
	error: false,
	success: true,
	warning: false
};
