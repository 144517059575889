import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { DropdownProps } from 'semantic-ui-react';
import {
	COMMUNITY_RESPONSE_SCHEMA,
	DISTRICT_RESPONSE_SCHEMA,
	REGION_RESPONSE_SCHEMA,
	SUB_DISTRICT_RESPONSE_SCHEMA
} from '../services';
import { AppStore } from '../store';

interface Options {
	region: string;
	district: string;
	subDistrict: string;
	community: string;
}

const find = <T extends { name: string; id: string }>(
	items: T[] | undefined | null,
	search: string
) => {
	if (!items) return null;
	const item = items.find(({ name, id }) => id === search || name === search);
	return item ? item : null;
};

const getDistricts = (region: REGION_RESPONSE_SCHEMA | undefined | null) => {
	if (!region) return { districts: [], id: '' };
	return { districts: region.districts, id: region.id };
};

const getSubDistricts = (district: DISTRICT_RESPONSE_SCHEMA | undefined | null) => {
	if (!district) return { subDistricts: [], id: '' };
	return { subDistricts: district.sub_districts, id: district.id };
};

const getCommunities = (subDistrict: SUB_DISTRICT_RESPONSE_SCHEMA | undefined | null) => {
	if (!subDistrict) return { communities: [], id: '' };
	return { communities: subDistrict.communities, id: subDistrict.id };
};

interface State {
	districts: DISTRICT_RESPONSE_SCHEMA[];
	subDistricts: SUB_DISTRICT_RESPONSE_SCHEMA[];
	communities: COMMUNITY_RESPONSE_SCHEMA[];
	regionId: string;
	districtId: string;
	subDistrictId: string;
	communityId: string;
	regions: REGION_RESPONSE_SCHEMA[];
}

type Action =
	| {
			type: 'INITIALIZE STATE';
			payload: {
				regions: REGION_RESPONSE_SCHEMA[];
				region: string;
				district: string;
				subDistrict: string;
				community: string;
			};
	  }
	| {
			type: 'SELECTED REGION';
			payload: string;
	  }
	| {
			type: 'SELECTED DISTRICT';
			payload: string;
	  }
	| {
			type: 'SELECTED SUB DISTRICT';
			payload: string;
	  }
	| {
			type: 'SELECTED COMMUNITY';
			payload: string;
	  };

const initialState: State = {
	regions: [],
	districts: [],
	subDistricts: [],
	communities: [],
	regionId: '',
	districtId: '',
	subDistrictId: '',
	communityId: ''
};

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'INITIALIZE STATE':
			const { regions, region, district, community, subDistrict } = action.payload;
			const { districts, id: regionId } = getDistricts(find(regions, region));
			const { subDistricts, id: districtId } = getSubDistricts(find(districts, district));
			const { communities, id: subDistrictId } = getCommunities(
				find(subDistricts, subDistrict)
			);
			const comm = find(communities, community);
			return {
				...state,
				regions,
				regionId,
				districts,
				districtId,
				subDistricts,
				subDistrictId,
				communities,
				communityId: (comm && comm.id) || ''
			};

		case 'SELECTED REGION':
			const reg = getDistricts(find(state.regions, action.payload));
			return {
				...state,
				...initialState,
				regions: state.regions,
				regionId: reg.id,
				districts: reg.districts
			};

		case 'SELECTED DISTRICT':
			const subDist = getSubDistricts(find(state.districts, action.payload));
			return {
				...state,
				districtId: subDist.id,
				subDistricts: subDist.subDistricts,
				communities: [],
				communityId: ''
			};

		case 'SELECTED SUB DISTRICT':
			const com = getCommunities(find(state.subDistricts, action.payload));
			return {
				...state,
				communities: com.communities,
				subDistrictId: com.id,
				communityId: ''
			};

		case 'SELECTED COMMUNITY':
			return { ...state, communityId: action.payload };

		default:
			return state;
	}
};

export const useLocationSelector = (options: Options) => {
	const regions = useSelector(({ regional }: AppStore) => regional.regions);
	const [state, dispatch] = useReducer(reducer, initialState);
	const { region, district, subDistrict, community } = options;

	useEffect(() => {
		if (!regions) return;
		dispatch({
			type: 'INITIALIZE STATE',
			payload: { region, district, subDistrict, community, regions }
		});
	}, [region, district, subDistrict, community, regions]);

	const onChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const value = data.value as string;
		switch (data.name) {
			case 'region':
				dispatch({ type: 'SELECTED REGION', payload: value });
				break;

			case 'district':
				dispatch({ type: 'SELECTED DISTRICT', payload: value });
				break;

			case 'subDistrict':
				dispatch({ type: 'SELECTED SUB DISTRICT', payload: value });
				break;

			case 'community':
				dispatch({ type: 'SELECTED COMMUNITY', payload: value });
				break;

			default:
				return;
		}
	};

	return { ...state, onChange };
};
