import React from 'react';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { getSampleLabTests } from '../_helpers/utilities';
import AdvancedTable, { ICellComponentProps } from '../_shared/components/AdvancedTable';
import Input from '../_shared/components/Input';
import Title from '../_shared/components/Title';
import { Section } from './styles';

interface IProps {
	request: SAMPLES_RESPONSE_SCHEMA;
}

export default function Investigations({ request }: IProps) {
	const items = getSampleLabTests(request.lab_tests, request.date_of_request);
	return (
		<Section style={{ pageBreakInside: 'avoid' }}>
			<Title
				content="INVESTIGATIONS"
				description="(to be filled-in by healthcare providers providing diagnostics services only)"
			/>
			<AdvancedTable
				columns={[
					{ text: 'Description', value: 'description', style: { width: 200 } },
					{ text: 'Unit Price', value: 'unitPrice', as: UnitPrice },
					{ text: 'Date', value: 'date', as: DateCreated },
					{ text: 'G-DRG', value: 'gdrg', as: Gdrg }
				]}
				items={items}
			/>
		</Section>
	);
}

function UnitPrice({ value }: ICellComponentProps) {
	return <Input length={6} value={value} />;
}

function DateCreated({ value }: ICellComponentProps) {
	return <Input length={10} placeholder="dd/mm/yyyy" value={value} />;
}

function Gdrg({ value }: ICellComponentProps) {
	return <Input length={7} value={value} />;
}
