import PropTypes from 'prop-types';
import React from 'react';
import { FormInput, Selector, Text } from '../../../resource/components';
import { convertToDatePickerFormat } from '../../../resource/services';

interface Props {
	name: string;
	surname: string;
	otherName?: string;
	telephone: string;
	username: string;
	password: string;
	dob: string;
	gender: string;
	pageNumber: number;
	activePage: number;
	handleChange: (event: React.ChangeEvent<any>) => void;
}

const LabAdminDetails: React.FC<Props> = props => {
	const { pageNumber, activePage } = props;
	return (
		<div style={{ display: pageNumber === activePage ? 'block' : 'none' }}>
			<FormInput
				type="text"
				name="admin.name"
				value={props.name}
				onChange={props.handleChange}
				placeholder="Enter admin first name"
				label="First name"
			/>
			<FormInput
				type="text"
				name="admin.surname"
				value={props.surname}
				onChange={props.handleChange}
				placeholder="Enter admin surname"
				label="Last name"
			/>
			<FormInput
				type="text"
				name="admin.otherName"
				value={props.otherName}
				onChange={props.handleChange}
				placeholder="Enter admin middle name"
				label="Other name"
			/>
			<FormInput
				type="date"
				name="admin.dob"
				value={convertToDatePickerFormat(props.dob)}
				label="Date of birth"
				onChange={props.handleChange}
			/>
			<div style={{ display: 'flex' }}>
				<Text style={{ marginRight: '20px' }}>Choose the gender of the administrator</Text>
				<Selector
					onChange={props.handleChange}
					label="Male"
					name="admin.gender"
					value={'M'}
					checked={props.gender === 'M'}
				/>
				<Selector
					onChange={props.handleChange}
					label="Female"
					name="admin.gender"
					value={'F'}
					checked={props.gender === 'F'}
				/>
			</div>
			<FormInput
				type="tel"
				name="admin.telephone"
				value={props.telephone}
				onChange={props.handleChange}
				placeholder="Enter admin telephone number e.g 0267469853"
				label="Phone number"
			/>
			<FormInput
				type="username"
				name="admin.username"
				value={props.username}
				onChange={props.handleChange}
				placeholder="Enter a username for the administrator"
				label="Username"
			/>
			<FormInput
				type="password"
				name="admin.password"
				value={props.password}
				onChange={props.handleChange}
				placeholder="Enter a password for the administrator"
				label="Password"
			/>
		</div>
	);
};

LabAdminDetails.propTypes = {
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
	otherName: PropTypes.string,
	telephone: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	dob: PropTypes.string.isRequired,
	gender: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default LabAdminDetails;
