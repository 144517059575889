import React from 'react';

export interface ICellComponentProps {
	value: any;
	index: number;
}

interface IColumn {
	style?: React.CSSProperties;
	text: string;
	value: string;
	as?: (args: ICellComponentProps) => JSX.Element | null;
}

interface IProps {
	columns: IColumn[];
	items: Record<string, any>[];
	isNumbered?: boolean;
}

export default function AdvancedTable({ columns, items, isNumbered = true }: IProps) {
	return (
		<table style={tableStyles}>
			<thead>
				<tr style={tableRowStyles}>
					{isNumbered && <th style={tableHeadCellStyles}></th>}
					{React.Children.toArray(
						columns.map((column) => (
							<th style={{ ...tableHeadCellStyles, ...(column.style || {}) }}>
								{column.text}
							</th>
						))
					)}
				</tr>
			</thead>
			<tbody>
				{React.Children.toArray(
					items.map((item, index) => (
						<tr style={tableRowStyles}>
							{isNumbered && <td style={tableCellStyles}>{index + 1}</td>}
							{React.Children.toArray(
								columns.map((column) => (
									<td style={tableCellStyles}>
										{column.as
											? column.as({ value: item[column.value], index })
											: item[column.value]}
									</td>
								))
							)}
						</tr>
					))
				)}
			</tbody>
		</table>
	);
}

const tableStyles: React.CSSProperties = {
	width: '100%',
	borderCollapse: 'collapse',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '#000',
	fontSize: '10pt'
};

const tableRowStyles: React.CSSProperties = {
	borderBottom: '1pt solid #000'
};

const tableCellStyles: React.CSSProperties = {
	padding: '5pt',
	borderRight: '1pt solid #000'
};

const tableHeadCellStyles: React.CSSProperties = {
	padding: '5pt',
	borderRight: '1pt solid #000'
};
