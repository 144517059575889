import React from 'react';
import PropTypes from 'prop-types';
import './css/tools.css';

export interface SelectorProps extends React.HTMLAttributes<HTMLInputElement> {
    [key: string]: any;
    label: string;
}

export const Selector = ({ label, ...props }: SelectorProps) => {
    return (
        <div className="selector">
            <input {...props} type="radio" />
            <label htmlFor={props.name}>{label}</label>
        </div>
    );
};

Selector.propTypes = {
    label: PropTypes.string
};

Selector.defaultProps = {
    label: null
};
