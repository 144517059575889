import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Alert } from '.';

type Props = {
	error: any;
	onExit: () => void;
	title: string;
};

type State = {
	message: string | null;
};

export class ErrorHandler extends PureComponent<Props, State> {
	public readonly state: State = {
		message: null
	};

	public static propTypes = {
		error: PropTypes.any,
		onExit: PropTypes.func.isRequired,
		title: PropTypes.string
	};

	public static defaultProps = {
		title: 'Error',
		onExit: () => {}
	};

	public componentDidCatch(error: Error, info: React.ErrorInfo) {
		this.setState(() => ({ message: error.message }));
	}

	public render() {
		const { message } = this.state;
		const { error } = this.props;
		return (
			<>
				<Alert
					onConfirmed={this.onClose}
					onClose={this.onClose}
					title="Error"
					error
					open={Boolean(!isEmpty(error) || message)}
					message={this.getErrorMessage(error || message)}
				/>
				{this.props.children}
			</>
		);
	}

	public onClose = (): void => {
		this.props.onExit();
	};

	public getErrorMessage = (error: any) => {
		if (!error) return '';
		if (typeof error === 'string') return error;
		if (error.error) return error.error.message;
		if (error.message) return error.message;
		return 'Something went wrong. Refresh the page and try again';
	};
}
