import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import InfiniteCalender from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './css/tools.css';

interface DatePickerProps {
	onSelect: (date: Date) => void;
	open: boolean;
	onClose: () => void;
}

interface IState {
	date: Date;
}

export class DatePicker extends PureComponent<DatePickerProps, IState> {
	public minYear = 1900;

	public readonly state: IState = {
		date: new Date()
	};

	public static propTypes = {
		onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
		open: PropTypes.bool
	};

	public static defaultProps = {
		onSelect: undefined,
		open: false
	};

	public render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				closeOnDimmerClick
				closeOnDocumentClick
				closeOnEscape
				basic
				className="date-picker"
			>
				<Modal.Content className="content">
					<Button icon basic size="large" className="action" onClick={this.onProceed}>
						<Icon name="check" />
					</Button>
					<InfiniteCalender
						width={500}
						height={400}
						displayOptions={{ layout: 'portrait' }}
						onSelect={this.onSelectDate}
						selected={this.state.date}
						min={new Date(this.minYear, 0, 1)}
						minDate={new Date(this.minYear, 0, 1)}
						theme={{
							floatingNav: {
								background: '#00808042',
								color: '#414141',
								chevron: 'transparent'
							},
							headerColor: 'teal',
							selectionColor: 'teal',
							weekdayColor: 'teal'
						}}
					/>
				</Modal.Content>
			</Modal>
		);
	}

	public onSelectDate = (date: Date) => {
		this.setState(() => ({ date }));
	};

	public onProceed = () => {
		const date = this.state.date;
		this.props.onSelect(date);
	};
}
