import { Dispatch as Action, MIDWIFE_RESPONSE_SCHEMA } from '../../services';

export const Midwife = {
	CLEAR_MIDWIFE_STATE: '@store/clear-midwife-state',
	ADD_MIDWIFE_SUCCESS: '@@api/post-midwife-success',
	ADD_MIDWIFE_FAIL: '@@api/post-midwife-fail',
	STORE_MIDWIVES: 'STORE_MIDWIVES'
};

export interface MidwifeState {
	midwife: MIDWIFE_RESPONSE_SCHEMA | null;
	error: any;
	midwives: Array<MIDWIFE_RESPONSE_SCHEMA> | null;
}

const defaultState: MidwifeState = {
	midwife: null,
	error: null,
	midwives: null
};

export default (state = defaultState, action: Action) => {
	switch (action.type) {
		case Midwife.ADD_MIDWIFE_SUCCESS:
			const newMidwife = action.payload;
			const newMidwives = (state.midwives || []).concat(newMidwife);
			return { ...state, midwife: newMidwife, midwives: newMidwives, error: null };

		case Midwife.ADD_MIDWIFE_FAIL:
			return { ...state, midwife: null, error: action.payload };

		case Midwife.CLEAR_MIDWIFE_STATE:
			return { ...state, ...defaultState };

		case Midwife.STORE_MIDWIVES:
			return { ...state, error: null, midwives: action.payload };

		default:
			return state;
	}
};
