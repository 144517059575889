import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Divider, Header, Icon } from 'semantic-ui-react';
import {
	Container,
	Image,
	MainContent,
	RestrictedAccess,
	Widget
} from '../../../resource/components';
import {
	calculateUserAge,
	convertToReadableDate,
	getFullName,
	getUserStatus
} from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import { getAddress, getWorkInfo } from '../_helpers';
import './css/profile.css';
import ProfileItem from './ProfileItem';
import ProfileList from './ProfileList';
import Toolbar from './Toolbar';

interface Props extends RouteComponentProps {}

const ProfileOverview = (props: Props) => {
	const user = useSelector(({ user }: AppStore) => user.user) as any;
	const name = getFullName(user as any);
	const onEditWorkProfile = () => {};

	const onEditUserProfile = () => {};

	return (
		<RestrictedAccess {...props} user={user}>
			<MainContent
				title="Profile"
				description="Manage your profile settings"
				toolbar={<Toolbar {...props} onEdit={onEditUserProfile} />}
			>
				<Container>
					<div className="profile layout">
						<Widget className="user">
							<Header className="header heading">Overview</Header>
							<Image text={name} className="img" />
							<ProfileList style={{ paddingTop: '2rem' }}>
								<ProfileItem name="Name" value={name} />
								<ProfileItem name="Status" value={getUserStatus(user as any)} />
								<ProfileItem
									name="Mobile"
									value={(user && user.user.telephone) || ''}
								/>
								<ProfileItem name="Address" value={getAddress(user)} />
							</ProfileList>
						</Widget>
						<Widget className="personal">
							<Header className="header heading">
								Personal
								<Header.Subheader>Personal infomation</Header.Subheader>
							</Header>
							<Divider className="content small top" />
							<ProfileList>
								<ProfileItem
									name="Age"
									value={(user && calculateUserAge(user.user.dob)) || ''}
								/>
								<ProfileItem
									name="Date of Birth"
									value={(user && convertToReadableDate(user.user.dob)) || ''}
								/>
								<ProfileItem
									name="Gender"
									value={
										(user && user.user.gender === 'M' ? 'Male' : 'Female') || ''
									}
								/>
							</ProfileList>
						</Widget>
						<Widget className="location">
							<Header className="header heading">
								Location
								<Header.Subheader>Location infomation</Header.Subheader>
							</Header>
							<Divider className="content small top" />
							<ProfileList>
								<ProfileItem
									name="Community"
									value={getAddress(user, 'community')}
								/>
								<ProfileItem
									name="Sub District"
									value={getAddress(user, 'sub district')}
								/>
								<ProfileItem name="District" value={getAddress(user, 'district')} />
								<ProfileItem name="Region" value={getAddress(user, 'region')} />
							</ProfileList>
						</Widget>
						<Widget className="work">
							<Header className="header heading">
								Work
								<Header.Subheader>Work place infomation</Header.Subheader>
								{user && user.privileges[0] !== 'PRIVILEGE_LAB_TECHNICIAN' && (
									<Icon
										name="pencil"
										onClick={onEditWorkProfile}
										className="edit"
										size="tiny"
										style={{ cursor: 'pointer' }}
									/>
								)}
							</Header>
							<Divider className="content small top" />
							<ProfileList>
								<ProfileItem name="Name" value={getWorkInfo(user)} />
								<ProfileItem name="Mobile" value={getWorkInfo(user, 'telephone')} />
								<ProfileItem name="Address" value={getAddress(user)} />
							</ProfileList>
						</Widget>
					</div>
				</Container>
			</MainContent>
		</RestrictedAccess>
	);
};

export default ProfileOverview;
