import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Pagination } from 'semantic-ui-react';
import { EmptyState, Widget } from '../../../resource/components';
import { ListProps } from '../../../resource/hocs';
import { usePagination } from '../../../resource/hooks';
import { LAB_RESPONSE_SCHEMA } from '../../../resource/services';
import { LabsListView } from './LabsListView';

interface Props extends ListProps<LAB_RESPONSE_SCHEMA> {}

export const LabsList = (props: Props) => {
	const { data, onSort, onSelect, sortedColumn, sortedDirection } = props;
	const {
		dataToRender,
		pageSize,
		dataSize,
		onPageChange,
		activePage,
		totalPages
	} = usePagination(data || []);

	if (isEmpty(data)) {
		return (
			<EmptyState
				title="No Registered Health Laboratories"
				description="There are no registered laboratories. Click on the add button to add a new health laboratory."
			/>
		);
	}
	return (
		<Widget>
			<LabsListView
				onSort={onSort}
				onSelect={onSelect}
				sortedColumn={sortedColumn}
				sortedDirection={sortedDirection}
				dataToRender={dataToRender}
				activePage={activePage}
				pageSize={pageSize}
			/>
			{dataSize > pageSize && (
				<div className="pagination">
					<Pagination
						totalPages={totalPages}
						activePage={activePage}
						onPageChange={onPageChange}
						firstItem={null}
						lastItem={null}
					/>
				</div>
			)}
		</Widget>
	);
};
