import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ToolbarButton } from '../../../resource/components';
import { wrappedToolbar } from '../../../resource/hocs';
import './css/toolbar.css';

type FilterKey = 'ALL' | 'OK' | 'PENDING' | 'BAD';

type Props = {
	isAssigned: boolean;
	onAssign: () => void;
	onFilter: (filterKey: FilterKey) => void;
	onSummaryReport: () => void;
	isDelete: boolean;
	onDeleteRequest: () => void;
	cancelDeleteRequest: () => void;
};

const Toolbar = (props: Props) => {
	const {
		isAssigned,
		onAssign,
		onFilter,
		onSummaryReport,
		isDelete,
		onDeleteRequest,
		cancelDeleteRequest
	} = props;

	return (
		<>
			{!isDelete && (
				<>
					<Dropdown className="requests toolbar" text="Filter">
						<Dropdown.Menu>
							<Dropdown.Item text="All Requests" onClick={() => onFilter('ALL')} />
							<Dropdown.Item
								text="Pending Requests"
								onClick={() => onFilter('PENDING')}
							/>
							<Dropdown.Item
								text="Successful Requests"
								onClick={() => onFilter('OK')}
							/>
							<Dropdown.Item text="Bad Requests" onClick={() => onFilter('BAD')} />
						</Dropdown.Menu>
					</Dropdown>
					<ToolbarButton
						iconName="motorcycle"
						content="Assign riders"
						disabled={!isAssigned}
						onClick={onAssign}
					/>
					<ToolbarButton
						iconName="file"
						content="Summary Report"
						onClick={onSummaryReport}
					/>
				</>
			)}
			<ToolbarButton
				iconName={isDelete ? 'check' : 'delete'}
				content="Delete Samples"
				onClick={onDeleteRequest}
				danger
			/>
			{isDelete && (
				<ToolbarButton iconName="delete" content="Cancel" onClick={cancelDeleteRequest} />
			)}
		</>
	);
};

Toolbar.propTypes = {
	isAssigned: PropTypes.bool.isRequired,
	onAssign: PropTypes.func.isRequired
};

Toolbar.defaultProps = {
	isAssigned: true
};

export default wrappedToolbar(Toolbar);
