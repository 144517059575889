import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';

export function getInputDate(args?: string | Date) {
	if (!args) return '';
	const date = new Date(args);
	return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
		.toString()
		.padStart(2, '0')}/${date.getFullYear()}`;
}

export function getDecimalInput(value: number, startLength = 3) {
	const [wholeNumber, fraction] = value.toFixed(2).split('.');
	return `${wholeNumber.padStart(startLength, ' ')}.${fraction}`;
}

export function getSampleLabTests(labTests: SAMPLES_RESPONSE_SCHEMA['lab_tests'], date: string) {
	return labTests.map((test) => ({
		description: test.lab_test.name,
		unitPrice: getDecimalInput(test.lab_test.NHIS_FEE),
		date: getInputDate(date),
		gdrg: test.lab_test.NHIS_CODE
	}));
}
