import PropTypes from 'prop-types';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownProps, Header, Icon, Select } from 'semantic-ui-react';
import {
	AdvancedForm,
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Text,
	TextField,
	ToolbarButton,
	Widget
} from '../../../resource/components';
import { getAllSampleTypes } from '../../../resource/dataService';
import { LAB_TEST_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore, clearDataForEdit, clearError } from '../../../resource/store';
import {
	clearLabTestState,
	Constants as K,
	LabTestFactory,
	storeLabTestInStore,
	submitLabTest
} from '../_helpers';
import './css/ltform.css';

interface ILabTestForm extends RouteComponentProps {
	test: LAB_TEST_RESPONSE_SCHEMA | null;
	submitLabTest: (data: any) => void;
	error: any;
	storeLabTestInStore: (data: LAB_TEST_RESPONSE_SCHEMA) => void;
	clearLabTestState: () => void;
	user: AppStore['user']['user'];
	clearDataForEdit: () => void;
	clearError: () => void;
	oldTest: any;
}

interface IState {
	results: Array<{ value: string; name: string }>;
	loading: boolean;
	[key: string]: any;
	showStatus: boolean;
	fields: Array<any>;
	sampleType: string;
}

class LabTestForm extends Component<ILabTestForm, IState> {
	public _isMounted = false;
	public readonly state: IState = {
		results: [],
		loading: false,
		showStatus: false,
		fields: [],
		sampleType: ''
	};

	public static propTypes: PropTypes.InferProps<ILabTestForm> = {
		error: PropTypes.any,
		submitLabTest: PropTypes.func.isRequired,
		storeLabTestInStore: PropTypes.func.isRequired,
		clearLabTestState: PropTypes.func.isRequired,
		clearDataForEdit: PropTypes.func.isRequired,
		clearError: PropTypes.func.isRequired,
		oldTest: PropTypes.object
	};

	public async componentDidMount() {
		this._isMounted = true;
		const types = await getAllSampleTypes();
		if (this._isMounted) {
			const test = LabTestFactory.getLabTest(this.props.oldTest);
			const fields = LabTestFactory.getSampleTypes(types);
			this.setState(() => ({
				fields,
				test,
				results: LabTestFactory.getLabTestResults(test),
				sampleType: LabTestFactory.getLabTestSampleType(types, test)
			}));
		}
	}

	componentWillReceiveProps(props: ILabTestForm) {
		if (props !== this.props && props.test) {
			// add the lab test to the list of tests
			this.setState(() => ({ loading: false, showStatus: Boolean(props.test) }));
			this.props.storeLabTestInStore(props.test);
		}
	}

	public render(): ReactNode {
		return (
			<ErrorHandler error={this.props.error} onExit={this.onErrorExit}>
				<RestrictedAccess {...this.props} user={this.props.user}>
					<MainContent
						title="Lab Test"
						description="Register a lab test perform by the laboratory"
						toolbar={this.renderToolbar()}
					>
						<Container>
							<Widget>
								<AdvancedForm
									fields={K.form.fields}
									name={K.form.name}
									title={K.form.title}
									description={K.form.description}
									onTextChange={this.onTextChange}
									onSelect={() => {}}
									onLevelSelect={() => {}}
									values={this.state}
								/>
								<Select
									options={this.state.fields}
									placeholder="Select a sample type or enter a new sample type"
									className="input"
									fluid
									onChange={this.onSelect}
									search
									selection
									allowAdditions
									additionLabel="Sample type: "
									onAddItem={this.onAddSample}
									value={this.state.sampleType}
								/>
								{this.renderPossibleResults()}
							</Widget>
						</Container>
						<Alert
							success
							open={this.state.showStatus}
							onConfirmed={this.onClose}
							title="Successful"
							message="The laboratory test has been created successfully."
						/>
					</MainContent>
				</RestrictedAccess>
			</ErrorHandler>
		);
	}

	public componentWillUnmount() {
		this._isMounted = false;
	}

	public renderPossibleResults = () => {
		return (
			<React.Fragment>
				<Header className="header" textAlign="center">
					Possible Results
				</Header>
				<Text>
					Click on the add icon to add more possible results for the lab test.
					<Button
						icon
						className="toolbar-btn btn"
						size="medium"
						onClick={this.onAddResult}
					>
						<Icon name="add" />
					</Button>
				</Text>
				{this.renderResultInput()}
			</React.Fragment>
		);
	};

	public renderResultInput = () => {
		const { results } = this.state;
		return results.map((result, _i: number) => (
			<div key={_i} className="result-wrapper">
				<TextField
					type="text"
					label="Enter a possible result for the test"
					value={result.value}
					onChange={this.onResultChange}
					name={result.name}
				/>
				<Button
					icon
					size="small"
					className="toolbar-btn btn toolbar-btn--close"
					onClick={() => this.onDeleteResult(result)}
				>
					<Icon name="close" />
				</Button>
			</div>
		));
	};

	public renderToolbar = () => {
		return (
			<div className="toolbar">
				<ToolbarButton
					loading={this.state.loading && Boolean(this.props.error)}
					iconName="cloud upload"
					content="Upload"
					onClick={this.onUpload}
					disabled={!Boolean(this.state.test)}
				/>
				<ToolbarButton danger iconName="close" content="Cancel" onClick={this.onClose} />
			</div>
		);
	};

	public onAddResult = (): void => {
		const input = { name: new Date().getTime().toString(), value: '' };
		this.setState((prevState: Readonly<IState>) => ({
			results: [...prevState.results, input]
		}));
	};

	public onResultChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		this.setState((prevState: Readonly<IState>) => ({
			results: prevState.results.map(val => {
				if (val.name === name) {
					val.value = value;
				}
				return val;
			})
		}));
	};

	public onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		event.persist();
		const target = event.target;
		this.setState((prevState: Readonly<IState>) => {
			const data = { ...prevState[target.id], [target.name]: target.value };
			return { [target.id]: data };
		});
	};

	public onSelect = (_: any, data: DropdownProps): void => {
		this.setState(() => ({
			sampleType: String(data.value)
		}));
	};

	public onDeleteResult = (input: any): void => {
		this.setState((prevState: Readonly<IState>) => ({
			results: prevState.results.filter(val => val !== input)
		}));
	};

	public onUpload = (): void => {
		if (this.state.test) {
			this.setState(() => ({ loading: true }));
			this.props.submitLabTest(this.state);
		}
	};

	public onErrorExit = (): void => {
		this.props.clearLabTestState();
		this.props.clearError();
	};

	public onClose = (): void => {
		this.setState({ showStatus: false });
		this.props.clearDataForEdit();
		this.props.history.push(K.app.routes.dashboard.LAB_TESTS);
	};

	public onAddSample = (_: any, { value }: DropdownProps) => {
		this.setState((state: Readonly<IState>) => ({
			fields: [
				{
					text: value,
					value: value
				},
				...state.fields
			]
		}));
	};
}

const mapStateToProps = (state: AppStore) => ({
	test: state.test.test,
	error: state.test.error,
	user: state.user.user,
	oldTest: state.misc.data
});

const mapDispatchToProps = {
	submitLabTest,
	storeLabTestInStore,
	clearLabTestState,
	clearError,
	clearDataForEdit
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LabTestForm);
