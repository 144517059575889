import { Constants as K } from '../../constants';
import { CLINIC_RESPONSE_SCHEMA, Dispatch } from '../../services';

export const Clinic = {
	CLEAR_CLINIC_DATA_TYPE: '@store/clear-clinic-state',
	STORE_CLINIC_DATA_TYPE: '@store/store-clinic',
	ADD_CLINIC_SUCCESS: '@@api/post-clinic-success',
	ADD_CLINIC_FAIL: '@@api/post-clinic-fail',
	STORE_CLINICS: 'STORE_CLINICS'
};

export interface ClinicState {
	clinic: CLINIC_RESPONSE_SCHEMA | null;
	error: any;
	clinics: Array<CLINIC_RESPONSE_SCHEMA> | null;
}

const defaultState: ClinicState = {
	clinic: null,
	error: null,
	clinics: null
};

export default (state: ClinicState = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Clinic.STORE_CLINIC_DATA_TYPE:
			const clinic = action.payload === null ? defaultState : action.payload;
			return { ...state, ...clinic };
		case Clinic.CLEAR_CLINIC_DATA_TYPE:
			return { ...state, ...defaultState };
		case K.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };
		case Clinic.ADD_CLINIC_SUCCESS:
			const newClinic = action.payload;
			const newClinics = (state.clinics || []).concat(newClinic);
			return { ...state, clinic: newClinic, clinics: newClinics, error: null };
		case Clinic.ADD_CLINIC_FAIL:
			return { ...state, clinic: null, error: action.payload };
		case Clinic.STORE_CLINICS:
			return { ...state, clinics: action.payload, error: null };
		default:
			return state;
	}
};
