import hasIn from 'lodash/hasIn';
import { Constants as K } from '../../../resource/constants';
import { DataStorage } from '../../../resource/dataService';
import {
	Dispatch,
	Dispatcher,
	getDateTimestamp,
	PRIVILEGE_SCHEMA,
	ServerRequest,
	USER_SIGN_SCHEMA
} from '../../../resource/services';
import { AppStore, Profile } from '../../../resource/store';

/**
 * the action creator clears the error field in the profile
 * state. the error occurs usually during processing a request
 */
export const clearProfileError = (): Dispatch => ({
	type: Profile.CLEAR_ERROR_TYPE
});

/**
 * the action creator pre processes the data passed to it then
 * submits the data to the server to update the user's profile
 * on success the user's data in the local storage is changed
 * @param data user
 */
export const updateUserProfile = (data: any) => async (dispatch: Dispatcher) => {
	try {
		const { first_name, middle_name, dob, gender, telephone, id, surname } = data;
		const { UPDATE_ADMIN, UPDATE_LAB_TECHNICIAN } = K.api.routes;
		const url = hasIn(data, 'clinic')
			? UPDATE_ADMIN
			: hasIn(data, 'lab_technician')
			? UPDATE_LAB_TECHNICIAN
			: hasIn(data, 'lab')
			? UPDATE_ADMIN
			: '';
		const updateData = {
			user: {
				first_name,
				middle_name,
				dob: getDateTimestamp(dob),
				gender,
				telephone,
				id,
				surname
			}
		};
		const res = await ServerRequest.uploadService(url, updateData, true, 'PUT');
		if (!res) {
			throw new Error('Failed to update profile');
		}
		const { technician, admin } = res;
		const profile = DataStorage.update(technician ? technician : admin);
		// dispatch the update success action with the profile payload
		dispatch({
			type: Profile.UPDATE_USER_SUCCESS,
			payload: profile
		});
		// dipatch the update data to the user state
		if (profile) {
			dispatch({
				type: K.store.types.STORE_USER_DATA_TYPE,
				payload: profile.user
			});
		}
	} catch (error) {
		dispatch({
			type: Profile.STORE_ERROR_TYPE,
			payload: error
		});
	}
};

/**
 * the action creator dispatches the action of clearing
 * the update state, freeing the state for other processing
 */
export const clearProfileUpdateState = (): Dispatch => ({
	type: Profile.CLEAR_UPDATE_TYPE
});

/**
 * the action creator store the data of the user's work place
 * information into the profile state. it formats the data
 * into the required format for each admin's use case such as
 * a lab admin will store { lab: {...} }
 * @param data user sign in details
 * @param privilege user access privilege
 */
export const storeWorkProfileInState = (
	data: AppStore['user']['user'],
	privilege: PRIVILEGE_SCHEMA
): Dispatch => {
	const { clinic, lab } = data as USER_SIGN_SCHEMA;
	const workProfileDetail = privilege === 'PRIVILEGE_CLINIC_ADMIN' ? { clinic } : { lab };
	return {
		type: Profile.STORE_USER_TYPE,
		payload: workProfileDetail
	};
};
