import React from 'react';
import { Button, ButtonProps, SemanticICONS, Popup, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './css/tools.css';

type Props = ButtonProps & {
	iconName: SemanticICONS;
	primary?: boolean;
	danger?: boolean;
	content: string;
	position?:
		| 'top left'
		| 'top right'
		| 'bottom right'
		| 'bottom left'
		| 'right center'
		| 'left center'
		| 'top center'
		| 'bottom center';
	tooltipStyle?: React.CSSProperties;
	showText?: boolean;
};

export const ToolbarButton = (props: Props) => {
	const {
		primary,
		danger,
		className,
		iconName,
		content,
		position,
		tooltipStyle,
		showText,
		...other
	} = props;
	let styling = `btn ${primary && 'primary'} ${danger && 'danger'} ${className || ''}`;
	if (showText) {
		return (
			<Button {...other} icon className={styling}>
				<Icon name={iconName} />
				{content}
			</Button>
		);
	}

	return (
		<Popup
			trigger={
				<Button {...other} icon className={styling}>
					<Icon name={iconName} />
				</Button>
			}
			content={content}
			position={position}
			hideOnScroll
			style={tooltipStyle}
			basic
		/>
	);
};

ToolbarButton.propTypes = {
	iconName: PropTypes.string.isRequired,
	primary: PropTypes.bool,
	danger: PropTypes.bool,
	className: PropTypes.string,
	content: PropTypes.string.isRequired
};

ToolbarButton.defaultProps = {
	primary: true,
	danger: false,
	className: '',
	tooltipStyle: {}
};

// const styles: { popup: React.CSSProperties } = {
// 	popup: {
// 		boxShadow: 'none',
// 		padding: '0.4rem 0.75rem',
// 		borderRadius: '0.2rem',
// 		fontFamily: Constants.app.theme.FONTS.font
// 	}
// };
