import { RouteProps } from 'react-router';
import { getValuesFromKey, ServerRequest } from '../../../resource/services';
import { Route as Routa, Router, routes } from '../routes';
import { Constants } from './constants';

export const accessRoutes = () => {
	const allRoutes: Array<RouteProps> = [];
	routes.forEach((route: Router) => {
		if (route.subComponents !== null && Array.isArray(route.subComponents)) {
			route.subComponents.forEach((routeComponent: Routa) => {
				allRoutes.push({
					exact: routeComponent.exact,
					path: routeComponent.path,
					component: routeComponent.component,
				});
			});
		} else {
			allRoutes.push({
				exact: route.exact,
				path: route.path,
				component: route.component,
			});
		}
	});
	return allRoutes;
};

export const fetchAccessRouteData = async (route: string) => {
	try {
		const [url, action] = Constants.apiRoutes[route];
		const data = await ServerRequest.get(url);
		return { data: getValuesFromKey(data), action };
	} catch (e) {}
};
