import { Constants as K } from '../../../resource/constants';
import {
	getDateTimestamp,
	convertToDatePickerFormat,
	LAB_TECHNICIAN_RESPONSE_SCHEMA
} from '../../../resource/services';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const formatFormState = (data: any) => {
	const format = { ...data.technician, dob: getDateTimestamp(data.technician.dob) };
	const method = data.technician.id ? 'PUT' : 'POST';
	const url = {
		POST: K.api.routes.ADD_LAB_TECHNICIAN,
		PUT: K.api.routes.UPDATE_LAB_TECHNICIAN
	};
	return { url: url[method], user: format, method, store: K.app.db.TECHNICIAN };
};

/**
 * the function formats the data that is stored in the misc
 * state in the app store to the form structure that the user
 * can make edit to the data
 * @param data technician
 */
export const formatDataToState = (data: any): any => {
	data.user.dob = convertToDatePickerFormat(data.user.dob);
	return { id: data.id, technician: data.user };
};

/**
 * the function deletes a technician from the list of technicians
 * from lab by returning the particular technician that is to
 * be deleted with the url and http method
 * @param data selected technician from list
 */
export const deleteLabTechnician = (data: any) => {
	const { id } = data as LAB_TECHNICIAN_RESPONSE_SCHEMA;
	return {
		url: K.api.routes.DELETE_LAB_TECHNICIAN,
		method: 'DELETE',
		technician_id: id,
		store: K.app.db.TECHNICIAN
	};
};
