import React, { CSSProperties } from 'react';

export function Section({
	children,
	style = {}
}: React.PropsWithChildren<{ style?: React.CSSProperties }>) {
	const styling: CSSProperties = { marginBottom: '10pt', pageBreakInside: 'avoid', ...style };
	return <section style={styling}>{children}</section>;
}

export function Box({ children, ...style }: React.PropsWithChildren<React.CSSProperties>) {
	return <div style={style}>{children}</div>;
}

export function Row({ children, ...rest }: React.PropsWithChildren<React.CSSProperties>) {
	const style = { display: 'flex', ...rest };
	return <div style={style}>{children}</div>;
}

export function Column({ children, ...rest }: React.PropsWithChildren<React.CSSProperties>) {
	const style = { flex: 1, ...rest };
	return <div style={style}>{children}</div>;
}

export function Field({ children, style = {} }: React.HTMLAttributes<HTMLDivElement>) {
	const styling = { paddingBottom: '5pt', ...style };
	return <div style={styling}>{children}</div>;
}

export function Text({ children, style, ...props }: React.HTMLAttributes<HTMLParagraphElement>) {
	const styling = { margin: 0, fontSize: '8pt', ...style };
	return <p style={styling}>{children}</p>;
}
