import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import RDLSLogo from '../assets/images/logo.jpeg';
import { getFullName } from '../services';
import './css/Navbar.css';

interface Props {
	pagesRoutes: { name: string; path: string }[];
	authenticatedUserRoute: string;
	authenticatedUser: any;
	activePageUrl: string;
}

const Navbar = (props: Props) => {
	const { pagesRoutes, authenticatedUserRoute, authenticatedUser, activePageUrl } = props;
	const isUserAuthenticated = !isEmpty(authenticatedUser);

	return (
		<nav className="navbar">
			<Container className="navbar-container">
				<div className="navbar-links-wrapper">
					<img src={RDLSLogo} alt="logo" />
					<div className="navbar-nav">
						{pagesRoutes.map((route, i) => (
							<Link
								key={i}
								to={route.path}
								className={(route.path === activePageUrl && 'active') || ''}
							>
								{route.name}
							</Link>
						))}
					</div>
				</div>
				<div className="navbar-user">
					<Link to={authenticatedUserRoute}>
						{isUserAuthenticated
							? `Welcome back, ${getFullName(authenticatedUser)}`
							: 'Login'}
					</Link>
				</div>
			</Container>
		</nav>
	);
};

Navbar.propTypes = {
	pagesRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
	authenticatedUserRoute: PropTypes.string.isRequired,
	authenticatedUser: PropTypes.object
};

export default Navbar;
