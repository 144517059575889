import React from 'react';
import PropTypes from 'prop-types';
import { ToolbarButton } from '../../../resource/components';

type Props = {
	name: string;
	onClick?: () => void;
	selected?: boolean;
	onEdit: () => void;
};

const LevelItem = (props: Props) => {
	const { onClick, name, selected, onEdit } = props;
	const className = `document-item ${selected ? 'selected' : ''}`.trim();
	return (
		<div className="document-item-wrapper">
			<div className={className} onClick={onClick} data-testid="name">
				{name}
			</div>
			<ToolbarButton iconName="edit" content="Edit" onClick={onEdit} />
		</div>
	);
};

LevelItem.propTypes = {
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
	onEdit: PropTypes.func.isRequired
};

LevelItem.defaultProps = {
	selected: false
};

export default LevelItem;
