import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon, SemanticICONS } from 'semantic-ui-react';
import { Widget, Text } from '.';
import './css/tools.css';

export interface IStats {
	className?: string;
	title: string;
	desc: string;
	icon: SemanticICONS;
	value: number | string;
	shrink?: boolean;
	inverted?: boolean;
}

export const Stats = ({ className, ...props }: IStats) => {
	const clName = typeof className !== 'undefined' ? className : '';
	const shrink = props.shrink ? 'shrink' : '';
	const inverted = props.inverted ? 'inverted' : '';
	return (
		<Fragment>
			<Widget className={`${clName} stats ${shrink} ${inverted}`}>
				<Header textAlign="right" className="header" content={props.title} />
				<div className="val-s">
					<Text className="val" text={props.value} />
					<Icon name={props.icon} />
				</div>
				<Text className="desc" text={props.desc} />
			</Widget>
		</Fragment>
	);
};

Stats.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	shrink: PropTypes.bool,
	inverted: PropTypes.bool
};

Stats.defaultProps = {
	classsName: '',
	shrink: false,
	inverted: false
};
