import { Constants as K } from '../../../resource/constants';
import {
	getDateTimestamp,
	COURIER_RESPONSE_SCHEMA,
	convertToDatePickerFormat
} from '../../../resource/services';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const createAndUpdateCourier = (data: any) => {
	const format = { ...data.courier, dob: getDateTimestamp(data.courier.dob) };
	const method = data.courier.id ? 'PUT' : 'POST';
	const url = {
		POST: K.api.routes.ADD_COURIER,
		PUT: K.api.routes.UPDATE_COURIER
	};
	if (method === 'PUT') {
		delete format.username;
		delete format.age;
		delete format.created_at;
	}
	return { url: url[method], user: format, method, store: K.app.db.COURIER };
};

/**
 * the function formats the data that is stored in the misc
 * state in the app store to the form structure that the user
 * can make edit to the data
 * @param data courier
 */
export const convertMiscStateToFormState = (data: any): any => {
	const courier = data as COURIER_RESPONSE_SCHEMA;
	courier.user.dob = convertToDatePickerFormat(courier.user.dob);
	return { id: courier.id, courier: courier.user };
};

/**
 * the function deletes a courier from the list of couriers
 * from lab by returning the particular courier that is to
 * be deleted
 * @param data selected courier from list
 */
export const deleteCourier = (data: any) => {
	const { id } = data as COURIER_RESPONSE_SCHEMA;
	return {
		url: K.api.routes.DELETE_COURIER,
		method: 'DELETE',
		rider_id: id,
		store: K.app.db.COURIER
	};
};
