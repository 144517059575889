import isEmpty from 'lodash/isEmpty';
import { USERS_RESPONSE_SCHEMA } from '../services';

export class Storage {
	private static AUTH_KEY = '@@auth';
	private static USER_KEY = '@@user';
	private static LAB_TESTS_KEY = '@@tests';

	/**
	 * getDataWithKey is a static method in the Storage class which
	 * retrieves a store data with the key pass as a param to the
	 * method
	 * @param key string
	 * @returns any
	 */
	public static getDataWithKey(key: string): any | null {
		const encoded = localStorage.getItem(key);
		return encoded ? JSON.parse(encoded) : null;
	}

	/**
	 * getAuthToken is a static method in the Storage class which
	 * retrieves the auth token generated by the server from storage
	 * to make other request to the server
	 * @returns string
	 */
	public static getAuthToken(): string {
		return Storage.getDataWithKey(Storage.AUTH_KEY);
	}

	/**
	 * storeAuthToken is a static method in the storage class which
	 * sets the auth token generated by the server during user verification
	 * and return a boolean value to indicate the status of the storage
	 * @param token string
	 */
	public static storeAuthToken(token: string): boolean {
		localStorage.setItem(Storage.AUTH_KEY, JSON.stringify(token));
		return Storage.getAuthToken() !== null;
	}

	/**
	 * storeUserData is a static method in the storage class which stores
	 * the user's data from the sign in response of the server into the
	 * localstorage of the application browser
	 * @param data any
	 * @returns boolean
	 */
	public static storeUserData(data: any): boolean {
		const details = Object.assign(data.user, {
			privileges: data.privileges
		});
		const tests = data.lab_test_options;
		Storage.storeData(Storage.USER_KEY, details);
		Storage.storeData(Storage.LAB_TESTS_KEY, tests);
		return Storage.getDataWithKey(Storage.USER_KEY) !== null;
	}

	/**
	 * getUserData retrieves the user data from local storage and returns
	 * the data in which is formatted as the data is gotten from the server
	 * @returns object | null
	 */
	public static getUserData(): { user: any; tests: any } {
		return {
			user: Storage.getDataWithKey(Storage.USER_KEY),
			tests: Storage.getDataWithKey(Storage.LAB_TESTS_KEY)
		};
	}

	/**
	 * storeData is a class method which stores the data in the localstorage
	 * TODO: data encrytion for all stored data in the local storage
	 * @param key string
	 * @param data any
	 */
	public static storeData(key: string, data: any): void {
		localStorage.setItem(key, JSON.stringify(data));
	}

	/**
	 * storeLabTests stores the retrieved lab tests from the server into
	 * the apps local storage
	 * @param data any
	 */
	public static storeLabTests(data: any): void {
		Storage.storeData(Storage.LAB_TESTS_KEY, data);
	}

	/**
	 * clear is a class method which clears the app storage
	 * and freeing the system for the next set of data when the user logs in
	 */
	public static clear(): void {
		localStorage.clear();
	}
}

interface User {
	username: string;
	password: string;
	user: USERS_RESPONSE_SCHEMA;
	authToken: string;
	timestamp: {
		signIn: number;
		signOut: null | number;
	};
}

interface Users {
	[id: string]: User;
}

interface Active {
	id: string;
	authToken: string;
}

export class DataStorage extends Storage {
	private static USERS_KEY = '@users$';
	private static ACTIVE_KEY = '@active$';
	private static users: Users | null = null;
	public static active: Active | null = null;
	public static user: any = {};

	/**
	 * init is a static method which initializes the users and the
	 * active properties in the DataStorage with the data in the local
	 * storage
	 * @returns void
	 */
	public static init(): void {
		DataStorage.users = DataStorage.getDataWithKey(DataStorage.USERS_KEY) || {};
		DataStorage.active = DataStorage.getDataWithKey(DataStorage.ACTIVE_KEY) || {};
		if (DataStorage.active && DataStorage.users) {
			if (Object.keys(DataStorage.active).length > 0) {
				DataStorage.user = DataStorage.users[DataStorage.active.id].user;
			}
		}
	}

	/**
	 * validate is a static method which is used to validate the user
	 * inputted credentials such as the username and password which
	 * then returns a boolean value when the user is found.
	 * it sets the active field with the validated user's id and authToken
	 * @param username string
	 * @param password string
	 * @returns boolean
	 */
	public static validate(username: string, password: string): boolean {
		if (DataStorage.users && Object.keys(DataStorage.users).length === 0) {
			return false;
		} else if (DataStorage.users) {
			const [id] = Object.keys(DataStorage.users).filter((id: string) => {
				if (DataStorage.users) {
					const user = DataStorage.users[id];
					return user.username === username && user.password === password;
				}
				return false;
			});
			// set the user data in the active field
			if (id) {
				const user: User = DataStorage.users[id];
				DataStorage.setActive(user, id);
				return true;
			}
			return false;
		}
		return false;
	}

	/**
	 * add is a static method which adds the new user to the users
	 * field and stores the user in the application local storage.
	 * it also sets the active field id and authToken with the user's
	 * id and auth_token
	 * @param user any
	 */
	public static add(user: any): boolean {
		const { username, password, auth_token, user: resUser, lab_test_options, ...data } = user;
		const users = DataStorage.users || {};
		Object.assign(users, {
			[user.user.id]: {
				username,
				password,
				user: { ...resUser, ...data },
				authToken: auth_token,
				timestamp: {
					signIn: new Date().getTime(),
					signOut: null
				}
			}
		});
		DataStorage.users = users;
		// store the users in app local storage
		DataStorage.storeData(DataStorage.USERS_KEY, DataStorage.users);
		// set the user to active
		DataStorage.setActive(DataStorage.users[user.user.id], user.user.id);
		return true;
	}

	/**
	 * setActive is a private static method in the DataStorage class
	 * which sets the active field with the id and authToken with the
	 * user's id and authToken
	 * @param user any
	 */
	private static setActive(user: User, id: string): void {
		DataStorage.active = {
			id,
			authToken: user.authToken
		};
		DataStorage.storeData(DataStorage.ACTIVE_KEY, DataStorage.active);
		// set the sign in date of the user
		if (DataStorage.users) {
			DataStorage.users[id].timestamp.signIn = new Date().getTime();
			DataStorage.storeData(DataStorage.USERS_KEY, DataStorage.users);
			DataStorage.user = user.user;
		}
	}

	/**
	 * getActive is a static method which returns the data in the active
	 * field which is the user's id and authToken
	 * @returns Active | null
	 */
	public static getActive(): Active | null {
		return DataStorage.active;
	}

	/**
	 * clearActive is a static method which clears the active field of the
	 * stored id and authToken it returns a boolean value for the status
	 * of the active field
	 * @returns boolean
	 */
	public static clearActive(): boolean {
		if (DataStorage.active && DataStorage.users) {
			// set the sign out date of the user
			DataStorage.users[DataStorage.active.id].timestamp.signOut = new Date().getTime();
			DataStorage.storeData(DataStorage.USERS_KEY, DataStorage.users);
			localStorage.removeItem(DataStorage.ACTIVE_KEY);
			localStorage.removeItem(DataStorage.USERS_KEY);
			DataStorage.users = null;
			DataStorage.active = null;
			return true;
		}
		return false;
	}

	public static cacheReset() {
		const keySets: { [key: string]: string } = {
			PRIVILEGE_LAB_ADMIN: 'lab',
			PRIVILEGE_CLINIC_ADMIN: 'clinic',
			PRIVILEGE_LAB_TECHNICIAN: 'lab_technician'
		};
		const users = DataStorage.users;
		if (users) {
			const isNew = Object.keys(users).every(id => {
				const {
					user: { privileges }
				} = users[id];
				const privilege = (privileges && privileges[0]) || '';
				if (privilege === 'PRIVILEGE_SUPER_ADMIN') return true;
				return Boolean((users[id].user as any)[keySets[privilege]]);
			});
			if (!isNew) {
				DataStorage.clearActive();
			}
		}
	}

	public static update(data: { user: USERS_RESPONSE_SCHEMA }) {
		if (isEmpty(DataStorage.users)) {
			return null;
		}
		const users = DataStorage.users as Users;
		users[data.user.id] = {
			...users[data.user.id],
			user: { ...users[data.user.id].user, ...data.user }
		};
		DataStorage.storeData(DataStorage.USERS_KEY, users);
		DataStorage.setActive(users[data.user.id], data.user.id);
		return users[data.user.id];
	}
}
