import { Constants as K } from '../../../resource/constants';
import { getDateTimestamp, LAB_RESPONSE_SCHEMA } from '../../../resource/services';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const createAndUpdateLaboratory = (data: any) => {
	const method = data.id ? 'PUT' : 'POST';
	const format = {
		...data.lab,
		...data.level.lab,
		'lab-admin': {
			user: {
				...data['lab-admin'],
				dob: getDateTimestamp(data['lab-admin'].dob)
			}
		}
	};
	const upload = {
		POST: {
			data: format,
			url: K.api.routes.POST_LAB
		},
		PUT: {
			data: {
				id: data.id,
				name: format.name,
				telephone: format.telephone,
				community_id: format.community_id
			},
			url: K.api.routes.UPDATE_LAB
		}
	};
	return { url: upload[method].url, method, ...upload[method].data, store: K.app.db.LAB };
};

/**
 * the function formats the data that is stored in the misc
 * state in the app store to the form structure that the user
 * can make edit to the data
 * @param data laboratory
 */
export const mapMiscStateToFormState = (data: any) => {
	const laboratory = data as LAB_RESPONSE_SCHEMA;
	return { id: laboratory.id, 'lab-admin': laboratory.admin.user, lab: laboratory };
};

/**
 * the function maps the laboratories to a bar chart data format
 * with the each field containing the name of the region and total
 * number of labs in the region
 * @param laboratories laboratories
 */
export const mapLabsToBarChartFormat = (laboratories: LAB_RESPONSE_SCHEMA[]) => {
	type TChart = { name: string; labs: number };
	return laboratories.reduce(
		(acc, lab) => {
			let accumulatedData = [...acc];
			const index = accumulatedData.findIndex(data => data.name === lab.region);
			if (index !== -1) {
				accumulatedData[index] = {
					name: lab.region,
					labs: accumulatedData[index].labs + 1
				};
			} else {
				accumulatedData = [...accumulatedData, { name: lab.region, labs: 1 }];
			}
			return accumulatedData;
		},
		[] as TChart[]
	);
};

export const deleteLaboratory = (data: any) => {
	const { id } = data;
	return {
		url: K.api.routes.DELETE_LAB + `/${id}`,
		method: 'DELETE',
		store: K.app.db.LAB
	};
};
