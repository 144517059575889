import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './features/App';
import { subscribeToNotifications } from './resource/services';
import { persistor, store } from './resource/store';
import { AppContext } from './resource/store/contexts/AppContext';

const AppRegistry = () => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		subscribeToNotifications();
	}, []);

	const onLoading = (isLoading: boolean) => setLoading(isLoading);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AppContext.Provider value={{ loading, onLoading }}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</AppContext.Provider>
			</PersistGate>
		</Provider>
	);
};
export default AppRegistry;
