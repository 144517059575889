import { Dispatcher, Dispatch, ServerRequest } from '../../../resource/services';
import { Constants as K } from '../../../resource/constants';
import { updateDataStore } from '../../../resource/dataService';
import { Clinic } from '../../../resource/store';

export const clearClinicState = (): Dispatch => ({
	type: Clinic.CLEAR_CLINIC_DATA_TYPE
});

export const submitClinicForm = (data: any) => async (dispatch: Dispatcher) => {
	try {
		const method = data.id ? 'PUT' : 'POST';
		const upload = {
			POST: {
				url: K.api.routes.POST_CLINIC,
				data: data
			},
			PUT: {
				url: K.api.routes.UPDATE_CLINIC,
				data: {
					id: data.id,
					name: data.name,
					clinic_code: data.clinic_code,
					telephone: data.telephone,
					community_id: data.community_id
				}
			}
		};
		const clinic = await ServerRequest.uploadService(
			upload[method].url,
			upload[method].data,
			true,
			method
		);
		// connect to database
		updateDataStore(method, K.app.db.CLINIC, clinic);
		dispatch({
			type: Clinic.ADD_CLINIC_SUCCESS,
			payload: clinic
		});
	} catch (error) {
		dispatch({
			type: Clinic.ADD_CLINIC_FAIL,
			payload: error
		});
	}
};
