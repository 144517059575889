import { useCallback, useReducer } from 'react';

const initialState = {
	isLoading: false,
	active: false,
	showAlert: false,
	success: false,
	direction: 'ascending',
	sortedColumn: 'name',
	selectedData: null
};

const ActionTypes = {
	SET_SUCCESS: 'SET_SUCCESS',
	SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
	ON_CLOSE: 'ON_CLOSE',
	SHOW_ALERT: 'SHOW_ALERT',
	CLOSE_ALERT: 'CLOSE_ALERT'
};

const reducer = (state: typeof initialState, action: any): typeof initialState => {
	switch (action.type) {
		case ActionTypes.SET_SUCCESS:
			return { ...state, success: true };

		case ActionTypes.SET_SELECTED_ITEM:
			const data = action.payload;
			return { ...state, selectedData: data, active: true };

		case ActionTypes.ON_CLOSE:
			return {
				...state,
				active: false,
				selectedData: null,
				isLoading: false,
				showAlert: false,
				success: false
			};

		case ActionTypes.SHOW_ALERT:
			return { ...state, showAlert: true };

		case ActionTypes.CLOSE_ALERT:
			return { ...state, showAlert: false };

		default:
			return state;
	}
};

const useListView = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const onSuccess = useCallback(() => dispatch({ type: ActionTypes.SET_SUCCESS }), []);

	const onSelectItem = useCallback(
		(data: any) => dispatch({ type: ActionTypes.SET_SELECTED_ITEM, payload: data }),
		[]
	);

	const onClose = useCallback(() => dispatch({ type: ActionTypes.ON_CLOSE }), []);

	const onAlert = useCallback(() => dispatch({ type: ActionTypes.SHOW_ALERT }), []);

	return { ...state, onSuccess, onSelectItem, onClose, onAlert };
};

export default useListView;
