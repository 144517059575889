import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { deleteLaboratory } from '../_helpers';
import { LabDetail } from './LabDetail';
import { LabsList } from './LabsList';
import ToolBar from './Toolbar';

const options: WrappedListViewOptions = {
	title: 'Health Laboratories',
	form: Constants.app.routes.dashboard.ADD_LAB,
	description: 'Manage the registered laboratories in the RDLS',
	storeKey: 'lab',
	stateKey: 'labs'
};

export default wrappedAdvancedListView(
	LabsList as any,
	LabDetail as any,
	ToolBar as any,
	options,
	deleteLaboratory
);
