import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
	Alert,
	Container,
	EmptyState,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Widget
} from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import {
	convertListToDropdownItems,
	getFullName,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';
import { AppStore, Sample } from '../../../resource/store';
import StatusForm from './StatusForm';
import Toolbar from './Toolbar';
import useStatusForm from './useStatusForm';
import useStatusSubmit from './useStatusSubmit';

type Props = RouteComponentProps & {};

const SampleStatus = (props: Props) => {
	const { loading, show, sendStatus, setShow } = useStatusSubmit();
	const user = useSelector(({ user }: AppStore) => user.user);
	const sample = useSelector(({ sample }: AppStore) => sample);
	const dispatch = useDispatch();
	const { error, selectedRequest } = sample;
	const { riders, status, riderId, onChange } = useStatusForm({
		status: 'OK',
		riderId:
			(selectedRequest &&
				selectedRequest.midwife_confirmation &&
				selectedRequest.midwife_confirmation.rider_id) ||
			''
	});

	useEffect(
		() => () => {
			dispatch({ type: Sample.CLEAR_ERROR_STATE });
		},
		[dispatch]
	);

	const renderContent = (request: SAMPLES_RESPONSE_SCHEMA) => {
		return (
			<Widget className="sample-status">
				<StatusForm
					riders={convertListToDropdownItems(riders, rider => getFullName(rider))}
					loading={loading}
					onSelectRider={onChange}
					onStatusSelect={onChange}
					onSubmit={() => {
						sendStatus({ status, rider_id: riderId, sample_id: request.id });
					}}
					status={status}
					disabled={!Boolean(riderId)}
					riderId={riderId}
				/>
			</Widget>
		);
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<ErrorHandler
				error={error}
				onExit={() => {
					dispatch({ type: Sample.CLEAR_ERROR_STATE });
				}}
			>
				<MainContent
					title="Sample Status"
					description="Verify the state of the sample collected"
					toolbar={<Toolbar />}
				>
					<Container>
						{isEmpty(selectedRequest) ? (
							<EmptyState
								title="No Sample"
								description="There is no sample to assess its status."
							/>
						) : (selectedRequest as SAMPLES_RESPONSE_SCHEMA).status !== 'PENDING' ? (
							<EmptyState
								title="Accessed"
								description="The sample has already been assessed."
							/>
						) : (
							renderContent(selectedRequest as SAMPLES_RESPONSE_SCHEMA)
						)}
						<Alert
							open={show}
							onConfirmed={() => {
								dispatch({ type: Sample.CLEAR_ERROR_STATE });
								setShow(false);
								props.history.replace(
									Constants.app.routes.dashboard.SAMPLE_REQUESTS
								);
							}}
							title="Verified"
							message="Sample status has been verified successfully"
						/>
					</Container>
				</MainContent>
			</ErrorHandler>
		</RestrictedAccess>
	);
};

export default SampleStatus;
