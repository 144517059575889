import { SemanticICONS } from 'semantic-ui-react';
import { Constants as K } from '../../resource/constants';
import { ClinicForm, Clinics } from '../Clinics';
import { CourierForm, Couriers } from '../Couriers';
import { LaboratoryForm, Labs } from '../Laboratory';
import { LabTestForm, LabTests } from '../LabTest';
import { MidwifeForm, Midwifes } from '../Midwife';
import { SchemeForm, Schemes } from '../NHIS';
import Overview from '../Overview';
import { Profile, ProfileForm, WorkProfile } from '../Profile';
import { LevelForm, RegionDetail, Regions } from '../Regional';
import {
	CourierAssignment,
	LabResult,
	NHISClaimForm,
	Requests,
	SampleStatus,
	SummaryReport
} from '../Request';
import { TechnicianForm, Technicians } from '../Technician';

const {
	app: {
		routes: { dashboard: route }
	}
} = K;

export interface Route {
	name: string;
	icon: SemanticICONS;
	exact: boolean;
	path: string;
	component?: any;
	show?: boolean;
	[key: string]: any;
	heading?: string;
	description?: string;
}

export interface Router extends Route {
	subComponents?: Array<Route>;
}

export const routes: Array<Router> = [
	{
		exact: true,
		path: route.DASHBOARD,
		name: 'Overview',
		icon: 'home',
		component: null,
		show: false,
		subComponents: [
			{
				exact: true,
				path: route.DASHBOARD,
				component: Overview,
				name: 'Overview',
				icon: 'home',
				show: false
			}
		]
	},
	{
		exact: true,
		path: route.LABS,
		component: null,
		name: 'Labs',
		icon: 'hospital',
		heading: 'Manage or setup laboratories',
		description: 'Setup and manage laboratories.',
		subComponents: [
			{
				exact: true,
				path: route.LABS,
				component: Labs,
				name: 'Laboratories',
				icon: 'lab'
			},
			{
				exact: false,
				path: route.ADD_LAB,
				component: LaboratoryForm,
				name: 'Add Lab',
				icon: 'plus'
			}
		]
	},
	{
		exact: true,
		path: route.CLINICS,
		component: null,
		icon: 'hospital symbol',
		name: 'clinics',
		heading: 'Setup clinics',
		description: 'Setup and manage clinics.',
		subComponents: [
			{
				exact: true,
				path: route.CLINICS,
				component: Clinics,
				name: 'Clinics',
				icon: 'hospital'
			},
			{
				exact: false,
				path: route.ADD_CLINIC,
				component: ClinicForm,
				name: 'Add Clinic',
				icon: 'plus'
			}
		]
	},
	{
		exact: true,
		path: route.NHIS_VIEW,
		component: null,
		icon: 'credit card',
		name: 'NHIS Scheme',
		heading: 'Manage insurance schemes',
		description: 'Setup the insurance schemes that the health sector of Ghana uses.',
		subComponents: [
			{
				exact: true,
				path: route.NHIS_VIEW,
				component: Schemes,
				icon: 'id card outline',
				name: 'NHIS Scheme'
			},
			{
				exact: false,
				path: route.NHIS_ADD,
				component: SchemeForm,
				icon: 'plus',
				name: 'Add NHIS Scheme'
			},
			{
				exact: true,
				path: route.NHIS_EDIT,
				component: SchemeForm,
				icon: 'id card outline',
				name: 'Edit NHIS Scheme',
				show: false
			}
		]
	},
	{
		exact: true,
		path: route.LAB_TESTS,
		component: null,
		icon: 'lab',
		name: 'Laboratory Test',
		heading: 'Manage lab test at laboratory',
		description: 'Setup and manage lab tests at laboratory',
		subComponents: [
			{
				exact: true,
				path: route.LAB_TESTS,
				component: LabTests,
				icon: 'lab',
				name: 'Laboratory Test'
			},
			{
				exact: true,
				path: route.LAB_TEST_ADD,
				component: LabTestForm,
				icon: 'plus',
				name: 'Add Laboratory Test'
			}
		]
	},
	{
		exact: true,
		path: route.SAMPLE_REQUESTS,
		component: null,
		icon: 'envelope open',
		name: 'Pending Sample Requests',
		heading: 'Lab requests from clients',
		description: 'Manage lab requests by clients and submit lab results',
		subComponents: [
			{
				exact: true,
				path: route.SAMPLE_REQUESTS,
				component: Requests,
				icon: 'envelope open',
				name: 'Sample Requests'
			},
			{
				exact: false,
				path: route.SAMPLE_RESULT,
				component: LabResult,
				icon: 'eye',
				name: 'Request',
				show: false
			},
			{
				exact: false,
				path: route.SAMPLE_REPORT,
				component: NHISClaimForm,
				icon: 'file',
				name: 'NHIS Claim Form',
				show: false
			},
			{
				exact: false,
				path: route.SAMPLE_COURIER_ASSIGN,
				component: CourierAssignment,
				icon: 'motorcycle',
				name: 'Courier Assignment',
				show: false
			},
			{
				exact: false,
				path: route.SAMPLE_STATUS,
				component: SampleStatus,
				icon: 'check',
				name: 'Sample Status',
				show: false
			},
			{
				exact: false,
				path: route.SUMMARY_REPORT,
				component: SummaryReport,
				icon: 'file',
				name: 'Summary Report',
				show: false
			}
		]
	},
	{
		exact: true,
		path: route.TECHNICIANS,
		component: null,
		icon: 'user md',
		name: 'Lab Technicians',
		heading: 'Manage technicians',
		description: 'Manage the laboratory technicians',
		subComponents: [
			{
				exact: true,
				path: route.TECHNICIANS,
				component: Technicians,
				icon: 'users',
				name: 'Lab Technicians'
			},
			{
				exact: false,
				path: route.ADD_LAB_TECHNICIAN,
				component: TechnicianForm,
				icon: 'user plus',
				name: 'Register Technician'
			},
			{
				exact: true,
				path: route.EDIT_TECHNICIAN,
				component: TechnicianForm,
				icon: 'user',
				name: 'Edit Lab Technician',
				show: false
			}
		]
	},
	{
		exact: true,
		path: route.MIDWIFES,
		component: null,
		icon: 'user md',
		name: 'Midwives',
		heading: 'Manage midwifes at clinic',
		description: 'Manage health workers at the clinic',
		subComponents: [
			{
				exact: true,
				path: route.MIDWIFES,
				component: Midwifes,
				icon: 'users',
				name: 'Midwives'
			},
			{
				exact: false,
				path: route.ADD_MIDWIFE,
				component: MidwifeForm,
				icon: 'user plus',
				name: 'Register Midwife'
			}
		]
	},
	{
		exact: true,
		path: route.COURIERS,
		component: null,
		icon: 'motorcycle',
		name: 'Couriers',
		heading: 'Manage lab couriers',
		description: 'Manage the riders or couriers at the lab',
		subComponents: [
			{
				exact: true,
				path: route.COURIERS,
				component: Couriers,
				icon: 'users',
				name: 'Couriers'
			},
			{
				exact: false,
				path: route.ADD_COURIER,
				component: CourierForm,
				icon: 'user plus',
				name: 'Register Courier'
			}
		]
	},
	{
		exact: true,
		path: route.REGIONS,
		component: null,
		icon: 'map',
		name: 'Regions',
		heading: 'Manage or setup levels',
		description: 'Manage the regions or levels',
		subComponents: [
			{
				exact: true,
				path: route.REGIONS,
				component: Regions,
				icon: 'map',
				name: 'Regions'
			},
			{
				exact: true,
				path: route.ADD_REGION,
				component: LevelForm,
				icon: 'map',
				name: 'Add Region'
			},
			{
				exact: true,
				path: route.VIEW_REGION,
				component: RegionDetail,
				icon: 'map',
				name: 'Region'
			}
		]
	},
	{
		exact: true,
		path: route.USER_PROFILE,
		component: null,
		icon: 'user',
		name: 'Profile',
		heading: 'Manage your account',
		description: 'Manage your account settings and details',
		subComponents: [
			{
				exact: true,
				path: route.USER_PROFILE,
				component: Profile,
				icon: 'user',
				name: 'Profile'
			},
			{
				exact: true,
				path: route.USER_PROFILE_EDIT,
				component: ProfileForm,
				icon: 'user',
				name: 'Edit Profile'
			},
			{
				exact: false,
				path: route.WORK_PROFILE_EDIT,
				component: WorkProfile,
				icon: 'industry',
				name: 'Work Profile'
			}
		]
	}
];
