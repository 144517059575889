import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../constants';
import { AppStore } from '../store';

const useAuthenticatedUser = () => {
	const [authenticatedUserRoute, setUserRoute] = useState(Constants.app.routes.SIGNIN);
	const [authenticatedStatus, setUserStatus] = useState('Login');
	const user = useSelector((state: AppStore) => state.user.user);

	useEffect(() => {
		if (!isEmpty(user)) {
			setUserStatus('Go To Dashboard');
			setUserRoute(Constants.app.routes.dashboard.DASHBOARD);
		}
	}, [user]);

	return { authenticatedUserRoute, authenticatedStatus, user };
};

export default useAuthenticatedUser;
