import isEmpty from 'lodash/isEmpty';
import { Constants as K } from '../../constants';
import { DataStorage } from '../../dataService';
import { Access, Dispatch as Action, Dispatch, Dispatcher, ServerRequest } from '../../services';
import { User } from '../reducers';

export const storeDataInAppStore = <T>(data: T, key: string): Action => ({
	type: K.store.types.DUMP_DATA_TYPE,
	payload: { [key]: data }
});

export const clearMiscState = () => {
	return {
		type: K.store.types.CLEAR_MISC_DATA_TYPE
	};
};

export const clearAppStore = () => (dispatch: Dispatcher) => {
	dispatch({ type: K.store.types.CLEAR_USER_CACHE });
};

export const getUserDataToStore = () => (dispatch: Dispatcher) => {
	let data;
	if (!isEmpty(DataStorage.user)) {
		// load the user data in to the app store
		data = DataStorage.user;
		Access.subscribe(data);
	}
	dispatch({
		type: User.STORE_USER_TYPE,
		payload: data || null
	});
};

export const uploadToServer = (data: any) => async (dispatch: Dispatcher) => {
	try {
		const { url, method, store, ...props } = data;
		const res = await ServerRequest.uploadService(url, props, true, method);
		dispatch({
			type: K.store.types.SERVER_UPLOAD_SUCCESS,
			payload: res
		});
	} catch (e) {
		dispatch({
			type: K.store.types.SERVER_UPLOAD_FAILED,
			payload: e
		});
	}
};

export const clearError = (): Dispatch => ({
	type: K.store.types.CLEAR_MISC_ERROR
});

export const storeDataForEdit = (data: any): Dispatch => ({
	type: K.store.types.STORE_EDIT_DATA_TYPE,
	payload: data
});

export const clearDataForEdit = (): Dispatch => ({
	type: K.store.types.CLEAR_EDIT_DATA
});
