import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLabCouriers } from '../../../resource/dataService';
import {
	CLINIC_RESPONSE_SCHEMA,
	COURIER_RESPONSE_SCHEMA,
	postRiderAssignment,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';
import { AppStore, Sample } from '../../../resource/store';

type ClinicRequest = {
	clinic: CLINIC_RESPONSE_SCHEMA;
	requests: string[];
};

type Requests = { [id: string]: ClinicRequest };

type AssignedRequests = { sample: SAMPLES_RESPONSE_SCHEMA }[];

type Assign = { sample_id: string; rider_id: string };

const useAssignFetch = () => {
	const [riders, setRiders] = useState<COURIER_RESPONSE_SCHEMA[]>([]);
	const [clinicRequests, setClinicRequests] = useState<Requests>({});
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const requests = useSelector(({ sample }: AppStore) => sample.requests);
	const dispatch = useDispatch();
	const mount = useRef(false);

	useEffect(() => {
		mount.current = true;
		const fetchRidersIntoState = async () => {
			const fetchedRiders = await getLabCouriers();
			if (mount.current) setRiders(fetchedRiders || []);
		};
		fetchRidersIntoState();
		return () => {
			mount.current = false;
		};
	}, []);

	useEffect(
		() => () => {
			setShow(false);
		},
		[]
	);

	useEffect(() => {
		const clinicRequests = requests.reduce(
			(clinicRequest, request) => {
				const { clinic, rider } = request;
				if (!clinicRequest[clinic.id]) {
					clinicRequest[clinic.id] = { requests: [], clinic };
				}
				if (!rider) {
					clinicRequest[clinic.id].requests.push(request.id);
				}
				return clinicRequest;
			},
			{} as Requests
		);
		if (mount.current) {
			setClinicRequests(clinicRequests);
		}
	}, [requests]);

	const sendAssignments = async (data: Assign[], clinicRequests: Requests) => {
		setLoading(true);
		try {
			const res = (await postRiderAssignment(data)) as AssignedRequests;
			dispatch({
				type: Sample.ASSIGN_RIDER_TO_SAMPLE_SUCCESS,
				payload: res.map(request => {
					const { sample } = request;
					const clinicId = Object.keys(clinicRequests).find(id =>
						clinicRequests[id].requests.includes(sample.id)
					);
					return clinicId
						? { ...sample, clinic: clinicRequests[clinicId].clinic }
						: sample;
				})
			});
			if (mount.current) {
				setShow(true);
			}
		} catch (error) {
			dispatch({
				type: Sample.ASSIGN_RIDER_TO_SAMPLE_FAIL,
				payload: error
			});
		} finally {
			if (mount.current) {
				setLoading(false);
			}
		}
	};

	return { riders, clinicRequests, loading, show, sendAssignments, setShow };
};

export default useAssignFetch;
