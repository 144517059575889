import { Dispatch } from '../../../resource/services';
import { Lab } from '../../../resource/store';

export interface Lab {
	name: string;
	telephone: string;
	community: string;
	district: string;
	region: string;
	sub_district: string;
}

export interface LabAdmin {
	dob: string;
	name: string;
	otherName: string;
	gender: string;
	password: string;
	surname: string;
	telephone: string;
	username: string;
}

export type Action =
	| {
			type: 'SET LABORATORY DETAILS';
			payload: { lab: Lab; admin: LabAdmin };
	  }
	| {
			type: 'RESET LABORATORY DETAILS';
	  }
	| {
			type: 'CHANGE ACTIVE PAGE';
			payload: number;
	  }
	| {
			type: 'SET FORM SUBMISSION STATUS';
			payload: boolean;
	  }
	| {
			type: 'SET SELECTED COMMUNITY ID';
			payload: string;
	  }
	| {
			type: 'SET LAB FORM ERROR';
			payload: Error;
	  }
	| {
			type: 'REMOVE LAB FORM ERROR';
	  };

interface State {
	lab: Lab;
	admin: LabAdmin;
	activePage: number;
	status: boolean;
	communityId: string;
	error: Error | null;
}

export const labFormInitialState: State = {
	lab: {
		name: '',
		telephone: '',
		community: '',
		district: '',
		region: '',
		sub_district: ''
	},
	admin: {
		dob: '',
		name: '',
		otherName: '',
		gender: '',
		password: '',
		surname: '',
		telephone: '',
		username: ''
	},
	activePage: 1,
	status: false,
	communityId: '',
	error: null
};

export const labFormReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SET LABORATORY DETAILS':
			return { ...state, lab: action.payload.lab, admin: action.payload.admin };

		case 'RESET LABORATORY DETAILS':
			return { ...state, ...labFormInitialState };

		case 'CHANGE ACTIVE PAGE':
			return { ...state, activePage: action.payload };

		case 'SET FORM SUBMISSION STATUS':
			return { ...state, status: action.payload };

		case 'SET SELECTED COMMUNITY ID':
			return { ...state, communityId: action.payload };

		case 'SET LAB FORM ERROR':
			return { ...state, error: action.payload };

		case 'REMOVE LAB FORM ERROR':
			return { ...state, error: null };

		default:
			return state;
	}
};

/**
 * clearLabState is an action creator which clears the lab state in
 * the app store
 */
export const clearLabState = (): Dispatch => ({
	type: Lab.CLEAR_LAB_FACILITY_TYPE
});
