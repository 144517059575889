import React from 'react';
import { Loader, Header, Button, Icon } from 'semantic-ui-react';
import { MIDWIFE_RESPONSE_SCHEMA, getUserDetails } from '../../../resource/services';
import { DetailProps } from '../../../resource/hocs';
import { Text } from '../../../resource/components';

const MidwifeDetail = (props: DetailProps<MIDWIFE_RESPONSE_SCHEMA>) => {
	const { data, loading } = props;
	const details = data ? getUserDetails(data) : null;
	return (
		<div className="detail-view">
			<div className="detail-block detail-block--small">
				{data && (
					<div>
						<Button
							size="medium"
							icon
							className="detail-block-btn"
							onClick={() => props.handleDelete(data)}
						>
							<Icon name="trash" color="red" />
						</Button>
					</div>
				)}
				<Button size="medium" icon className="detail-block-btn" onClick={props.handleClose}>
					<Icon name="close" color="red" />
				</Button>
			</div>
			{loading ? (
				<Loader active={loading} size="large" />
			) : (
				<React.Fragment>
					{details && (
						<React.Fragment>
							<div className="detail-block">
								<Header className="header" textAlign="center">
									Midwife
								</Header>
								<Text>Details of the midwife</Text>
								<div>
									{Object.keys(details).map((propName, _i: number) => (
										<Text key={_i} className="ix text">
											<span>{propName}</span> {(details as any)[propName]}
										</Text>
									))}
								</div>
							</div>
						</React.Fragment>
					)}
					{data === null && (
						<div className="detail-block">
							<Header
								textAlign="center"
								className="details-header details-header--error"
							>
								No Details
							</Header>
							<p className="details-text details-text--error">
								There is no information about the midwife. Please enter the details
								for the midwife by clicking on the edit icon above.
							</p>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default MidwifeDetail;
