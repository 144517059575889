import React from 'react';
import PropTypes from 'prop-types';
import { ToolBarProps, wrappedToolbar } from '../../../resource/hocs';
import { ToolbarButton } from '../../../resource/components';

export const ToolBar = (props: ToolBarProps) => (
	<div className="toolbar">
		<ToolbarButton iconName="plus" content="Add Technician" onClick={props.handleAdd} />
	</div>
);

ToolBar.propTypes = {
	handleAdd: PropTypes.func.isRequired
};

export default wrappedToolbar(ToolBar as any);
