import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Access } from '../../resource/services';
import { AppContext } from '../../resource/store/contexts/AppContext';
import { Router, routes } from '../Dashboard/routes';
import { Constants, fetchAccessRouteData } from './_helpers';

const useLoadStore = () => {
	const [sync, setSync] = useState(false);
	const dispatch = useDispatch();
	const mountedRef = useRef(false);
	const { onLoading } = useContext(AppContext);

	const isRouteAccessible = (route: Router) => {
		const isAccessible = Access.isAccessible(route.path);
		const condition =
			route.subComponents &&
			route.subComponents.length > 0 &&
			route.show === undefined &&
			isAccessible &&
			route.path !== Constants.app.routes.dashboard.USER_PROFILE;
		return condition;
	};

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		const accessibleRoutes = routes.filter(isRouteAccessible);
		setSync(true);
		onLoading(true);
		const pendingFetch = accessibleRoutes.map(async route => {
			const res = await fetchAccessRouteData(route.path);
			dispatch({ type: res!.action, payload: res!.data });
		});
		Promise.all(pendingFetch)
			.then(() => {
				mountedRef.current && setSync(false);
				onLoading(false);
			})
			.catch(() => {
				mountedRef.current && setSync(false);
				onLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return sync;
};

export default useLoadStore;
