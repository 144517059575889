import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import { Selector, Text, TextField } from '../../../resource/components';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';

type Props = {
	request: SAMPLES_RESPONSE_SCHEMA;
	onCustomResult: (e: React.FormEvent<HTMLInputElement>) => void;
	onSelectTestResult: (e: React.FormEvent<HTMLInputElement>) => void;
	onClaimsCheckCodeChange: (e: React.FormEvent<HTMLInputElement>) => void;
	claimsCheckCode: string;
	onLabRecordNoChange: (e: React.FormEvent<HTMLInputElement>) => void;
	labRecordNo: string;
};

const TestResult = (props: Props) => {
	const {
		request,
		onCustomResult,
		onSelectTestResult,
		onClaimsCheckCodeChange,
		claimsCheckCode,
		onLabRecordNoChange,
		labRecordNo
	} = props;

	const renderTests = () => {
		const { lab_tests } = request;
		return lab_tests.map(({ id, lab_test, lab_result }) => (
			<React.Fragment key={id}>
				<Text>{lab_test.name + ' *'}</Text>
				<div className="list">
					{lab_test.possible_results.length > 1 ? (
						lab_test.possible_results.map((result, i) => {
							return !result ? (
								<TextField
									key={i}
									type="text"
									name={id}
									label="Enter result for test"
									onChange={onCustomResult}
									defaultValue={lab_result}
								/>
							) : (
								<Selector
									label={result}
									key={i}
									name={id}
									value={result}
									defaultChecked={Boolean(result === lab_result)}
									onChange={onSelectTestResult}
								/>
							);
						})
					) : (
						<TextField
							type="text"
							name={id}
							label="Enter result for test"
							onChange={onCustomResult}
							defaultValue={lab_result}
						/>
					)}
				</div>
			</React.Fragment>
		));
	};

	return (
		<>
			<Header className="title">Test Result</Header>
			<Divider className="content" />
			{renderTests()}
			{request.has_insurance && (
				<>
					<TextField
						type="text"
						label="Claims check code *"
						name="ccc"
						value={claimsCheckCode}
						onChange={onClaimsCheckCodeChange}
						maxLength="5"
					/>
					<TextField
						type="text"
						label="Health Insurance Code"
						value={request.insurance_code}
						disabled
					/>
				</>
			)}
			<TextField
				type="text"
				label="Lab record number *"
				name="labRecordNo"
				value={labRecordNo}
				onChange={onLabRecordNoChange}
			/>
		</>
	);
};

export default TestResult;
