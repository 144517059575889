import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, Header, Icon, Loader } from 'semantic-ui-react';
import { Constants } from '../../../resource/constants';
import { DetailProps } from '../../../resource/hocs';
import { convertToReadable, getUserDetails, LAB_RESPONSE_SCHEMA } from '../../../resource/services';
import { Lab } from '../../../resource/store';
import './css/detailview.css';

const HIDDEN_DETAILS: Array<string> = ['id', 'admin'];

export const LabDetail = ({ data, loading, ...props }: DetailProps<LAB_RESPONSE_SCHEMA>) => {
	const dispatch = useDispatch();
	const format = data ? getUserDetails(data['admin']) : null;

	return (
		<div className="detail-view">
			<div className="detail-block detail-block--small">
				{data && (
					<div>
						<Button
							icon
							className="detail-block-btn"
							onClick={() => {
								dispatch({ type: Lab.STORE_LAB_FACILITY_TYPE, payload: data });
								props.handleEdit(data);
							}}
						>
							<Icon name="pencil" color="teal" />
						</Button>
						<Button
							size="medium"
							icon
							className="detail-block-btn"
							onClick={() => props.handleDelete(data)}
						>
							<Icon name="trash" color="red" />
						</Button>
					</div>
				)}
				<Button size="medium" icon className="detail-block-btn" onClick={props.handleClose}>
					<Icon name="close" color="red" />
				</Button>
			</div>
			{loading ? (
				<Loader active={loading} size="large" />
			) : (
				<React.Fragment>
					{data && (
						<div className="detail-block">
							<Divider horizontal>
								<span style={styles.sectionTitle}>Details</span>
							</Divider>
							<div style={{ marginTop: '0.98rem' }} />
							{Object.keys(data).map((key: string, _i: number) => (
								<p className="details-text" key={_i}>
									{typeof (data as any)[key] === 'object' ||
									HIDDEN_DETAILS.includes(key) ? null : (
										<React.Fragment>
											<span style={styles.detailText}>
												{convertToReadable(key)}
											</span>{' '}
											- {(data as any)[key]}
										</React.Fragment>
									)}
								</p>
							))}
						</div>
					)}
					{format && (
						<div className="detail-block">
							<Divider horizontal>
								<span style={styles.sectionTitle}>Administrator</span>
							</Divider>
							<div style={{ marginTop: '0.98rem' }} />
							{Object.keys(format).map((key: string, _i: number) => (
								<p className="details-text" key={_i}>
									<span style={styles.detailText}>{convertToReadable(key)}</span>{' '}
									- {(format as any)[key]}
								</p>
							))}
						</div>
					)}
					{data === null && (
						<div className="detail-block">
							<Header
								textAlign="center"
								className="details-header details-header--error"
							>
								No Details
							</Header>
							<p className="details-text details-text--error">
								There is no information about the laboratory. Please enter the
								details for the lab by clicking on the edit icon above.
							</p>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	sectionTitle: {
		fontFamily: 'Open Sans',
		fontWeight: 400,
		textTransform: 'uppercase',
		color: Constants.app.theme.SECONDARY,
		fontSize: '1.2rem'
	},
	detailText: {
		textTransform: 'capitalize',
		fontWeight: 300,
		color: Constants.app.theme.SECONDARY,
		marginRight: '0.45rem'
	}
};
