import isEmpty from 'lodash/isEmpty';
import { LAB_TEST_RESPONSE_SCHEMA, SAMPLE_TYPE_RESPONSE_SCHEMA } from '../../../resource/services';
import { Constants as K } from '../../../resource/constants';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const createAndUpdateLabTest = (data: any) => {
	return { url: '' };
};

/**
 * function returns the url for the lab test that is to be deleted
 * @param data lab test
 */
export const deleteLabTest = (data: any) => {
	const { id } = data as LAB_TEST_RESPONSE_SCHEMA;
	return {
		url: `${K.api.routes.DELETE_LAB_TEST}/${id}`,
		method: 'DELETE',
		store: K.app.db.TEST,
		id
	};
};

type TListItem = { text: string; value: string; id: string };

export class LabTestFactory {
	public static EMPTY_LAB_TEST: LAB_TEST_RESPONSE_SCHEMA = {
		id: '',
		name: '',
		NHIS_CODE: '',
		GDRG_CODE: '',
		NHIS_FEE: 0,
		CASH_FEE: 0,
		sample_type: {
			description: '',
			id: ''
		},
		possible_results: [],
		is_deleted: false
	};

	public static EMPTY_SAMPLE_TYPE: SAMPLE_TYPE_RESPONSE_SCHEMA = {
		description: '',
		id: ''
	};

	/**
	 * the factory method returns a lab test. a check is made on the
	 * data passed into it. the return value is based on the data having
	 * the specific properties or null.
	 * if null value is detected the empty lab test value is return. this
	 * ensures the prevention of null checks within the application or
	 * specifically the component
	 * @param data lab test
	 */
	public static getLabTest(data: any): LAB_TEST_RESPONSE_SCHEMA {
		return !isEmpty(data) ? data : LabTestFactory.EMPTY_LAB_TEST;
	}

	/**
	 * the factory method returns an array of list items of sample
	 * types which is then render in the select field component
	 * ___
	 * **NOTE** the factory always returns an array of the list items
	 * whether or not the param has values. this ensure that null
	 * values are not checked in usage
	 * @param data sample types
	 */
	public static getSampleTypes(data: any): TListItem[] {
		if (isEmpty(data)) {
			return Array(1).fill({
				text: LabTestFactory.EMPTY_SAMPLE_TYPE.description,
				value: LabTestFactory.EMPTY_SAMPLE_TYPE.description,
				key: LabTestFactory.EMPTY_SAMPLE_TYPE.id
			});
		}

		return data.map((sampleType: SAMPLE_TYPE_RESPONSE_SCHEMA) => ({
			text: sampleType.description,
			value: sampleType.description,
			key: sampleType.id
		}));
	}

	/**
	 * the factory function returns an array of results formatted
	 * in the structure that can be rendered with the result fields
	 * ___
	 * **NOTE** ensure that the data passed into the factory should be
	 * a valid lab test
	 * @param test lab test
	 */
	public static getLabTestResults(test: LAB_TEST_RESPONSE_SCHEMA) {
		return test.possible_results.map(result => ({
			value: result,
			name: new Date().getTime().toString()
		}));
	}

	/**
	 * the factory method returns the sample type of the lab
	 * test from the sampleTypes by checking the id value of the
	 * sample types
	 * @param sampleTypes all sample types
	 * @param test lab test
	 */
	public static getLabTestSampleType(
		sampleTypes: SAMPLE_TYPE_RESPONSE_SCHEMA[] | null,
		test: LAB_TEST_RESPONSE_SCHEMA
	) {
		if (!sampleTypes || isEmpty(sampleTypes)) {
			return '';
		}
		const { description: desc } = test.sample_type;
		const type = sampleTypes.find(
			({ description }) => description.toLowerCase() === desc.toLowerCase()
		);
		return type ? type.description : '';
	}
}
