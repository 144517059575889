import React from 'react';
import { ToolbarButton } from '../../../resource/components';
import { wrappedToolbar } from '../../../resource/hocs';

interface IProps {
	onAddRegion: () => void;
}

const Toolbar = (props: IProps) => (
	<div className="toolbar">
		<ToolbarButton iconName="plus" content="Add Region" onClick={props.onAddRegion} />
	</div>
);

export default wrappedToolbar(Toolbar as any);
