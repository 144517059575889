import { Constants as SharedConstants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedConstants,
	configs: [
		{
			title: 'Courier Details',
			description: 'Fill in the details of the courier',
			fields: UserForm.userDetails,
			path: SharedConstants.app.routes.dashboard.ADD_COURIER,
			name: 'courier'
		}
	]
};
