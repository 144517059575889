import React, { PureComponent, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Constants as K } from '../constants';
import { Access } from '../services';
import { Text } from './Text';
import './css/tools.css';
import { AppStore } from '../store';
import secured from '../assets/images/secured.svg';

interface IRestrictedAccess extends RouteComponentProps {
	user: AppStore['user']['user'];
}

interface IState {
	access: Array<string> | null;
	prevPath: string;
	isAccessible: boolean;
}

class RestrictedAccess extends PureComponent<IRestrictedAccess, IState> {
	private _isMounted = false;
	public accessPoints: Array<string> = [];
	public readonly state: IState = {
		access: null,
		prevPath: '',
		isAccessible: true
	};

	public static propTypes = {
		user: PropTypes.oneOfType([PropTypes.object])
	};

	componentDidMount() {
		this._isMounted = true; // value is set when component is mounted
		const user = this.props.user;
		if (!user) {
			this.props.history.push(K.app.routes.SIGNIN);
			return;
		}
		Access.subscribe(user);
		if (this._isMounted) {
			this.setState((_, props: Readonly<IRestrictedAccess>) => {
				return {
					prevPath: props.location.pathname,
					isAccessible: Access.isAccessible(props.location.pathname)
				};
			});
		}
	}

	componentWillUpdate() {
		// validate if the user is allowed to view the current location
		// then move them to the previous page if they cannot access the
		// path
		if (this._isMounted) {
			this.setState((_, props: Readonly<IRestrictedAccess>) => {
				return {
					isAccessible: Access.isAccessible(props.location.pathname)
				};
			});
		}
	}

	public render(): ReactNode {
		if (this.state.isAccessible) {
			return this.props.children;
		}
		return (
			<div className="secured">
				<img src={secured} alt="secured" />
				<Header className="header">Restricted Access</Header>
				<Text>
					Access to this page is denied. Please ensure you have the rights to access this
					page.
				</Text>
				<Button onClick={this.onReturn} className="toolbar-btn">
					Go Back
				</Button>
			</div>
		);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	public onReturn = () => {
		this.props.history.goBack();
	};
}

export default RestrictedAccess;
