import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import xlsx from 'xlsx';

export const downloadHtml = async (id: string, filename: string) => {
	const elem = document.getElementById(id);
	if (!elem) return;
	const canvas = await html2canvas(elem, { scale: 2 });
	const doc = new JsPDF('p', 'mm', 'a4');
	doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
	doc.save(filename);
};

// downloads the html table as an excel file
export const generateExcelSheetFromTable = (tableId: string, filename: string) => {
	const workbook = xlsx.utils.table_to_book(document.getElementById(tableId));
	xlsx.writeFile(workbook, filename);
};

export const generatePdfFromTable = (tableId: string, filename: string, configs: any = {}) => {
	const doc = new JsPDF();
	(doc as any).autoTable({
		html: document.getElementById(tableId),
		...configs
	});
	doc.save(filename);
};

export const printHtmlContent = (id: string) => {
	const htmlContent = `<html>${document.getElementById(id)!.innerHTML}</html>`;
	const printWindow = window.open();
	if (!printWindow) return;
	printWindow.document.write(htmlContent);
	printWindow.document.close();
	printWindow.focus();
	printWindow.print();
	// printWindow.close();
};
