import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Header, Icon, SemanticICONS } from 'semantic-ui-react';
import { Text, Widget } from '../../resource/components';
import './css/detail.css';

interface Props {
	title: string;
	subTitle?: string;
	description?: string;
	icon: SemanticICONS;
	path: string;
	total: number;
}

const OverviewDetail = (props: Props) => {
	const { title, subTitle, description, icon, path, total } = props;

	return (
		<Widget className="section-overview">
			<Header className="heading">
				{title}
				<Header.Subheader>{subTitle}</Header.Subheader>
				<div className="actions toolbar">
					<Link to={path} className="link">
						<Icon name="eye" />
					</Link>
				</div>
			</Header>
			<Divider className="separator" />
			<div className="main-content">
				<div className="content-detail">
					<Icon name={icon} />
					<Text>{description}</Text>
				</div>
				<h1 className="summary">{total}</h1>
			</div>
		</Widget>
	);
};

export default OverviewDetail;
