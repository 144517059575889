import { combineReducers } from 'redux';
import clinicReducer, { ClinicState } from './clinicReducer';
import labReducer, { LabState } from './labReducer';
import miscReducer, { MiscState } from './miscReducer';
import nhisReducer, { NhisState } from './nhisReducer';
import labTestReducer, { LabTestState } from './labTestReducer';
import userReducer, { UserState } from './userReducer';
import sampleReducer, { SampleState } from './requestReducer';
import technicianReducer, { TechnicianState } from './technicianReducer';
import midwifeReducer, { MidwifeState } from './midwifeReducer';
import courierReducer, { CourierState } from './courierReducer';
import regionReducer, { RegionState } from './regionReducer';
import profileReducer, { ProfileState } from './profileReducer';
import dataReducer, { DataState } from './dataReducer';

export interface AppStore {
	lab: LabState;
	clinic: ClinicState;
	misc: MiscState;
	nhis: NhisState;
	test: LabTestState;
	user: UserState;
	sample: SampleState;
	technician: TechnicianState;
	midwife: MidwifeState;
	courier: CourierState;
	regional: RegionState;
	profile: ProfileState;
	data: DataState;
}

export default combineReducers<AppStore>({
	lab: labReducer,
	clinic: clinicReducer,
	misc: miscReducer,
	nhis: nhisReducer,
	test: labTestReducer,
	user: userReducer,
	sample: sampleReducer,
	technician: technicianReducer,
	midwife: midwifeReducer,
	courier: courierReducer,
	regional: regionReducer,
	profile: profileReducer,
	data: dataReducer
});
