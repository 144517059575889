import { Constants as SC } from '../../../resource/constants';
import {
	Clinic,
	Courier,
	Lab,
	LabTest,
	Midwife,
	NHIS,
	Region,
	Sample,
	Technician
} from '../../../resource/store';

const routes = SC.app.routes.dashboard;
const api = SC.api.routes;

export const Constants = {
	...SC,
	apiRoutes: {
		[routes.LABS]: [api.GET_LABS, Lab.STORE_LABS],
		[routes.CLINICS]: [api.GET_CLINICS, Clinic.STORE_CLINICS],
		[routes.NHIS_VIEW]: [api.NHIS_SCHEMES, NHIS.STORE_NHIS],
		[routes.LAB_TESTS]: [api.GET_LAB_TESTS, LabTest.STORE_LAB_TESTS_TYPE],
		[routes.REGIONS]: [api.REGIONS, Region.STORE_REGIONS],
		[routes.SAMPLE_REQUESTS]: [api.GET_LAB_REQUESTS, Sample.FETCH_SAMPLES_SUCCESS],
		[routes.MIDWIFES]: [api.MIDWIVES, Midwife.STORE_MIDWIVES],
		[routes.COURIERS]: [api.COURIERS, Courier.STORE_COURIERS],
		[routes.TECHNICIANS]: [api.GET_LAB_TECHNICIANS, Technician.STORE_LAB_TECHNICIANS]
	}
};
