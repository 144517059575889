import { useState } from 'react';
import { STATUS_SCHEMA } from '../../../resource/services';

type Fields = {
	reason: string;
	status: STATUS_SCHEMA;
	ccc: string;
	labRecordNo: string;
};

const useResultForm = (initialValues: Fields) => {
	const [values, setValues] = useState(initialValues);

	return {
		...values,
		setValue(e: any) {
			setValues({
				...values,
				[e.target.name]: e.target.value
			});
		}
	};
};

export default useResultForm;
