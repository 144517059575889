import { Constants } from '../../../resource/constants';
import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { deleteLabTechnician } from '../_helpers';
import TechnicianDetail from './TechnicianDetail';
import TechniciansList from './TechniciansList';
import ToolBar from './Toolbar';

const options: WrappedListViewOptions = {
	title: 'Lab Technicians',
	form: Constants.app.routes.dashboard.ADD_LAB_TECHNICIAN,
	description: 'Manage the technicians in the laboratory',
	storeKey: 'technician',
	stateKey: 'technicians'
};

export default wrappedAdvancedListView(
	TechniciansList as any,
	TechnicianDetail as any,
	ToolBar as any,
	options,
	deleteLabTechnician
);
