import { useEffect, useState } from 'react';
import { getLabCouriers } from '../../../resource/dataService';
import { COURIER_RESPONSE_SCHEMA } from '../../../resource/services';

type Values = {
	status: 'OK' | 'BAD';
	riderId: string;
};

const useStatusForm = (values: Values) => {
	const [state, setState] = useState(values);
	const [riders, setRiders] = useState<COURIER_RESPONSE_SCHEMA[]>([]);

	useEffect(() => {
		const fetchLabRiders = async () => {
			const fetchedRiders = await getLabCouriers();
			setRiders(fetchedRiders || []);
		};
		fetchLabRiders();
	}, []);

	return {
		...state,
		riders,
		onChange: (e: any, data?: any) => {
			const value =
				typeof e === 'string' ? { riderId: e } : { [e.target.name]: e.target.value };
			setState({ ...state, ...value });
		}
	};
};

export default useStatusForm;
