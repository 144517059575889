import { DataStorage } from '../../../resource/dataService';
import { Dispatcher, verifyAccount, Dispatch } from '../../../resource/services';
import { User } from '../../../resource/store';
import { NotifcationSubscription } from '../../../resource/services';

/**
 * authenticateUser takes the user info and submits the data to the
 * server for authentication. the data returned will be sent to the
 * app store.
 * @param user signin credentials
 */
export const authenticateUser = (data: any) => async (dispatch: Function) => {
	try {
		if (NotifcationSubscription.userId) {
			data.device_id = NotifcationSubscription.userId;
		}
		const res = await verifyAccount(data);
		// save the user info in the user state
		dispatch({
			type: User.STORE_USER_TYPE,
			payload: res
		});
	} catch (error) {
		dispatch({
			type: User.USER_INVALID_TYPE,
			payload: error
		});
	}
};

/**
 * getUserDataToStore is an action creator which get the user details
 * in the local storage and places it in the application store which
 * is the accessed by other components
 */
export const getUserDataToStore = () => (dispatch: Dispatcher) => {
	if (DataStorage.user) {
		// load the user data in to the app store
		const data = DataStorage.user;
		dispatch({
			type: User.STORE_USER_TYPE,
			payload: data
		});
	}
};

/**
 * clearUserState is an action creator which sends the
 * action of clearing the user state
 */
export const clearUserState = (): Dispatch => ({
	type: User.CLEAR_USER_TYPE
});
