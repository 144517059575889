import React from 'react';
import { ToolbarButton } from '../../../resource/components';

interface IProps {
	onBack: () => void;
}

const Toolbar = (props: IProps) => (
	<div className="toolbar">
		<ToolbarButton iconName="arrow left" content="Go back" onClick={props.onBack} />
	</div>
);

export default Toolbar;
