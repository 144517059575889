import React, { CSSProperties, PureComponent } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { EmptyState, Text } from '../../../resource/components';
import { LAB_RESPONSE_SCHEMA } from '../../../resource/services';
import './css/labs.css';

interface ILabList {
	labs: LAB_RESPONSE_SCHEMA[] | null;
	onLabSelect: (lab: LAB_RESPONSE_SCHEMA) => void;
	labId: string;
}

class LabList extends PureComponent<ILabList> {
	public render(): React.ReactNode {
		if (!this.props.labs) {
			return (
				<EmptyState
					title="No registered Laboratories"
					description="There are no registered labs. Register a lab to assign the clinic to."
				/>
			);
		}
		return (
			<React.Fragment>
				<Header className="header iw large" textAlign="center">
					Laboratory
				</Header>
				<Text style={styles.text}>
					Choose a laboratory for the clinic to send sample requests.
				</Text>
				<div className="iw lists">
					{this.props.labs.map(lab => (
						<div
							key={lab.id}
							className="iw detail-block item"
							onClick={() => this.props.onLabSelect(lab)}
						>
							{this.renderLabDetails(lab)}
						</div>
					))}
				</div>
			</React.Fragment>
		);
	}

	public renderLabDetails = (lab: LAB_RESPONSE_SCHEMA): React.ReactNode => {
		return (
			<React.Fragment>
				<div className="details">
					<Text className="iw header">
						<span>Name</span>
						{lab.name}
					</Text>
					<Text>
						<span>Region</span>
						{lab.region}
					</Text>
					<Text>
						<span>District</span>
						{lab.district}
					</Text>
					<Text>
						<span>Community</span>
						{lab.community}
					</Text>
				</div>
				{this.props.labId === lab.id && (
					<div className="iw image-box">
						<Icon name="check" color="green" size="large" />
					</div>
				)}
			</React.Fragment>
		);
	};
}

const styles: { [key: string]: CSSProperties } = {
	text: {
		margin: 'auto',
		lineHeight: '1.7rem',
		marginBottom: '1.7rem'
	}
};

export default LabList;
