import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';

const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		blacklist: [
			'lab',
			'clinic',
			'misc',
			'nhis',
			'test',
			'sample',
			'technician',
			'midwife',
			'courier',
			'regional',
			'profile',
			'data'
		]
	},
	reducers
);

const middlewares = [ReduxThunk];

const dev =
	process.env.NODE_ENV === 'development'
		? composeWithDevTools(applyMiddleware(...middlewares))
		: applyMiddleware(...middlewares);

export const store = createStore(persistedReducer, {}, dev);
export const persistor = persistStore(store);
