import { Constants as SharedConstants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedConstants,
	configs: [
		{
			path: SharedConstants.app.routes.dashboard.ADD_CLINIC_DETAIL,
			title: 'Clinic',
			description: 'Fill the required details of the clinic',
			fields: [
				{
					type: 'text',
					name: 'name',
					placeholder: 'Name of clinic'
				},
				{
					type: 'text',
					name: 'clinic_code',
					placeholder: 'Clinic code'
				},
				{
					type: 'tel',
					name: 'telephone',
					placeholder: 'Clinic telephone number'
				}
			],
			showLevels: true,
			name: 'clinic'
		},
		{
			title: 'Administrator',
			description: 'Fill the required details of the administrator',
			fields: UserForm.userFields,
			path: SharedConstants.app.routes.dashboard.ADD_CLINIC_ADMIN,
			name: 'clinic-admin'
		}
	]
};
