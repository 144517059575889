import React from 'react';
import { ToolbarButton } from '../../../resource/components';
import { wrappedToolbar } from '../../../resource/hocs';

interface IToolbar {
	onEdit: () => void;
}

export const Toolbar = (props: IToolbar) => (
	<div className="toolbar">
		<ToolbarButton onClick={props.onEdit} iconName="pencil" content="Edit profile" />
	</div>
);

export default wrappedToolbar(Toolbar as any);
