import React, { CSSProperties } from 'react';

type IProps = {
	content: string;
	description?: string;
	style?: CSSProperties;
};

export default function Title({ content, style = {}, description }: IProps) {
	return (
		<p style={{ ...headingStyles, ...style }}>
			{content}
			<em style={descStyles}>{description}</em>
		</p>
	);
}

const headingStyles: React.CSSProperties = {
	margin: 0,
	fontSize: '12pt',
	padding: '7pt 0 7pt',
	fontWeight: 'bold'
};

const descStyles: React.CSSProperties = {
	fontSize: '10pt',
	fontWeight: 'normal',
	display: 'inline-block',
	marginLeft: '4pt'
};
