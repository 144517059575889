import { Constants as SharedConstants } from '../../../resource/constants';
import { UserForm } from '../../../resource/services';

export const Constants = {
	...SharedConstants,
	configs: [
		{
			title: 'Health Facility',
			path: SharedConstants.app.routes.dashboard.ADD_LAB,
			description: 'Fill in the required fields for the health facility',
			fields: [
				{
					type: 'text',
					name: 'name',
					placeholder: 'Enter laboratory name'
				},
				{
					type: 'tel',
					name: 'telephone',
					placeholder: 'Enter telephone of laboratory'
				}
			],
			showLevels: true,
			name: 'lab'
		},
		{
			title: 'Administrator',
			description: 'Fill in the required fields for the administrator',
			fields: UserForm.userFields,
			path: SharedConstants.app.routes.dashboard.ADD_LAB_ADMIN,
			name: 'lab-admin'
		}
	]
};
