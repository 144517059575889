import React from 'react';

interface IProps {
	required?: boolean;
	style?: React.CSSProperties;
}

export default function Label({ required, children, style = {} }: React.PropsWithChildren<IProps>) {
	const styling = {
		maxWidth: 180,
		display: 'inline-block',
		fontSize: '10pt',
		marginRight: '1em',
		...style
	};
	return (
		<label style={styling}>
			{children}
			{required && <sup>*</sup>}
		</label>
	);
}
