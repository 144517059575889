import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { TableWrap } from '../../../resource/components';
import { convertToReadableDate, SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';

type Props = {
	samples: SAMPLES_RESPONSE_SCHEMA[];
	activePage: number;
	pageSize: number;
	summaryTableId: string;
	totalAmount: number;
	showCashReport: boolean;
};

function SummaryReportListView(props: Props) {
	const { samples, activePage, pageSize, summaryTableId, totalAmount, showCashReport } = props;

	return (
		<TableWrap>
			<Table id={summaryTableId} basic="very">
				<Table.Header>
					<Table.Row>
						<StyledHeaderCell>No.</StyledHeaderCell>
						<StyledHeaderCell>Surname</StyledHeaderCell>
						<StyledHeaderCell>Other names</StyledHeaderCell>
						<StyledHeaderCell>Date Of Birth</StyledHeaderCell>
						<StyledHeaderCell>Gender</StyledHeaderCell>
						<StyledHeaderCell>Attendance Date</StyledHeaderCell>
						<StyledHeaderCell style={{ width: showCashReport ? '8.5rem' : '7.5rem' }}>
							Discharge Date/Request Date
						</StyledHeaderCell>
						<StyledHeaderCell>Clinic</StyledHeaderCell>
						<StyledHeaderCell>Hospital Records Number</StyledHeaderCell>
						{!showCashReport && <StyledHeaderCell>NHIS Code</StyledHeaderCell>}
						<StyledHeaderCell>Claim Check Code(CCC)</StyledHeaderCell>
						<StyledHeaderCell style={{ width: showCashReport ? '32rem' : '25rem' }}>
							Diagnosis
						</StyledHeaderCell>
						<StyledHeaderCell style={{ width: '22rem' }}>G-DRG</StyledHeaderCell>
						<StyledHeaderCell>Service</StyledHeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{React.Children.toArray(
						samples.map((sample, i) => {
							const total = sample.lab_tests.reduce(
								(acc, test) => acc + test.lab_test.NHIS_FEE,
								0
							);
							const { surname, first_name, middle_name, gender, dob } =
								sample.patient.user;
							const otherNames = `${first_name} ${middle_name}`.trim();
							const requestDate = convertToReadableDate(sample.date_of_request);
							return (
								<Table.Row>
									<StyledCell>{(activePage - 1) * pageSize + i + 1}</StyledCell>
									<StyledCell>{surname}</StyledCell>
									<StyledCell>{otherNames}</StyledCell>
									<StyledCell>{convertToReadableDate(dob)}</StyledCell>
									<StyledCell>
										{gender.toLowerCase().match('m') ? 'Male' : 'Female'}
									</StyledCell>
									<StyledCell>{requestDate}</StyledCell>
									<StyledCell>{requestDate}</StyledCell>
									<StyledCell>{sample.clinic.name}</StyledCell>
									<StyledCell>{sample.patient.record_number}</StyledCell>
									{!showCashReport && (
										<StyledCell>{sample.insurance_code}</StyledCell>
									)}
									<StyledCell>{sample.ccc}</StyledCell>
									<StyledCell>
										{sample.lab_tests
											.map((test) => test.lab_test.name)
											.join(', ')}
									</StyledCell>
									<StyledCell>
										{sample.lab_tests
											.map((test) => test.lab_test.NHIS_CODE)
											.join(', ')}
									</StyledCell>
									<StyledCell>{total.toFixed(2)}</StyledCell>
								</Table.Row>
							);
						})
					)}
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.Cell colSpan={showCashReport ? '10' : '11'}>Total Amount</Table.Cell>
						<Table.Cell>{totalAmount.toFixed(2)}</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</TableWrap>
	);
}

const StyledHeaderCell = styled(Table.HeaderCell)`
	font-size: 0.85rem !important;
	padding: 0.85rem 0.55rem !important;
`;

const StyledCell = styled(Table.Cell)`
	font-size: 0.85rem !important;
	padding: 0.85rem 0.55rem !important;
`;

export default SummaryReportListView;
