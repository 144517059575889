import React from 'react';

interface IProfileItem {
	name: string;
	value: string | number;
}

const ProfileItem = (props: IProfileItem) => (
	<li className="item">
		<span className="label">{props.name}</span>
		<span>{props.value}</span>
	</li>
);

export default ProfileItem;
