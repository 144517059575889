import React from 'react';
import { ToolBarProps as Props, wrappedToolbar } from '../../../resource/hocs';
import { ToolbarButton } from '../../../resource/components';

export const Toolbar = (props: Props) => (
	<div className="toolbar">
		<ToolbarButton iconName="plus" content="Add Clinic" onClick={props.handleAdd} />
	</div>
);

export default wrappedToolbar(Toolbar as any);
