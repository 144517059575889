import { Constants as K } from '../../../resource/constants';

/**
 * the function takes the state as its param and restructures
 * the data to match the data structure need to upload to the
 * server
 * @param data state from wrappedAdvancedForm
 */
export const formatFormState = (data: any) => {
	return { url: '' };
};

export const deleteClinic = (data: any) => {
	const { id } = data;
	return {
		url: K.api.routes.DELETE_CLINIC + `/${id}`,
		method: 'DELETE',
		store: K.app.db.CLINIC
	};
};
