import { Constants, Constants as K } from '../constants';

export const setAuthToken = (token: string) => {
	localStorage.setItem('AUTHORIZATION_KEY', JSON.stringify(token));
};

export const getAuthToken = () => {
	const token = localStorage.getItem('AUTHORIZATION_KEY');
	return JSON.parse(token || '');
};

export class ServerRequest {
	public static async uploadService(url: string, data: any, isAuth = true, method = 'POST') {
		try {
			const config: any = {
				method,
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ data }),
			};
			if (isAuth) {
				config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
			}
			const res = await fetch(`${Constants.api.BASE_URL}${url}`, config);
			const resData = await res.json();
			if (resData.error) throw resData;
			return resData.data ? resData.data : resData;
		} catch (error) {
			// TODO: create error handling service for api requests
			throw error;
		}
	}

	public static async get(url: string) {
		try {
			const config: RequestInit = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthToken()}`,
				},
			};
			const response = await fetch(`${Constants.api.BASE_URL}${url}`, config);
			const jsonData = await response.json();
			if (jsonData.error) {
				throw jsonData;
			}
			return jsonData.data;
		} catch (error) {
			throw error;
		}
	}
}

export const verifyAccount = async (data: any): Promise<any> => {
	try {
		const user = await ServerRequest.uploadService(K.api.routes.USER_AUTHENTICATE, data, false);
		setAuthToken(user.auth_token);
		return user;
	} catch (error) {
		throw error;
	}
};

export const postLabTest = async (data: any) => {
	try {
		const test = await ServerRequest.uploadService(K.api.routes.ADD_LAB_TEST, data);
		return test;
	} catch (error) {
		throw error;
	}
};

export const uploadTestResult = async (data: any) => {
	try {
		const response = await ServerRequest.uploadService(
			K.api.routes.POST_LAB_TEST_RESPONSE,
			data
		);
		return response;
	} catch (error) {
		throw error;
	}
};

export const postLabForm = async (data: any, id = '') => {
	try {
		if (id) {
			const updateLabData = {
				id,
				name: data.name,
				telephone: data.telephone,
				community_id: data.community_id,
			};
			const lab = await ServerRequest.uploadService(
				K.api.routes.UPDATE_LAB,
				updateLabData,
				true,
				'PUT'
			);
			return lab;
		}
		const lab = await ServerRequest.uploadService(K.api.routes.POST_LAB, data);
		return lab;
	} catch (error) {
		throw error;
	}
};

/**
 * postNewClinicForm posts the clinic data to the server and
 * return the response from the server
 * @param data any
 */
export const postNewClinicForm = async (data: any) => {
	try {
		const clinic = await ServerRequest.uploadService(K.api.routes.POST_CLINIC, data);
		return clinic;
	} catch (error) {
		throw error;
	}
};

/**
 * addLabTechnician post the new data of a lab technician to the server
 * with the returned data of the newly added lab technician
 * @param data any
 */
export const addLabTechnician = async (data: any) => {
	try {
		const technician = await ServerRequest.uploadService(K.api.routes.ADD_LAB_TECHNICIAN, data);
		return technician;
	} catch (error) {
		throw error;
	}
};

/**
 * addMidwife posts a new midwife for registration to the server with the clinic
 * the midwife is associated to.
 * @param data any
 */
export const addMidwife = async (data: any) => {
	try {
		const midwife = await ServerRequest.uploadService(K.api.routes.ADD_MIDWIFE, data);
		return midwife;
	} catch (error) {
		throw error;
	}
};

/**
 * addCourier is an api service which posts a new courier of a lab to the
 * server
 * @param data any
 */
export const addCourier = async (data: any) => {
	try {
		const courier = await ServerRequest.uploadService(K.api.routes.ADD_COURIER, data);
		return courier;
	} catch (error) {
		throw error;
	}
};

export const postConfirmation = async (data: any) => {
	try {
		const confirm = await ServerRequest.uploadService(K.api.routes.CONFIRM_LAB_REQUEST, data);
		return confirm;
	} catch (error) {
		throw error;
	}
};

export const postRiderAssignment = async (data: any) => {
	try {
		const assign = await ServerRequest.uploadService(K.api.routes.ASSIGN_RIDER, data);
		return assign;
	} catch (error) {
		throw error;
	}
};
