import { Constants as SharedConstants } from '../../../resource/constants';

export default {
	...SharedConstants,
	form: {
		title: 'Laboratory Test',
		description: 'Fill in the details of the lab test',
		path: SharedConstants.app.routes.dashboard.LAB_TEST_ADD,
		name: 'test',
		fields: [
			{
				type: 'text',
				placeholder: 'Enter name of test',
				name: 'name'
			},
			{
				type: 'text',
				placeholder: 'Enter NHIS code',
				name: 'NHIS_CODE'
			},
			{
				type: 'text',
				placeholder: 'Enter GDRG code',
				name: 'GDRG_CODE'
			},
			{
				type: 'number',
				placeholder: 'Enter NHIS fee for the test',
				name: 'NHIS_FEE'
			},
			{
				type: 'number',
				placeholder: 'Enter cash fee for the test',
				name: 'CASH_FEE'
			}
		]
	}
};
