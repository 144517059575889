import PropTypes from 'prop-types';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Container, MainContent, RestrictedAccess } from '../../../resource/components';
import { getName, REGION_RESPONSE_SCHEMA, USERS_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import { Constants, Level, onUpdateArea, RegionFactory, storeLevel } from '../_helpers';
import './css/style.css';
import Overview from './Overview';
import Toolbar from './Toolbar';

export interface IRegionViewProps extends RouteComponentProps {
	region: REGION_RESPONSE_SCHEMA | null;
	user: AppStore['user']['user'];
	storeLevel: (id: string, level: Level, region?: any) => void;
	onUpdateArea: (data: any, region: any) => Promise<void>;
}

interface IRegionViewState {
	region: REGION_RESPONSE_SCHEMA | null;
}

class RegionDetail extends Component<IRegionViewProps, IRegionViewState> {
	public readonly state: IRegionViewState = {
		region: null
	};

	public static propTypes: PropTypes.InferProps<IRegionViewProps> = {
		user: PropTypes.shape<PropTypes.ValidationMap<USERS_RESPONSE_SCHEMA>>({}),
		region: PropTypes.shape<PropTypes.ValidationMap<REGION_RESPONSE_SCHEMA>>({})
	};

	public componentDidMount() {
		this.setState(() => ({ region: RegionFactory.getRegion(this.props.region) }));
	}

	public componentWillReceiveProps(props: Readonly<IRegionViewProps>) {
		if (props.region !== this.props.region) {
			this.setState(() => ({ region: RegionFactory.getRegion(props.region) }));
		}
	}

	public render(): ReactNode {
		const name = getName(this.state.region);
		return (
			<RestrictedAccess {...this.props} user={this.props.user}>
				<MainContent
					title={name}
					toolbar={<Toolbar onBack={this.viewRegionsPage} />}
					description={`Overview of ${name}`}
				>
					<Container className="region-detail">
						{!this.state.region ? (
							<Loader active size="massive" />
						) : (
							<Overview region={this.state.region} onUpdateRegion={this.onUpdate} />
						)}
					</Container>
				</MainContent>
			</RestrictedAccess>
		);
	}

	public moveToRegionForm = () => {
		// this.props.storeLevel();
		this.props.history.push(Constants.app.routes.dashboard.ADD_REGION);
	};

	public viewRegionsPage = () => {
		this.props.history.push(Constants.app.routes.dashboard.REGIONS);
	};

	public onAddDistrict = () => {
		this.setState(prevState => {
			const region = prevState.region as REGION_RESPONSE_SCHEMA;
			return {
				region: {
					...region,
					districts: [...region.districts, RegionFactory.EMPTY_DISTRICT]
				}
			};
		});
	};

	public onAddSubDistrict = (id: string) => {
		this.setState(prevState => {
			const region = prevState.region as REGION_RESPONSE_SCHEMA;
			return {
				region: {
					...region,
					districts: region.districts.map(district => {
						if (district.id === id) {
							district.sub_districts = [
								...district.sub_districts,
								RegionFactory.EMPTY_SUB_DISTRICT
							];
						}
						return district;
					})
				}
			};
		});
	};

	onAddCommunity = (subDistrictId: string, districtId: string) => {
		this.setState(prevState => {
			const region = prevState.region as REGION_RESPONSE_SCHEMA;
			return {
				region: {
					...region,
					districts: region.districts.map(district => {
						if (district.id === districtId) {
							district.sub_districts = district.sub_districts.map(subDistrict => {
								if (subDistrict.id === subDistrictId) {
									subDistrict.communities = [
										...subDistrict.communities,
										RegionFactory.EMPTY_COMMUNITY
									];
								}
								return subDistrict;
							});
						}
						return district;
					})
				}
			};
		});
	};

	public onUpdate = (data: any) => {
		this.props.onUpdateArea(data, this.state.region);
	};
}

const mapStateToProps = (store: AppStore) => ({
	user: store.user.user,
	region: store.regional.region
});

const mapDispatchToProps = {
	storeLevel,
	onUpdateArea
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RegionDetail);
