import { Constants as K } from '../constants';

const { dashboard: R } = K.app.routes;

const SUPER_ADMIN_RESTRICT = [
	R.SAMPLE_REQUESTS,
	R.SAMPLE_RESULT,
	R.TECHNICIANS,
	R.ADD_MIDWIFE,
	R.MIDWIFES,
	R.ADD_LAB_TECHNICIAN,
	R.EDIT_TECHNICIAN,
	R.COURIERS,
	R.ADD_COURIER,
	R.USER_PROFILE,
	R.USER_PROFILE_EDIT
];
const SUPER_ADMIN_ROUTES = Object.values(R).filter(route => !SUPER_ADMIN_RESTRICT.includes(route));

const PRIVILEGES = {
	PRIVILEGE_PATIENT: undefined,
	PRIVILEGE_MIDWIFE: undefined,
	PRIVILEGE_COURIER: undefined,
	PRIVILEGE_CLINIC_ADMIN: [
		R.CLINIC_PROFILE_EDIT,
		R.MIDWIFES,
		R.ADD_MIDWIFE,
		R.DASHBOARD,
		R.USER_PROFILE,
		R.USER_PROFILE_EDIT
	],
	PRIVILEGE_SUPER_ADMIN: [...SUPER_ADMIN_ROUTES],
	PRIVILEGE_LAB_ADMIN: [
		R.LAB_PROFILE_EDIT,
		R.TECHNICIANS,
		R.ADD_LAB_TECHNICIAN,
		R.EDIT_TECHNICIAN,
		R.SAMPLE_REQUESTS,
		R.COURIERS,
		R.ADD_COURIER,
		R.DASHBOARD,
		R.USER_PROFILE,
		R.USER_PROFILE_EDIT
	],
	PRIVILEGE_USER: undefined,
	PRIVILEGE_LAB_TECHNICIAN: [
		R.LAB_TESTS,
		R.SAMPLE_REQUESTS,
		R.SAMPLE_RESULT,
		R.SAMPLE_REQUEST_VIEW,
		R.SAMPLE_REQUEST_PATIENT,
		R.SAMPLE_REPORT,
		R.DASHBOARD,
		R.USER_PROFILE,
		R.USER_PROFILE_EDIT,
		R.SUMMARY_REPORT,
		R.SAMPLE_COURIER_ASSIGN,
		R.SAMPLE_STATUS
	]
};

export class Access {
	public static routes: Array<string> | null = null;

	/**
	 * subscribe is a class method in the Access class
	 * which subscribes the user to the allowed page access based on the
	 * response from the server of the user's privilege
	 * @param user any
	 */
	public static subscribe(user: any): void {
		if (!user) {
			return;
		}
		const routes = user.privileges
			? (user.privileges as []).reduce((_: any, currentValue: any) => {
					if ((PRIVILEGES as any)[currentValue] === undefined) {
						return null;
					}
					return (PRIVILEGES as any)[currentValue];
			  }, [])
			: null;
		Access.routes = routes;
	}

	/**
	 * unsubscribe class method removes the users accessible
	 * routes and clears any stored access pages in the application
	 */
	public static unsubscribe(): void {
		Access.routes = null;
	}

	/**
	 * isAccessible method checks if the current path access by the user
	 * is allowed based on the user's restrictions and privileges
	 * @param path string
	 * @return boolean
	 */
	public static isAccessible(path: string): boolean {
		const access = Boolean(Access.routes && Access.routes.includes(path));
		return access;
	}

	/**
	 * clearAccess method clears the currently accessed user from
	 * the system
	 */
	public static clearAccess(): void {
		Access.routes = null;
	}
}
