import React from 'react';
import { ToolbarButton } from '../../../resource/components';

interface IToolbar {
	onUpdateProfile: () => void;
	loading: boolean;
	disabled: boolean;
	onClose: () => void;
}

const Toolbar = (props: IToolbar) => (
	<div className="toolbar">
		<ToolbarButton
			onClick={props.onUpdateProfile}
			loading={props.loading}
			iconName="cloud upload"
			content="Update"
			disabled={props.disabled}
		/>
		<ToolbarButton onClick={props.onClose} iconName="close" content="Cancel" danger />
	</div>
);

export default Toolbar;
