import PropTypes from 'prop-types';
import React from 'react';
import ReactLocationPicker, { Location, Position } from 'react-location-picker';

interface Props {
	defaultPosition: Position;
	onChange: (data: Location) => void;
}

export const LocationPicker: React.FC<Props> = ({ defaultPosition, onChange }) => (
	<div style={{ margin: '1.2rem 0' }}>
		<ReactLocationPicker
			containerElement={<div style={{ width: '100%', height: '100%' }} />}
			mapElement={<div style={{ height: '400px' }} />}
			defaultPosition={defaultPosition}
			onChange={onChange}
			zoom={14}
			radius={-1}
		/>
	</div>
);

LocationPicker.propTypes = {
	defaultPosition: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired
	}).isRequired,
	onChange: PropTypes.func.isRequired
};
