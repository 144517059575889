import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header } from 'semantic-ui-react';
import { TextField, Text } from '../../resource/components';
import healthcare from '../../resource/assets/images/healthcare.svg';

export interface ISignInFormProps {
	password: string;
	username: string;
	disabled: boolean;
	active: boolean;
	onTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SignInForm = (props: ISignInFormProps) => {
	return (
		<form className="wrapper-form">
			<Header className="title form" textAlign="center">
				Sign In
			</Header>
			<img src={healthcare} alt="healthcare" className="signin-img" />
			<div className="fields">
				<TextField
					type="text"
					name="username"
					value={props.username}
					onChange={props.onTextChange}
					label="Enter your username"
				/>
				<TextField
					type="password"
					name="password"
					value={props.password}
					onChange={props.onTextChange}
					label="Enter your password"
				/>
			</div>
			<Text className="iw text">{''}</Text>
			<div className="btn-container">
				<Button
					className="btn"
					disabled={props.disabled}
					loading={props.active}
					onClick={props.onSubmit}
					type="submit"
				>
					Sign In
				</Button>
			</div>
		</form>
	);
};

SignInForm.propTypes = {
	password: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	active: PropTypes.bool.isRequired,
	onTextChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};
