import React from 'react';
import PropTypes from 'prop-types';
import './css/tools.css';

export interface ITextArea extends React.HTMLAttributes<HTMLTextAreaElement> {
	[key: string]: any;
	label?: string;
}

export const TextArea = ({ label, style, ...props }: ITextArea) => {
	const hasValue = props.value || props.placeholder !== undefined;
	return (
		<div className="textbox" style={style}>
			<textarea {...props} className={hasValue ? 'active' : ''} />
			<label className="textbox-label">{label}</label>
		</div>
	);
};

TextArea.propTypes = {
	label: PropTypes.string
};

TextArea.defaultProps = {
	label: ''
};
