import React, { ChangeEvent, SyntheticEvent, PureComponent, ReactNode, Fragment } from 'react';
import { Header, Select, DropdownProps } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Text, Selector } from '.';
import { InputField } from '../services';
import './css/tools.css';
import FormInput from './FormInput';
import LevelSelector from './LevelSelector';

export interface RFormProps {
	fields: Array<InputField>;
	onTextChange: (event: ChangeEvent<HTMLInputElement>) => void;
	name: string;
	title: string;
	description: string;
	onSelect: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
	showLevels?: boolean;
	onLevelSelect: (data: any) => void;
	onlyDistrict?: boolean;
	onlySubDistrict?: boolean;
	values: any;
}

export class AdvancedForm extends PureComponent<RFormProps> {
	public static defaultProps = {
		showLevels: false,
		onlyDistrict: false,
		onlySubDistrict: false
	};

	public static propTypes: PropTypes.InferProps<RFormProps> = {
		name: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		onTextChange: PropTypes.func.isRequired,
		onLevelSelect: PropTypes.func.isRequired,
		showLevels: PropTypes.bool,
		fields: PropTypes.arrayOf(
			PropTypes.shape<PropTypes.ValidationMap<InputField>>({
				type: PropTypes.string.isRequired,
				placeholder: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired
			})
		).isRequired,
		values: PropTypes.any
	};

	public render(): ReactNode {
		const { title, description, fields, showLevels } = this.props;
		return (
			<React.Fragment>
				<Header textAlign="center" className="header">
					{title}
				</Header>
				<Text style={{ margin: '1.2rem 0' }}>{description}</Text>
				{fields.map((input: InputField, _i: number) => {
					if (input.type === 'radio') {
						return <Fragment key={_i}>{this.renderSelector(input)}</Fragment>;
					}
					return (
						<Fragment key={_i}>
							{input.type === 'select'
								? this.renderSelect(input)
								: this.renderInput(input)}
						</Fragment>
					);
				})}
				{showLevels && (
					<LevelSelector
						onlyDistrict={this.props.onlyDistrict}
						onlySubDistrict={this.props.onlySubDistrict}
						showAll={showLevels && this.props.onlyDistrict ? false : true}
						name={this.props.name}
						onLevelSelect={this.props.onLevelSelect}
						value={this.props.values[this.props.name]}
					/>
				)}
			</React.Fragment>
		);
	}

	/**
	 * renderTextInput method renders a text input field
	 * @param input InputField
	 * @returns ReactNode
	 */
	public renderInput = (input: InputField): ReactNode => {
		const { type, placeholder, name } = input;
		const { values, name: key } = this.props;
		return (
			<FormInput
				type={type}
				label={placeholder}
				name={name}
				id={key}
				onChange={this.props.onTextChange}
				value={(values[key] && values[key][name]) || ''}
			/>
		);
	};

	/**
	 * renderSelectField method renders a select field form
	 * @param input InputField
	 * @returns ReactNode
	 */
	public renderSelect = (input: InputField): ReactNode => {
		return (
			<Select
				options={input.options || []}
				placeholder={input.placeholder}
				className="input"
				fluid
				onChange={this.props.onSelect}
				basic
				id={this.props.name}
				name={input.name}
			/>
		);
	};

	/**
	 * renderSelector renders the customized selector with
	 * each option for the input field
	 * @param input InputField
	 * @returns ReactNode
	 */
	public renderSelector = (input: InputField): ReactNode => {
		if (!input.options) {
			return null;
		}
		const { options } = input;
		const { values, name: key } = this.props;
		return (
			<Fragment>
				<Text>{input.placeholder}</Text>
				<div style={{ display: 'flex' }}>
					{options.map((option, _i: number) => (
						<Selector
							key={_i}
							label={option.text}
							name={input.name}
							value={option.value}
							onChange={this.props.onTextChange}
							id={key}
							checked={Boolean(
								values[key] && values[key][input.name] === option.value
							)}
						/>
					))}
				</div>
			</Fragment>
		);
	};
}
