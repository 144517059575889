import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { postConfirmation } from '../../../resource/services';
import { Sample } from '../../../resource/store';

type Status = {
	rider_id: string;
	sample_id: string;
	status: 'OK' | 'BAD';
};

const useStatusSubmit = () => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();

	useEffect(
		() => () => {
			setShow(false);
		},
		[]
	);

	const sendStatus = async (status: Status) => {
		setLoading(true);
		try {
			const res = await postConfirmation(status);
			dispatch({ type: Sample.SAMPLE_REQUEST_CONFIRM_SUCCESS, payload: res });
			setShow(true);
		} catch (error) {
			dispatch({ type: Sample.SAMPLE_REQUEST_CONFIRM_ERROR, payload: error });
		} finally {
			setLoading(false);
		}
	};

	return { loading, show, sendStatus, setShow };
};

export default useStatusSubmit;
