import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { AppStore } from '../../resource/store';
import './css/dashboard.css';
import { routes } from './routes';
import SideBar from './SideBar';
import UnauthenticatedUser from './UnauthenticatedUser';
import useLoadStore from './useLoadStore';
import { accessRoutes } from './_helpers';

interface Props extends RouteComponentProps {}

export const Dashboard = (props: Props) => {
	const sync = useLoadStore();
	const user = useSelector(({ user: { user } }: AppStore) => user);
	const dashboardRoutes = useMemo(() => accessRoutes(), []);
	const pathname = props.location.pathname;

	const renderRoutes = dashboardRoutes.map((route: RouteProps, _i: number) => (
		<Route key={_i} {...route} />
	));

	if (isEmpty(user)) {
		return <UnauthenticatedUser />;
	}

	return (
		<div className="dashboard">
			<SideBar routes={routes} url={pathname} />
			<div className="dashboard-content">
				<Switch>{renderRoutes}</Switch>
			</div>
			{sync && (
				<div className="sync">
					<Loader active size="small" />
				</div>
			)}
		</div>
	);
};

export default Dashboard;
