import { wrappedAdvancedListView, WrappedListViewOptions } from '../../../resource/hocs';
import { Constants } from '../../Clinics/_helpers';
import { deleteLabTest } from '../_helpers';
import LabTestDetail from './LabTestDetail';
import LabTestsList from './LabTestsList';
import LTToolBar from './ToolBar';

const options: WrappedListViewOptions = {
	title: 'Laboratory Test',
	form: Constants.app.routes.dashboard.LAB_TEST_ADD,
	description: 'Manage the sample tests offered by the laboratory',
	storeKey: 'test',
	stateKey: 'tests'
};

export default wrappedAdvancedListView(
	LabTestsList as any,
	LabTestDetail as any,
	LTToolBar as any,
	options,
	deleteLabTest
);
