import React, { CSSProperties } from 'react';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import signature from '../images/eric-signature.jpg';
import { getDecimalInput } from '../_helpers/utilities';
import { AdvancedTable, Fieldset, Input, Label, TextField, Title } from '../_shared/components';
import { ICellComponentProps } from '../_shared/components/AdvancedTable';
import { Column, Field, Row, Section } from './styles';

interface IProps {
	request: SAMPLES_RESPONSE_SCHEMA;
	lab: any;
}

export default function ClientSummary({ request }: IProps) {
	const total = request.lab_tests.reduce((acc, { lab_test }) => acc + lab_test.NHIS_FEE, 0);
	const tariff = getDecimalInput(total, 4);

	return (
		<Section>
			<Title content="CLIENT CLAIM SUMMARY*" />
			<Row gap={20}>
				<Column flex={2}>
					<AdvancedTable
						isNumbered={false}
						columns={[
							{ text: 'Type Of Service', value: 'typeOfService' },
							{ text: 'G-DRG/Code', value: 'gdrg', as: Code },
							{ text: 'Tariff Amount', value: 'tariff', as: Tariff }
						]}
						items={[
							{ typeOfService: 'Out-Patient', gdrg: '', tariff: '' },
							{ typeOfService: 'In-Patient', gdrg: '', tariff: '' },
							{ typeOfService: 'Investigations', gdrg: 'INVE', tariff: tariff },
							{ typeOfService: 'Pharmacy', gdrg: '', tariff: '' }
						]}
					/>
					<Row
						alignItems="center"
						justifyContent="space-between"
						padding={7}
						paddingRight="6%"
					>
						<Label>TOTAL</Label>
						<Input length={7} value={tariff} />
					</Row>
				</Column>
				<Column>
					<TextField
						label="Name"
						style={{ marginBottom: 20 }}
						inputStyling={{ height: 30, fontSize: '10pt', paddingTop: '4pt' }}
					>
						Eric Akosah
					</TextField>
					<TextField label="Signature" inputStyling={{ height: 50, paddingTop: '5pt' }}>
						<img src={signature} alt="signature" width={165} />
					</TextField>
				</Column>
			</Row>
			<SubTitle>Scheme Use Only</SubTitle>
			<Fieldset style={{ padding: 0 }}>
				<Row alignItems="center">
					<Row
						flexDirection="column"
						alignItems="flex-end"
						padding={8}
						borderRight="1px solid #000"
					>
						<Field>
							<Input
								required
								label="Date Received"
								length={10}
								placeholder="dd/mm/yyyy"
							/>
						</Field>
						<TextField
							label="Signed"
							horizontal
							inputStyling={{ height: 35, minWidth: '15em' }}
						/>
					</Row>
					<Column padding={8}>
						<Row gap={10} alignItems="center" marginBottom={7}>
							<TextField label="Action 1" horizontal />
							<TextField label="Date" horizontal />
							<TextField label="Signed" horizontal />
						</Row>
						<Row gap={10} alignItems="center">
							<TextField label="Action 2" horizontal />
							<TextField label="Date" horizontal />
							<TextField label="Signed" horizontal />
						</Row>
					</Column>
				</Row>
			</Fieldset>
		</Section>
	);
}

function SubTitle({ children }: React.PropsWithChildren<{}>) {
	const styling: CSSProperties = {
		margin: '0pt',
		marginBottom: '2pt',
		marginTop: '5pt',
		fontSize: '10pt',
		fontWeight: 'bold'
	};
	return <p style={styling}>{children}</p>;
}

function Code({ index, value }: ICellComponentProps) {
	if (index !== 1) return null;
	return <Input length={7} value={value} />;
}

function Tariff({ value }: ICellComponentProps) {
	return <Input length={7} value={value} />;
}
