// import isEmpty from 'lodash/isEmpty';
import {
	CLINIC_RESPONSE_SCHEMA,
	COURIER_RESPONSE_SCHEMA,
	LAB_RESPONSE_SCHEMA,
	MIDWIFE_RESPONSE_SCHEMA,
	NHIS_SCHEMA,
	REGION_RESPONSE_SCHEMA,
	SAMPLE_TYPE_RESPONSE_SCHEMA,
	SAMPLES_RESPONSE_SCHEMA,
	LAB_RESULT_RESPONSE_SCHEMA,
	LAB_TEST_RESPONSE_SCHEMA,
	convertRequestToListFormat,
	ServerRequest,
	getValuesFromKey
} from '../services';
import { Constants as K } from '../constants';
import { AppStore } from '../store';
import sampleTypes from './_data/sample.json';
// import Storage from './appStorageService';

const getDataFromStorage = async <P>(storename: string, url: string): Promise<P[] | null> => {
	try {
		// const storedData = await Storage.store<P>(storename).getAll();
		// if (!isEmpty(storedData)) {
		// 	return storedData;
		// }
		const fetchedData = await ServerRequest.get(url);
		const data = getValuesFromKey(fetchedData);
		// await Storage.store(storename).addAll(data);
		return data;
	} catch (error) {
		return null;
	}
};

export const getAllClinics = async (): Promise<Array<CLINIC_RESPONSE_SCHEMA> | null> => {
	return getDataFromStorage(K.app.db.CLINIC, K.api.routes.GET_CLINICS);
};

export const getLabCouriers = async (): Promise<COURIER_RESPONSE_SCHEMA[] | null> => {
	return getDataFromStorage(K.app.db.COURIER, K.api.routes.COURIERS);
};

export const getAllLabs = async (): Promise<Array<LAB_RESPONSE_SCHEMA> | null> => {
	return getDataFromStorage(K.app.db.LAB, K.api.routes.GET_LABS);
};

export const getMidwives = async (): Promise<MIDWIFE_RESPONSE_SCHEMA[] | null> => {
	return getDataFromStorage(K.app.db.MIDWIFE, K.api.routes.MIDWIVES);
};

export const getAllNHISScheme = async (data?: AppStore): Promise<Array<NHIS_SCHEMA> | null> => {
	return getDataFromStorage(K.app.db.SCHEME, K.api.routes.NHIS_SCHEMES);
};

export const getAllRegions = async (): Promise<Array<REGION_RESPONSE_SCHEMA> | null> => {
	return getDataFromStorage(K.app.db.REGION, K.api.routes.REGIONS);
};

export const getAllSampleTypes = async (): Promise<SAMPLE_TYPE_RESPONSE_SCHEMA[] | null> => {
	// const samples = await AsyncStorage.store(K.app.db.SAMPLE_TYPE).getAll();
	return sampleTypes;
};

export const getAllSamples = async (_?: any): Promise<SAMPLES_RESPONSE_SCHEMA[] | null> => {
	try {
		const data = await getDataFromStorage<SAMPLES_RESPONSE_SCHEMA>(
			K.app.db.REQUEST,
			K.api.routes.GET_LAB_REQUESTS
		);
		if (!data) return null;
		return convertRequestToListFormat(data);
	} catch (error) {
		return null;
	}
};

export const getLabTechnicians = async (): Promise<LAB_RESULT_RESPONSE_SCHEMA[] | null> => {
	return getDataFromStorage(K.app.db.TECHNICIAN, K.api.routes.GET_LAB_TECHNICIANS);
};

export const getAllTests = async (store?: AppStore): Promise<LAB_TEST_RESPONSE_SCHEMA[] | null> => {
	return getDataFromStorage(K.app.db.TEST, K.api.routes.GET_LAB_TESTS);
};
