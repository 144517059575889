import isEmpty from 'lodash/isEmpty';
import { InputField } from './typeService';
import { USER_SIGN_SCHEMA } from './schemeService';
import { Constants as K } from '../constants';

export class UserForm {
	public static userFields: Array<InputField> = [
		{
			type: 'text',
			name: 'first_name',
			placeholder: 'First name'
		},
		{
			type: 'text',
			name: 'surname',
			placeholder: 'Surname'
		},
		{
			type: 'text',
			name: 'middle_name',
			placeholder: 'Other name'
		},
		{
			type: 'date',
			name: 'dob',
			placeholder: 'Select date of birth'
		},
		{
			type: 'radio',
			name: 'gender',
			placeholder: 'Select the gender of the admin',
			options: [
				{
					value: 'M',
					text: 'Male'
				},
				{
					value: 'F',
					text: 'Female'
				}
			]
		},
		{
			type: 'tel',
			name: 'telephone',
			placeholder: 'Telephone number'
		},
		{
			type: 'text',
			name: 'username',
			placeholder: 'Enter username'
		},
		{
			type: 'password',
			name: 'password',
			placeholder: 'Enter password'
		}
	];

	/**
	 * _AUTH_FIELDS holds the authentication fields of the user's form
	 * @static _AUTH_FIELDS
	 */
	private static AUTH_FIELDS = ['password', 'username'];

	public static userDetails: Array<InputField> = UserForm.userFields.filter(
		(field: InputField) => !UserForm.AUTH_FIELDS.includes(field.name)
	);

	public static UUID = (): string => Date.now().toString(32);
}

type TStoreURL = { url: string; store: string };

interface IUserAccessible {
	PRIVILEGE_CLINIC_ADMIN: TStoreURL[];
	PRIVILEGE_SUPER_ADMIN: TStoreURL[];
	PRIVILEGE_LAB_ADMIN: TStoreURL[];
	PRIVILEGE_LAB_TECHNICIAN: TStoreURL[];
}

export const getDataURLFromUser = (user: USER_SIGN_SCHEMA) => {
	const route = K.api.routes;
	const table = K.app.db;
	const userAccessibleRoute: IUserAccessible = {
		PRIVILEGE_CLINIC_ADMIN: [{ url: route.MIDWIVES, store: table.MIDWIFE }],
		PRIVILEGE_SUPER_ADMIN: [
			{ url: route.REGIONS, store: table.REGION },
			{ url: route.NHIS_SCHEMES, store: table.SCHEME },
			{ url: route.GET_LAB_TESTS, store: table.TEST },
			{ url: route.GET_LABS, store: table.LAB },
			{ url: route.GET_CLINICS, store: table.CLINIC }
		],
		PRIVILEGE_LAB_ADMIN: [
			{ url: route.GET_LAB_TESTS, store: table.TEST },
			{ url: route.GET_LAB_TECHNICIANS, store: table.TECHNICIAN },
			{ url: route.GET_LAB_REQUESTS, store: table.REQUEST },
			{ url: route.COURIERS, store: table.COURIER }
		],
		PRIVILEGE_LAB_TECHNICIAN: [
			{ url: route.GET_LAB_REQUESTS, store: table.REQUEST },
			{ url: route.GET_LAB_TESTS, store: table.TEST },
			{ url: route.COURIERS, store: table.COURIER }
		]
	};
	const routes = userAccessibleRoute[user.privileges[0] as keyof IUserAccessible];
	return isEmpty(routes) ? [{ url: '', store: '' }] : routes;
};
