import isEmpty from 'lodash/isEmpty';
import { AppStore } from '../../../resource/store';

type TField = 'name' | 'telephone';

/**
 * the function get the user's location as a formatter
 * location address such as Bantama, Kumasi - Ashanti
 * if the user does not have a location, an empty string
 * is returned to prevent issues wtih react rendering
 * undefined values
 * @param user user
 */
export const getAddress = (user: AppStore['user']['user'], level = '') => {
	if (isEmpty(user) || !user) return '';
	const mappedLevelAddress: { [key: string]: string } = {
		district: 'district',
		region: 'region',
		'sub district': 'sub_district',
		community: 'community'
	};
	if (level) {
		const field = mappedLevelAddress[level];
		let dataToExtract: any = user;
		return dataToExtract.lab
			? dataToExtract.lab[field]
			: dataToExtract.clinic
			? dataToExtract.clinic[field]
			: dataToExtract.lab_technician
			? dataToExtract.lab_technician.lab[field]
			: '';
	}
	const work = user.lab || user.clinic || user.lab_technician.lab;
	const { district, region, sub_district } = work;
	return `${sub_district}, ${district} - ${region}`;
};

/**
 * the function returns the name of the user's workplace
 * such as the name of the clinic or the name of the laboratory
 * that the user works at
 * @param data user
 */
export const getWorkInfo = (data: AppStore['user']['user'], field: TField = 'name'): string => {
	if (isEmpty(data) || !data) return '';

	return data.lab
		? data.lab[field]
		: data.clinic
		? data.clinic[field]
		: data.lab_technician
		? data.lab_technician.lab[field]
		: '';
};
