import React from 'react';
import { Pagination } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import { LAB_TEST_RESPONSE_SCHEMA } from '../../../resource/services';
import { EmptyState, Widget } from '../../../resource/components';
import LabTestListView from './LabTestListView';
import { usePagination } from '../../../resource/hooks';
import { ListProps } from '../../../resource/hocs';

interface Props extends ListProps<LAB_TEST_RESPONSE_SCHEMA> {}

const LabTestsList = (props: Props) => {
	const { data, onSort, onSelect, sortedColumn, sortedDirection } = props;
	const {
		dataToRender,
		pageSize,
		dataSize,
		onPageChange,
		activePage,
		totalPages
	} = usePagination(data || []);

	if (isEmpty(data)) {
		return (
			<EmptyState
				title="No registered Lab test"
				description="Please there are no registered tests in the system. Click on the add button to add a new Lab test"
			/>
		);
	}
	return (
		<Widget>
			<LabTestListView
				onSort={onSort}
				onSelect={onSelect}
				sortedColumn={sortedColumn}
				sortedDirection={sortedDirection}
				activePage={activePage}
				pageSize={pageSize}
				dataToRender={dataToRender}
			/>
			{dataSize > pageSize && (
				<div className="pagination">
					<Pagination
						totalPages={totalPages}
						activePage={activePage}
						onPageChange={onPageChange}
						firstItem={null}
						lastItem={null}
					/>
				</div>
			)}
		</Widget>
	);
};

export default LabTestsList;
