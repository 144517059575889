import React from 'react';
import './css/tools.css';

export const Widget = ({ className = '', ...props }: React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<div {...props} className={`widget ${className}`.trim()}>
			{props.children}
		</div>
	);
};

export const TableWrap = (props: React.PropsWithChildren<{}>) => (
	<div className="responsive-table">{props.children}</div>
);
