import React from 'react';
import Label from './Label';

interface IProps {
	title?: string;
	required?: boolean;
	style?: React.CSSProperties;
}

export default function Fieldset({
	children,
	title,
	required,
	style
}: React.PropsWithChildren<IProps>) {
	return (
		<Container style={style}>
			{title && (
				<Label required={required} style={groupTitle}>
					{title}
				</Label>
			)}
			{children}
		</Container>
	);
}

function Container({ children, style = {} }: React.HTMLAttributes<HTMLDivElement>) {
	const styling: React.CSSProperties = {
		border: '1px solid #000',
		position: 'relative',
		padding: 12,
		marginBottom: 12,
		...style
	};
	return <div style={styling}>{children}</div>;
}

const groupTitle: React.CSSProperties = {
	position: 'absolute',
	top: -10,
	left: 12,
	margin: 0,
	background: '#fff'
};
