import PropTypes from 'prop-types';
import React from 'react';
import { Location } from 'react-location-picker';
import { DropdownProps, Select } from 'semantic-ui-react';
import { FormInput, LocationPicker } from '../../../resource/components';
import {
	COMMUNITY_RESPONSE_SCHEMA,
	convertListToDropdownItems,
	DISTRICT_RESPONSE_SCHEMA,
	REGION_RESPONSE_SCHEMA,
	SUB_DISTRICT_RESPONSE_SCHEMA
} from '../../../resource/services';

interface Props {
	name: string;
	telephone: string;
	pageNumber: number;
	activePage: number;
	districts: DISTRICT_RESPONSE_SCHEMA[];
	subDistricts: SUB_DISTRICT_RESPONSE_SCHEMA[];
	communities: COMMUNITY_RESPONSE_SCHEMA[];
	regionId: string;
	districtId: string;
	subDistrictId: string;
	communityId: string;
	regions: REGION_RESPONSE_SCHEMA[];
	location: { lat: number; lng: number };
	onChange: (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
	handleChange: (event: React.ChangeEvent<any>) => void;
	onLocationChange: (location: Location) => void;
}

const LabDetails: React.FC<Props> = props => {
	const { pageNumber, activePage, regionId, regions, location } = props;

	return (
		<div style={{ display: pageNumber === activePage ? 'block' : 'none' }}>
			<FormInput
				type="text"
				value={props.name}
				name="lab.name"
				placeholder="Enter laboratory name"
				onChange={props.handleChange}
				label="Laboratory name"
			/>
			<FormInput
				type="tel"
				value={props.telephone}
				name="lab.telephone"
				placeholder="Enter telephone of laboratory"
				onChange={props.handleChange}
				label="Phone number"
			/>
			<Select
				options={convertListToDropdownItems(regions)}
				fluid
				placeholder="Select a region"
				value={regionId}
				onChange={props.onChange}
				name="region"
			/>
			<Select
				options={convertListToDropdownItems(props.districts)}
				fluid
				placeholder="Select district"
				value={props.districtId}
				onChange={props.onChange}
				name="district"
			/>
			<Select
				options={convertListToDropdownItems(props.subDistricts)}
				fluid
				placeholder="Select sub district"
				value={props.subDistrictId}
				onChange={props.onChange}
				name="subDistrict"
			/>
			<Select
				options={convertListToDropdownItems(props.communities)}
				fluid
				placeholder="Select the laboratory's district"
				value={props.communityId}
				onChange={props.onChange}
				name="community"
			/>
			<LocationPicker defaultPosition={location} onChange={props.onLocationChange} />
		</div>
	);
};

LabDetails.propTypes = {
	name: PropTypes.string.isRequired,
	telephone: PropTypes.string.isRequired,
	onLocationChange: PropTypes.func.isRequired,
	location: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired
	}).isRequired
};

export default LabDetails;
