import { Dispatch, USER_SIGN_SCHEMA } from '../../services';

export const Profile = {
	STORE_USER_TYPE: '@store/store-user-profile',
	CLEAR_ERROR_TYPE: '@store/clear-user-profile-error',
	STORE_ERROR_TYPE: '@store/processing-user-profile-error',
	UPDATE_USER_SUCCESS: '@@api/update-user-profile-success',
	CLEAR_UPDATE_TYPE: '@store/clear-profile-update-state'
};

export interface ProfileState {
	profile: { user: USER_SIGN_SCHEMA | null } | null;
	error: any;
	update: any;
}

const defaultState: ProfileState = {
	profile: null,
	error: null,
	update: null
};

export default (state = defaultState, action: Dispatch) => {
	switch (action.type) {
		case Profile.STORE_USER_TYPE:
			return { ...state, ...defaultState, profile: action.payload };

		case Profile.CLEAR_ERROR_TYPE:
			return { ...state, error: null };

		case Profile.STORE_ERROR_TYPE:
			return { ...state, error: action.payload };

		case Profile.UPDATE_USER_SUCCESS:
			return { ...state, update: action.payload };

		case Profile.CLEAR_UPDATE_TYPE:
			return { ...state, update: null, error: null };

		default:
			return state;
	}
};
