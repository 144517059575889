import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadTestResult } from '../../../resource/services';
import { Sample } from '../../../resource/store';

const useResultUpload = () => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();

	const uploadResult = async (data: any) => {
		try {
			setLoading(true);
			const res = await uploadTestResult(data);
			setMessage('Test results uploaded successfully');
			setShow(true);
			dispatch({
				type: Sample.LAB_REQUEST_RESPONSE_SUCCESS,
				payload: res.sample
			});
		} catch (error) {
			dispatch({
				type: Sample.LAB_REQUEST_RESPONSE_FAIL,
				payload: (error.error && error.error.message) || error.message
			});
		} finally {
			setLoading(false);
		}
	};

	return {
		loading,
		message,
		show,
		uploadResult,
		setShow
	};
};

export default useResultUpload;
