import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import {
	Alert,
	Container,
	EmptyState,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Text,
	Widget,
} from '../../../resource/components';
import { Constants } from '../../../resource/constants';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore, Sample } from '../../../resource/store';
import './css/labresult.css';
import RequestInfo from './RequestInfo';
import TestResult from './TestResult';
import Toolbar from './Toolbar';
import useResultForm from './useResultForm';
import useResultUpload from './useResultUpload';

type TestResult = {
	test_id: string;
	result?: string;
	custom_result?: string;
};

type Props = RouteComponentProps & {};

const LabResult = (props: Props) => {
	const [testResults, setTestResults] = useState<TestResult[]>([]);
	const user = useSelector(({ user }: AppStore) => user.user);
	const sample = useSelector(({ sample }: AppStore) => sample);
	const { error, selectedRequest } = sample;
	const { reason, status, ccc, labRecordNo, setValue } = useResultForm({
		reason: '',
		status: (selectedRequest && selectedRequest.status) || 'OK',
		ccc: (selectedRequest && selectedRequest.ccc) || '',
		labRecordNo: (selectedRequest && selectedRequest.sample_code) || '',
	});
	const { loading, message, show, uploadResult, setShow } = useResultUpload();
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedRequest) {
			const results = selectedRequest.lab_tests.map((test) => {
				const { id, lab_result, lab_test } = test;
				const { possible_results } = lab_test;
				const value = { test_id: id } as TestResult;
				if (isEmpty(possible_results)) {
					value.custom_result = lab_result || '';
				} else {
					value.result = lab_result;
				}
				return value;
			});
			setTestResults(results);
		}
	}, [selectedRequest]);

	const onSelectTestResult = (e: any) => {
		const { name: id, value } = e.target;
		const oldTestResults = [...testResults];
		const index = oldTestResults.findIndex((testResult) => testResult.test_id === id);
		if (index === -1) {
			oldTestResults.push({ test_id: id, result: value });
		} else {
			oldTestResults[index].result = value;
		}
		setTestResults(oldTestResults);
	};

	const onCustomTestResult = (e: any) => {
		const { name: id, value } = e.target;
		const oldTestResults = [...testResults];
		const index = oldTestResults.findIndex((testResult) => testResult.test_id === id);
		if (index === -1) {
			oldTestResults.push({ test_id: id, custom_result: value });
		} else {
			oldTestResults[index].custom_result = value;
		}
		setTestResults(oldTestResults);
	};

	const onSubmitResult = (request: SAMPLES_RESPONSE_SCHEMA) => {
		const labResults = {
			sample_id: request.id,
			lab_record_number: labRecordNo,
			ccc,
			tests: testResults
				.filter((test) => Boolean(test.test_id))
				.map((test) => {
					if (test.custom_result) {
						const { result, ...data } = test;
						return data;
					}
					if (test.result) {
						const { custom_result, ...data } = test;
						return data;
					}
					return test;
				}),
		};
		uploadResult(labResults);
	};

	const renderContent = (request: SAMPLES_RESPONSE_SCHEMA) => {
		return (
			<Widget className="lab-result">
				<section className="result">
					<RequestInfo
						onStatusSelect={setValue}
						onReasonChange={setValue}
						reason={reason}
						request={request}
						status={status}
					/>
					<TestResult
						onClaimsCheckCodeChange={setValue}
						onCustomResult={onCustomTestResult}
						onLabRecordNoChange={setValue}
						onSelectTestResult={onSelectTestResult}
						request={request}
						labRecordNo={labRecordNo}
						claimsCheckCode={ccc}
					/>
					<div style={{ textAlign: 'center', margin: '1.35rem 0' }}>
						<Text warning>
							Please ensure that all required fields are set with the accurate results
							before submitting the result. Once the form is submitted it cannot be
							changed.
						</Text>
						{request.status !== 'BAD' && (
							<Button
								loading={loading}
								disabled={loading}
								className="submit"
								onClick={() => onSubmitResult(request)}
							>
								Submit
							</Button>
						)}
					</div>
				</section>
				<Alert
					title="Lab Result"
					message={message}
					open={show}
					onConfirmed={() => {
						setShow(false);
						props.history.replace(Constants.app.routes.dashboard.SAMPLE_REQUESTS);
					}}
				/>
			</Widget>
		);
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<ErrorHandler
				onExit={() => {
					dispatch({ type: Sample.CLEAR_ERROR_STATE });
				}}
				error={error}
			>
				<MainContent
					title="Lab Result"
					description="Lab results for sample request"
					toolbar={<Toolbar />}
				>
					<Container>
						{!selectedRequest ? (
							<EmptyState
								title="No Sample Request"
								description="No sample request has been selected. Please return to the samples page."
							/>
						) : (
							renderContent(selectedRequest)
						)}
					</Container>
				</MainContent>
			</ErrorHandler>
		</RestrictedAccess>
	);
};

export default LabResult;
