import { Constants as SharedConstants } from '../../../resource/constants';

export const Constants = {
	...SharedConstants,
	levels: {
		DISTRICT: 'district',
		SUB_DISTRICT: 'sub_district',
		COMMUNITY: 'community',
		REGION: 'region'
	}
};
