import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import './css/sidebar.css';
import { Route, Router } from './routes';
import { Access } from '../../resource/services';
import { Constants as K } from '../../resource/constants';

interface Props {
	routes: Array<Route>;
	url: string;
}

const Sidebar = (props: Props) => {
	const [isAccessible, setAccessible] = useState(false);
	const { url, routes } = props;

	useEffect(() => {
		const hasAccess = Access.isAccessible(url);
		setAccessible(hasAccess);
	}, [url]);

	const renderLink = (route: Router, val: number, index: number) => {
		const { path } = route;
		const dashboardRoute = K.app.routes.dashboard.DASHBOARD;
		const condition =
			(url === path || val !== -1) && (path !== dashboardRoute || url === dashboardRoute);
		const className = `link ${condition ? 'active' : ''}`;
		const isAccessible = Access.isAccessible(path);
		return (
			<React.Fragment key={index}>
				{isAccessible && (
					<Popup
						trigger={
							<Link to={String(route.path)} className={className}>
								<Icon name={route.icon} />
							</Link>
						}
						content={route.name}
						position="right center"
						basic
					/>
				)}
			</React.Fragment>
		);
	};

	return isAccessible ? (
		<div className="sidebar collapse">
			<div className="wrapper">
				{routes.map((route: Router, index: number) => {
					let val: number = -1;
					if (route.subComponents !== null && Array.isArray(route.subComponents)) {
						val = route.subComponents.findIndex((value: Route) =>
							url.startsWith(value.path)
						);
					}
					return renderLink(route, val, index);
				})}
			</div>
		</div>
	) : null;
};

export default React.memo(Sidebar);
