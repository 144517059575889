import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import { Selector, Text, TextArea } from '../../../resource/components';
import {
	convertToReadableDate,
	getFullName,
	SAMPLES_RESPONSE_SCHEMA
} from '../../../resource/services';

type Props = {
	request: SAMPLES_RESPONSE_SCHEMA;
	reason: string;
	onReasonChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
	onStatusSelect: (e: React.FormEvent<HTMLInputElement>) => void;
	status: SAMPLES_RESPONSE_SCHEMA['status'];
};

const RequestInfo = (props: Props) => {
	const { request, onReasonChange, onStatusSelect, reason, status } = props;
	return (
		<>
			<Header className="title">Lab Result</Header>
			<Divider className="content" />
			<Text className="list-item">
				<span>Date of receiving sample</span>
				{convertToReadableDate(request.date_of_delivery)}
			</Text>
			<Text className="list-item">
				<span>Time of receiving sample</span>
				{convertToReadableDate(request.date_of_delivery, false, true)}
			</Text>
			<Text className="list-item">
				<span>Sample delivered by</span>
				{getFullName(request.rider)}
			</Text>
			<Text className="list-item">
				<span>Patient Name</span>
				{getFullName(request.patient)}
			</Text>
			<Text>Is sample of sufficient quality *</Text>
			<div className="list">
				<Selector
					defaultChecked={Boolean(status === 'OK')}
					label="Yes"
					name="status"
					value="OK"
					onChange={onStatusSelect}
				/>
				<Selector
					defaultChecked={Boolean(status === 'BAD')}
					label="No"
					name="status"
					value="BAD"
					onChange={onStatusSelect}
				/>
			</div>
			<Text>
				If no, specify why sample is not sufficient and call midwife to collect new sample
			</Text>
			<TextArea
				label="Specify why sample not sufficient"
				name="reason"
				value={reason}
				onChange={onReasonChange}
			/>
		</>
	);
};

RequestInfo.propTypes = {
	request: PropTypes.object.isRequired,
	reason: PropTypes.string.isRequired,
	onReasonChange: PropTypes.func.isRequired,
	onStatusSelect: PropTypes.func.isRequired,
	status: PropTypes.oneOf(['OK', 'BAD', 'PENDING', 'PARTIAL_PENDING']).isRequired
};

export default RequestInfo;
