import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Direction, ListViewProps } from '../../../resource/hocs';
import { TableWrap } from '../../../resource/components';
import { LAB_TEST_RESPONSE_SCHEMA, SAMPLE_TYPE_RESPONSE_SCHEMA } from '../../../resource/services';

const LabTestListView = (props: ListViewProps<LAB_TEST_RESPONSE_SCHEMA>) => (
	<TableWrap>
		<Table basic="very" sortable selectable>
			<Table.Header>
				<Table.Row style={{ backgroundColor: 'teal' }}>
					<Table.HeaderCell style={{ ...styles.header, ...styles.cell, width: '1.7rem' }}>
						#
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('name')}
						sorted={props.sortedColumn === 'name' ? props.sortedDirection : undefined}
					>
						Name
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('NHIS_CODE')}
						sorted={
							props.sortedColumn === 'NHIS_CODE' ? props.sortedDirection : undefined
						}
					>
						NHIS CODE
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('GDRG_CODE')}
						sorted={
							props.sortedColumn === 'GDRG_CODE' ? props.sortedDirection : undefined
						}
					>
						GDRG CODE
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('NHIS_FEE')}
						sorted={
							props.sortedColumn === 'NHIS_FEE' ? props.sortedDirection : undefined
						}
					>
						NHIS fee
					</Table.HeaderCell>
					<Table.HeaderCell
						style={{ ...styles.header, ...styles.cell }}
						onClick={() => props.onSort('CASH_FEE')}
						sorted={
							props.sortedColumn === 'CASH_FEE' ? props.sortedDirection : undefined
						}
					>
						Cash fee
					</Table.HeaderCell>
					<Table.HeaderCell style={{ ...styles.header, ...styles.cell }}>
						Sample Type
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{props.dataToRender.map((item: LAB_TEST_RESPONSE_SCHEMA, i: number) => {
					return (
						<Table.Row key={item.id} onClick={() => props.onSelect(item)}>
							<Table.Cell>
								{((props.activePage as number) - 1) * props.pageSize + i + 1}
							</Table.Cell>
							<Table.Cell style={{ ...styles.cell, width: '15.5rem' }}>
								{item.name}
							</Table.Cell>
							<Table.Cell style={styles.cell}>{item.NHIS_CODE}</Table.Cell>
							<Table.Cell style={styles.cell}>{item.GDRG_CODE}</Table.Cell>
							<Table.Cell style={styles.cell}>{item.NHIS_FEE.toFixed(2)}</Table.Cell>
							<Table.Cell style={styles.cell}>{item.CASH_FEE.toFixed(2)}</Table.Cell>
							<Table.Cell style={styles.cell}>
								{item.sample_type.description}
							</Table.Cell>
						</Table.Row>
					);
				})}
			</Table.Body>
		</Table>
	</TableWrap>
);

const styles: { [key: string]: React.CSSProperties } = {
	header: {
		fontWeight: 300,
		fontFamily: 'Open sans',
		fontSize: '1.14rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '0.85rem 1rem'
	}
};

LabTestListView.propTypes = {
	onSort: PropTypes.func.isRequired,
	sortedColumn: PropTypes.string.isRequired,
	sortedDirection: PropTypes.oneOf<Direction>(['ascending', 'descending']).isRequired,
	onSelect: PropTypes.func.isRequired,
	dataToRender: PropTypes.arrayOf(
		PropTypes.shape<PropTypes.ValidationMap<LAB_TEST_RESPONSE_SCHEMA>>({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			NHIS_CODE: PropTypes.string.isRequired,
			GDRG_CODE: PropTypes.string.isRequired,
			NHIS_FEE: PropTypes.number.isRequired,
			CASH_FEE: PropTypes.number.isRequired,
			possible_results: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.string),
				PropTypes.any
			]),
			sample_type: PropTypes.shape<PropTypes.ValidationMap<SAMPLE_TYPE_RESPONSE_SCHEMA>>({
				id: PropTypes.any,
				description: PropTypes.string.isRequired
			}).isRequired
		})
	),
	activePage: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired
};

export default LabTestListView;
