import React from 'react';
import Label from './Label';

type IProps = {
	label: string;
	horizontal?: boolean;
	inputStyling?: React.CSSProperties;
	style?: React.CSSProperties;
};

export default function TextField({
	label,
	horizontal,
	inputStyling = {},
	style = {},
	children
}: React.PropsWithChildren<IProps>) {
	return (
		<Container horizontal={horizontal} style={style}>
			<StyledLabel>{label}</StyledLabel>
			<InputField style={inputStyling}>{children}</InputField>
		</Container>
	);
}

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & { horizontal?: boolean };
function Container({ children, horizontal, style = {} }: ContainerProps) {
	const styling: React.CSSProperties = { gap: '8pt', textAlign: 'center' };
	if (horizontal) Object.assign(styling, horizontalStyling);
	return <div style={{ ...styling, ...style }}>{children}</div>;
}

function InputField({ style = {}, children }: React.HTMLAttributes<HTMLDivElement>) {
	const styling = {
		height: '15pt',
		minWidth: '50pt',
		flex: 1,
		border: '1px solid #000',
		...style
	};
	return <div style={styling}>{children}</div>;
}

function StyledLabel({ children }: React.HTMLAttributes<HTMLLabelElement>) {
	return <Label style={{ margin: 0 }}>{children}</Label>;
}

const horizontalStyling: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center'
};
