import {
	REGION_RESPONSE_SCHEMA,
	Dispatch as Action,
	Dispatcher,
	ServerRequest
} from '../../../resource/services';
import { Level, Constants as K } from '.';
import { Region } from '../../../resource/store';

/**
 * storeRegionInState is an action creator which store the region
 * in the region state of the app store
 * @param region REGION_RESPONSE_SCHEMA
 * @returns Action
 */
export const storeRegionInState = (region: REGION_RESPONSE_SCHEMA): Action => {
	return {
		type: Region.STORE_REGION_IN_STORE,
		payload: region
	};
};

/**
 * setFormToRegion sets the regional state in the app store to
 * region which the levelsForm will render a region form
 * @return Action
 */
export const setFormToRegion = (): Action => {
	return {
		type: Region.SET_FORM_REGION
	};
};

/**
 * clearLevelState is an action creator which clears the
 * level state in the app store
 * @returns Action
 */
export const clearLevelState = (): Action => ({
	type: Region.CLEAR_FORM_STATE
});

/**
 * submitLevel is an action creator which submits the level state
 * to the server. the submission of the form depends on the
 * level that the data is in such as if the places where districts,
 * a region id is need and the level too is needed to detect it
 * @returns Promise<void>
 */
export const submitLevel = (places: Array<{ name: string }>) => async (
	dispatch: Dispatcher
): Promise<void> => {
	const { ADD_REGIONS } = K.api.routes;
	try {
		const res = await ServerRequest.uploadService(ADD_REGIONS, { regions: places });
		dispatch({
			type: Region.ADD_LEVEL_SUCCESS,
			payload: res
		});
	} catch (error) {
		dispatch({
			type: Region.ADD_LEVEL_FAIL,
			payload: error
		});
	}
};

/**
 * clearErrorState is an action creator which clears the
 * error prop of the regional state in the app store
 * @returns Action
 */
export const clearErrorState = (): Action => ({
	type: Region.CLEAR_ERROR_STATE
});

export const storeLevel = (id: string, level: Level, region?: any): Action => {
	switch (level) {
		case K.levels.DISTRICT:
			return { type: Region.SET_FORM_DISTRICT, payload: { id, region } };

		case K.levels.SUB_DISTRICT:
			return { type: Region.SET_FORM_SUB_DISTRICT, payload: { id, region } };

		case K.levels.COMMUNITY:
			return { type: Region.SET_FORM_COMMUNITY, payload: { id, region } };

		default:
			return { type: Region.SET_FORM_REGION };
	}
};

export const onUpdateArea = (data: any, region: any) => async (dispatch: Dispatcher) => {
	try {
		const { url, method, ...other } = data;
		const res = await ServerRequest.uploadService(url, other, true, method);
		let updatedRegion = { ...(region as REGION_RESPONSE_SCHEMA) };
		if (method === 'POST') {
			if (res.region) {
				updatedRegion = res.region;
			}
			if (res.district) {
				updatedRegion.districts = updatedRegion.districts.map(district =>
					district.id === res.district.id ? res.district : district
				);
			}
			if (res.sub_districts) {
				updatedRegion.districts = updatedRegion.districts.map(district => {
					district.sub_districts = district.sub_districts.map(sub =>
						sub.id === res.sub_districts.id ? res.sub_districts : sub
					);
					return district;
				});
			}
		}
		if (method === 'PUT') {
			if (res.districts) {
				const { districts } = res;
				updatedRegion.districts = updatedRegion.districts.map(district =>
					district.id === districts.id ? res.districts : district
				);
			}
			if (res.sub_districts) {
				const { sub_districts } = res;
				updatedRegion.districts = updatedRegion.districts.map(district => {
					district.sub_districts = district.sub_districts.map(sub =>
						sub.id === sub_districts.id ? sub_districts : sub
					);
					return district;
				});
			}
			if (res.community) {
				const { community } = res;
				updatedRegion.districts = updatedRegion.districts.map(district => {
					district.sub_districts = district.sub_districts.map(sub => {
						sub.communities = sub.communities.map(comm =>
							comm.id === community.id ? community : comm
						);
						return sub;
					});
					return district;
				});
			}
		}
		dispatch({
			type: Region.ADD_LEVEL_SUCCESS,
			payload: updatedRegion
		});
	} catch (error) {
		dispatch({
			type: Region.ADD_LEVEL_FAIL,
			payload: error
		});
	}
};
