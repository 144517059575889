import { useEffect, useRef, useState } from 'react';
import { generateExcelSheetFromTable, generatePdfFromTable } from '../services';

interface Options {
	htmlElementId: string;
	fileName: string;
}

const useDownload = (options: Options) => {
	const [downloading, setDownloading] = useState(false);
	const { htmlElementId, fileName } = options;
	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	const downloadAsExcel = () => {
		setDownloading(true);
		generateExcelSheetFromTable(htmlElementId, fileName + '.xlsx');
		if (mounted.current) {
			setDownloading(false);
		}
	};

	const downloadTableAsPdf = (configs: any) => {
		setDownloading(true);
		generatePdfFromTable(htmlElementId, fileName + '.pdf', configs);
		if (mounted.current) {
			setDownloading(false);
		}
	};

	return {
		downloading,
		downloadAsExcel,
		downloadTableAsPdf
	};
};

export default useDownload;
