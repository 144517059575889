import { RouteProps } from 'react-router-dom';
import { Constants } from '../resource/constants';
import Dashboard from './Dashboard';
import Landing from './Landing';
import PrivacyPolicy from './PrivacyPolicy';
import SignIn from './SignIn';

const {
	app: { routes: route }
} = Constants;

type Routes = RouteProps & { name?: string };

export const routes: Array<Routes> = [
	{
		exact: true,
		path: route.HOME,
		component: Landing,
		name: 'Home'
	},
	{
		exact: true,
		path: route.SIGNIN,
		component: SignIn
	},
	{
		exact: false,
		path: route.dashboard.DASHBOARD,
		component: Dashboard
	},
	{
		exact: true,
		path: route.PRIVACY_POLICY,
		component: PrivacyPolicy,
		name: 'Privacy Policy'
	}
];

export const pagesRoutes = routes
	.filter(route => route.name)
	.map(route => ({ name: route.name || '', path: route.path as string }));
