import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import {
	AdvancedForm,
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Widget
} from '../../../resource/components';
import { AppStore } from '../../../resource/store';
import { clearProfileError, clearProfileUpdateState, Constants as K } from '../_helpers';
import Toolbar from './Toolbar';

interface IWorkProfile extends RouteComponentProps {
	user: AppStore['user']['user'];
	error: any;
	profile: AppStore['profile']['profile'];
	update: any;
	clearProfileError: () => void;
	clearProfileUpdateState: () => void;
}

interface IState {
	[key: string]: any;
	level: any;
	loading: boolean;
	showStatus: boolean;
}

function WorkProfile(props: IWorkProfile) {
	const { profile, update, clearProfileUpdateState, clearProfileError, history, error } = props;
	const [state, setState] = useState<Record<string, any>>({
		level: {},
		loading: false,
		showStatus: false
	});

	useEffect(() => {
		if (!profile) return;
		setState((prevState) => ({ ...prevState, ...profile }));
	}, [profile]);

	useEffect(() => {
		if (update)
			setState((prevState) => ({
				...prevState,
				showStatus: Boolean(update),
				loading: false
			}));
	}, [update]);

	const onExitError = () => {
		clearProfileError();
	};

	const onFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const target = event.target;
		setState((prevState) => {
			const data = { ...prevState[target.id], [target.name]: target.value };
			return { ...prevState, [target.id]: data };
		});
	};

	const onLevelSelect = (data: any): void => {
		setState((prevState) => ({ ...prevState, level: { ...prevState.level, ...data } }));
	};

	const onCancel = () => {
		clearProfileUpdateState();
		history.goBack();
	};

	const onUpdateProfile = () => {};

	return (
		<ErrorHandler error={error} onExit={onExitError}>
			<RestrictedAccess {...props} user={props.user}>
				<MainContent
					title="Profile"
					description="Manage your work place profile"
					toolbar={
						<Toolbar
							onUpdateProfile={onUpdateProfile}
							loading={state.loading}
							disabled={!Boolean(state.clinic || state.lab)}
							onClose={onCancel}
						/>
					}
				>
					<Container>
						<Widget>
							<Switch>
								{K.work.map((form, i) => (
									<Route
										key={i}
										exact
										path={form.path}
										render={() => (
											<AdvancedForm
												fields={form.fields}
												name={form.name}
												title={form.title}
												description={form.description}
												onTextChange={onFormInput}
												onSelect={() => {}}
												onLevelSelect={onLevelSelect}
												values={state}
												showLevels={form.showLevels}
											/>
										)}
									/>
								))}
							</Switch>
						</Widget>
					</Container>
					<Alert
						success
						open={state.showStatus}
						onClose={onCancel}
						title="Updated"
						message="Your profile has been updated successfully."
					/>
				</MainContent>
			</RestrictedAccess>
		</ErrorHandler>
	);
}

const mapStateToProps = (state: AppStore) => ({
	error: state.profile.error,
	update: state.profile.update,
	profile: state.profile.profile,
	user: state.user.user
});

const mapDispatchToProps = {
	clearProfileError,
	clearProfileUpdateState
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkProfile);
