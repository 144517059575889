import isEmpty from 'lodash/isEmpty';
import {
	LAB_TEST_RESPONSE_SCHEMA,
	ServerRequest,
	Dispatcher,
	Dispatch
} from '../../../resource/services';
import { Constants as K } from '../../../resource/constants';
import { updateDataStore } from '../../../resource/dataService';
import { LabTest } from '../../../resource/store';

/**
 * submitLabTest sends the form to the server to add a new lab test
 * to the system
 * @param data LAB_TEST_CREATION_SCHEMA
 */
export const submitLabTest = (data: any) => async (dispatch: Dispatcher) => {
	try {
		const url = {
			POST: K.api.routes.ADD_LAB_TEST,
			PUT: K.api.routes.UPDATE_LAB_TEST
		};
		const { test, results, sampleType } = data;
		if (isEmpty(results)) {
			results.push({ value: '' });
		}
		const labTest: any = {
			name: test.name,
			CASH_FEE: parseFloat(test.CASH_FEE),
			NHIS_FEE: parseFloat(test.NHIS_FEE),
			GDRG_CODE: test.GDRG_CODE,
			NHIS_CODE: test.NHIS_CODE,
			sample_type: {
				description: sampleType
			},
			possible_results: results.map((result: any) => result.value)
		};
		const method = !isEmpty(data.test.id) ? 'PUT' : 'POST';
		if (method === 'PUT') {
			labTest.id = data.test.id;
		}
		const res = await ServerRequest.uploadService(url[method], labTest, true, method);
		// connect to database
		updateDataStore(method, K.app.db.TEST, res);
		dispatch({
			type: LabTest.LAB_TEST_ADD_SUCCESS,
			payload: res.test || res
		});
	} catch (error) {
		dispatch({
			type: LabTest.LAB_TEST_ADD_FAIL,
			payload: error
		});
	}
};

/**
 * storeLabTestInStore is a factory function or an action creator which
 * stores the response data from the server in the app store which can
 * be shown in the lab test lists
 * @param data LAB_TEST_RESPONSE_SCHEMA
 */
export const storeLabTestInStore = (data: LAB_TEST_RESPONSE_SCHEMA) => ({
	type: LabTest.ADD_LAB_TEST_TO_STORE_TYPE,
	payload: data
});

/**
 * loadNewLabTests loads the lab tests from the server and loads it
 * into the app store lab tests data which then the data is stored
 * in local storage.
 */
export const loadNewLabTests = () => async (dispatch: Dispatcher) => {
	try {
		const tests = await ServerRequest.get(K.api.routes.GET_LAB_TESTS);
		dispatch({
			type: LabTest.STORE_LAB_TESTS_TYPE,
			payload: tests.lab_tests
		});
	} catch (error) {
		dispatch({
			type: LabTest.LAB_TEST_ADD_FAIL,
			payload: error
		});
	}
};

/**
 * clearLabTestState is an action creator which clears the lab
 * test state in the app store
 */
export const clearLabTestState = (): Dispatch => ({
	type: LabTest.CLEAR_LAB_TEST_SCHEME_TYPE
});
