import React, { useEffect, useReducer } from 'react';
import { ToolbarButton, Widget } from '../../../resource/components';
import {
	COMMUNITY_RESPONSE_SCHEMA,
	DISTRICT_RESPONSE_SCHEMA,
	REGION_RESPONSE_SCHEMA,
	SUB_DISTRICT_RESPONSE_SCHEMA
} from '../../../resource/services';
import { Constants as K } from '../_helpers';
import AreaLevel from './AreaLevel';
import './css/regionoverview.css';
import './css/style.css';
import LevelItem from './LevelItem';

enum Level {
	DISTRICT,
	SUB_DISTRICT,
	COMMUNITY
}

type Props = {
	region: REGION_RESPONSE_SCHEMA;
	onUpdateRegion: (data: any) => void;
};

type State = {
	subDistricts: SUB_DISTRICT_RESPONSE_SCHEMA[];
	communities: COMMUNITY_RESPONSE_SCHEMA[];
	district: DISTRICT_RESPONSE_SCHEMA | null;
	subDistrict: SUB_DISTRICT_RESPONSE_SCHEMA | null;
	areaLevelTitle: string;
	areaLevelValue: string;
	showAreaLevel: boolean;
	postData: any;
	selectedLevel: Level;
	loading: boolean;
};

const initialState: State = {
	subDistricts: [],
	communities: [],
	district: null,
	subDistrict: null,
	areaLevelTitle: '',
	areaLevelValue: '',
	showAreaLevel: false,
	postData: {},
	selectedLevel: Level.DISTRICT,
	loading: false
};

const OverviewActionTypes = {
	SET_SUB_DISTRICTS: 'SET_SUB_DISTRICTS',
	SET_COMMUNITIES: 'SET_COMMUNITIES',
	ADD_AREA_LEVEL: 'ADD_AREA_LEVEL',
	CLEAR_AREA_LEVEL: 'CLEAR_AREA_LEVEL',
	PROCESSING_REGION: 'PROCESSING_REGION',
	REGION_PROCESS_COMPLETE: 'REGION_PROCESS_COMPLETE'
};

const overviewReducer = (state: State, { type, payload }: any): State => {
	switch (type) {
		case OverviewActionTypes.SET_SUB_DISTRICTS:
			return {
				...state,
				...initialState,
				subDistricts: payload.subDistricts,
				district: payload.district
			};

		case OverviewActionTypes.SET_COMMUNITIES:
			return { ...state, communities: payload.communities, subDistrict: payload.subDistrict };

		case OverviewActionTypes.ADD_AREA_LEVEL:
			return {
				...state,
				areaLevelTitle: payload.title,
				areaLevelValue: payload.value,
				showAreaLevel: true,
				selectedLevel: payload.selectedLevel,
				postData: payload.postData
			};

		case OverviewActionTypes.CLEAR_AREA_LEVEL:
			return { ...state, areaLevelTitle: '', areaLevelValue: '', showAreaLevel: false };

		case OverviewActionTypes.PROCESSING_REGION:
			return { ...state, loading: true };

		case OverviewActionTypes.REGION_PROCESS_COMPLETE:
			return {
				...state,
				loading: false,
				areaLevelTitle: '',
				areaLevelValue: '',
				showAreaLevel: false
			};

		default:
			return state;
	}
};

const Overview = (props: Props) => {
	const [state, dispatch] = useReducer(overviewReducer, initialState);
	const {
		subDistricts,
		communities,
		district: selectedDistrict,
		subDistrict: selectedSubDistrict,
		areaLevelTitle,
		areaLevelValue,
		showAreaLevel,
		postData,
		selectedLevel,
		loading
	} = state;
	const { region } = props;
	const { districts } = props.region;

	useEffect(() => {
		dispatch({ type: OverviewActionTypes.REGION_PROCESS_COMPLETE });
		if (selectedDistrict) {
			const district = districts.find(({ id }) => id === selectedDistrict.id);
			district && dispatchDistrictAction(district);
			if (district && selectedSubDistrict) {
				const { sub_districts } = district;
				const newSubDistricts = sub_districts.find(
					({ id }) => id === selectedSubDistrict.id
				);
				newSubDistricts && dispatchSubDistrictAction(newSubDistricts);
			}
		}
	}, [region, dispatch, selectedDistrict, districts, selectedSubDistrict]);

	const dispatchDistrictAction = (district: DISTRICT_RESPONSE_SCHEMA) => {
		dispatch({
			type: OverviewActionTypes.SET_SUB_DISTRICTS,
			payload: {
				subDistricts: district.sub_districts,
				district
			}
		});
	};

	const dispatchSubDistrictAction = (subDistrict: SUB_DISTRICT_RESPONSE_SCHEMA) => {
		dispatch({
			type: OverviewActionTypes.SET_COMMUNITIES,
			payload: {
				communities: subDistrict.communities,
				subDistrict
			}
		});
	};

	const onAreaChange = (text: string, level: Level, data: any, value = '') => () => {
		dispatch({
			type: OverviewActionTypes.ADD_AREA_LEVEL,
			payload: {
				title: text,
				value,
				postData: data,
				selectedLevel: level
			}
		});
	};

	const onSubmit = (name: string) => {
		const value = [{ name }];
		const area = {
			[Level.DISTRICT]: { districts: value },
			[Level.SUB_DISTRICT]: { sub_districts: value },
			[Level.COMMUNITY]: { communities: value }
		};
		const data = {
			...postData,
			...(postData.id ? { name } : area[selectedLevel])
		};
		dispatch({ type: OverviewActionTypes.PROCESSING_REGION });
		props.onUpdateRegion(data);
	};

	return (
		<Widget className="region-overview">
			<div className="document-table">
				<div className="document">
					<h3 className="document-title">
						Districts
						<ToolbarButton
							onClick={onAreaChange(
								`Add District for ${region.name}`,
								Level.DISTRICT,
								{
									region_id: region.id,
									url: K.api.routes.ADD_DISTRICTS,
									method: 'POST'
								}
							)}
							iconName="plus"
							content="Add District"
						/>
					</h3>
					{districts.map(district => (
						<LevelItem
							onClick={() => dispatchDistrictAction(district)}
							key={district.id}
							name={district.name}
							selected={Boolean(
								selectedDistrict && district.id === selectedDistrict.id
							)}
							onEdit={onAreaChange(
								'Edit District',
								Level.DISTRICT,
								{
									id: district.id,
									region_id: region.id,
									url: K.api.routes.UPDATE_DISTRICT,
									method: 'PUT'
								},
								district.name
							)}
						/>
					))}
				</div>
				<div className="document">
					<h3 className="document-title">
						Sub Districts
						{selectedDistrict && (
							<ToolbarButton
								onClick={onAreaChange(
									`Add Sub District for ${selectedDistrict.name}`,
									Level.SUB_DISTRICT,
									{
										district_id: selectedDistrict.id,
										url: K.api.routes.ADD_SUB_DISTRICTS,
										method: 'POST'
									}
								)}
								iconName="plus"
								content="Add Sub District"
							/>
						)}
					</h3>
					{subDistricts.map((subDistrict, i) => (
						<LevelItem
							onClick={() => dispatchSubDistrictAction(subDistrict)}
							key={i}
							name={subDistrict.name}
							selected={Boolean(
								selectedSubDistrict && subDistrict.id === selectedSubDistrict.id
							)}
							onEdit={onAreaChange(
								'Edit Sub District',
								Level.SUB_DISTRICT,
								{
									id: subDistrict.id,
									district_id: selectedDistrict && selectedDistrict.id,
									url: K.api.routes.UPDATE_SUB_DISTRICT,
									method: 'PUT'
								},
								subDistrict.name
							)}
						/>
					))}
				</div>
				<div className="document">
					<h3 className="document-title">
						Communities
						{selectedSubDistrict && (
							<ToolbarButton
								onClick={onAreaChange(
									`Add Community for ${selectedSubDistrict.name}`,
									Level.COMMUNITY,
									{
										sub_district_id: selectedSubDistrict.id,
										url: K.api.routes.ADD_COMMUNITY,
										method: 'POST'
									}
								)}
								iconName="plus"
								content="Add Community"
							/>
						)}
					</h3>
					{communities.map((community, i) => (
						<LevelItem
							key={i}
							name={community.name}
							onEdit={onAreaChange(
								'Edit Sub District',
								Level.COMMUNITY,
								{
									id: community.id,
									sub_district_id: selectedSubDistrict && selectedSubDistrict.id,
									url: K.api.routes.UPDATE_COMMUNITY,
									method: 'PUT'
								},
								community.name
							)}
						/>
					))}
				</div>
			</div>
			<AreaLevel
				onClose={() => {
					dispatch({ type: OverviewActionTypes.CLEAR_AREA_LEVEL });
				}}
				onSubmit={onSubmit}
				open={showAreaLevel}
				title={areaLevelTitle}
				value={areaLevelValue}
				loading={loading}
			/>
		</Widget>
	);
};

export default Overview;
