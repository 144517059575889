import React, { useState, useEffect } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { TextField } from '../../../resource/components';

type Props = {
	title: string;
	value: string;
	open: boolean;
	onClose: () => void;
	loading: boolean;
	onSubmit: (val: string) => void;
};

const AreaLevel = (props: Props) => {
	const [name, setName] = useState('');
	const { title, value, open, onClose, loading, onSubmit } = props;

	useEffect(() => {
		setName(value);
	}, [value]);

	const onButtonClick = () => {
		onSubmit(name);
		setName('');
	};

	const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { value: newValue } = e.target as any;
		setName(newValue);
	};

	return (
		<Modal open={open} size="mini" onClose={onClose} closeOnDimmerClick closeOnDocumentClick>
			<Modal.Content>
				<Header style={{ letterSpacing: 0, wordSpacing: 0 }}>{title}</Header>
				<TextField
					value={name}
					type="text"
					placeholder="E.g Tontokrom"
					label="Enter the name of the area"
					onChange={onTextChange}
				/>
				<Button loading={loading} onClick={onButtonClick} color="teal">
					Submit
				</Button>
			</Modal.Content>
		</Modal>
	);
};

AreaLevel.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired
};

AreaLevel.defaultProps = {
	open: false,
	value: '',
	loading: false
};

export default AreaLevel;
