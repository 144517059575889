import React from 'react';
import { Table, Select } from 'semantic-ui-react';
import { CLINIC_RESPONSE_SCHEMA } from '../../../resource/services';

type ClinicRequest = {
	clinic: CLINIC_RESPONSE_SCHEMA;
	requests: string[];
};

type Props = {
	clinicRequests: { [id: string]: ClinicRequest };
	riders: any[];
	onSelectRider: (clinicId: string, riderId: any) => void;
};

const RequestsList = (props: Props) => {
	const { clinicRequests, riders, onSelectRider } = props;

	const renderBody = () => {
		const ids = Object.keys(clinicRequests);
		return ids.map((id, i) => {
			const { clinic, requests } = clinicRequests[id];
			return requests.length > 0 ? (
				<Table.Row key={id}>
					<Table.Cell style={styles.cell}>{i + 1}</Table.Cell>
					<Table.Cell style={styles.cell}>{clinic.name}</Table.Cell>
					<Table.Cell style={styles.cell}>{requests.length}</Table.Cell>
					<Table.Cell style={styles.cell}>
						<Select
							fluid
							basic
							options={riders}
							onChange={(_, { value }) => onSelectRider(id, value)}
							placeholder="Select a rider"
						/>
					</Table.Cell>
				</Table.Row>
			) : null;
		});
	};

	return (
		<Table basic="very">
			<Table.Header>
				<Table.Row style={{ backgroundColor: 'teal' }}>
					<Table.HeaderCell style={{ ...styles.header, width: '1.7rem' }}>
						#
					</Table.HeaderCell>
					<Table.HeaderCell style={styles.header}>Clinic</Table.HeaderCell>
					<Table.HeaderCell style={styles.header}>Requests</Table.HeaderCell>
					<Table.HeaderCell style={styles.header}>Assign Rider</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>{renderBody()}</Table.Body>
		</Table>
	);
};

const styles = {
	header: {
		fontWeight: 'normal',
		fontFamily: 'Open sans',
		fontSize: '1.2rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '1.15rem 1rem'
	}
};

export default RequestsList;
