import React, { ComponentType, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dimmer, Header, Sidebar } from 'semantic-ui-react';
import { Alert, Container, ErrorHandler, MainContent, RestrictedAccess } from '../components';
import { getName } from '../services';
import { AppStore, storeDataForEdit, storeDataInAppStore, uploadToServer } from '../store';
import './css/wraplistview.css';
import useListView from './useListView';
import { ToolBarProps, WrappedListViewOptions as IOptions } from './_helpers';

export const wrappedAdvancedListView = <T, P>(
	List: ComponentType,
	DetailView: ComponentType,
	ToolBar: ComponentType<ToolBarProps> | null,
	options: IOptions,
	deleteFunction?: (data: P) => { url: string; method: string; store: string }
) => {
	const { title, description, form, storeKey, stateKey } = options;
	return (props: any) => {
		const store = useSelector((state: AppStore) => (state as any)[storeKey][stateKey]);
		const user = useSelector(({ user }: AppStore) => user.user);
		const res = useSelector(({ misc }: AppStore) => misc.serverResponse);
		const err = useSelector(({ misc }: AppStore) => misc.error);
		const _isMounted = useRef(false);
		const {
			isLoading,
			active,
			showAlert,
			success,
			direction,
			selectedData,
			sortedColumn,
			onAlert,
			onClose,
			onSelectItem,
			onSuccess
		} = useListView();

		useEffect(() => {
			_isMounted.current = true;
			return () => {
				_isMounted.current = false;
			};
		}, []);

		useEffect(() => {
			if (res) {
				onSuccess();
			}
		}, [res, onSuccess]);

		const renderToolBar = () => {
			return ToolBar && <ToolBar {...props} handleAdd={onAdd} />;
		};

		const onSort = (column: string): void => {};

		const onSelect = (data: any) => {
			onSelectItem(data);
		};

		const onEdit = (data: any) => {
			props.storeDataForEdit(data);
			props.history.push(form);
		};

		const onDelete = (data: any) => {
			onAlert();
		};

		const onAdd = () => {
			props.history.push(form);
		};

		const onConfirm = () => {
			if (success) {
				onClose();
				return;
			}
			const data = selectedData;
			if (!deleteFunction || !selectedData) {
				return;
			}
			props.uploadToServer(deleteFunction(data as any));
		};

		const onCloseView = () => {
			onClose();
		};

		return (
			<RestrictedAccess {...props} user={user}>
				<ErrorHandler error={err} onExit={() => {}}>
					<MainContent title={title} description={description} toolbar={renderToolBar()}>
						<Sidebar.Pushable className="hoc-listView-sidebar">
							<Sidebar
								animation="push"
								direction="right"
								visible={active}
								width="wide"
							>
								<DetailView
									{...props}
									handleClose={onCloseView}
									data={selectedData}
									loading={isLoading}
									handleEdit={onEdit}
									handleDelete={onDelete}
								/>
							</Sidebar>
							<Sidebar.Pusher>
								<Dimmer.Dimmable
									className="hoc-listView-dimmed"
									dimmed={active}
									blurring
								>
									<Dimmer active={active} inverted onClick={onCloseView}>
										<Header
											textAlign="center"
											size="large"
											className="hoc-listView-header"
											color="black"
										>
											{getName(selectedData)}
										</Header>
									</Dimmer>
									<Container>
										<List
											{...props}
											data={store}
											onSort={onSort}
											sortedColumn={sortedColumn}
											sortedDirection={direction}
											onSelect={onSelect}
										/>
									</Container>
								</Dimmer.Dimmable>
							</Sidebar.Pusher>
						</Sidebar.Pushable>
						<Alert
							success={success}
							confirm={!success}
							open={showAlert}
							onClose={onClose}
							onConfirmed={onConfirm}
							title={success ? 'Success' : 'Confirmation'}
							message={
								success
									? 'Deletion successful'
									: `Do you want to delete ${getName(selectedData)}`
							}
						/>
					</MainContent>
				</ErrorHandler>
			</RestrictedAccess>
		);
	};
};

const mapDispatchToProps = {
	storeDataInAppStore,
	storeDataForEdit,
	uploadToServer
};

export default <T extends {}, P>(
	ListView: ComponentType,
	DetailView: ComponentType,
	ToolBar: ComponentType<ToolBarProps> | null,
	options: IOptions,
	deleteFunction?: (data: P) => T & { url: string; method: string; store: string }
) => {
	return connect(
		null,
		mapDispatchToProps
	)(wrappedAdvancedListView(ListView, DetailView, ToolBar, options, deleteFunction));
};
