// import { jsPDF } from 'jspdf';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
	Container,
	EmptyState,
	MainContent,
	RestrictedAccess,
	Widget
} from '../../../resource/components';
import { printHtmlContent } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import '../css/claimform.css';
import NhisScheme from './NHIS';
import Toolbar from './Toolbar';

type Props = RouteComponentProps;

function NHISClaimForm(props: Props) {
	const user = useSelector(({ user }: AppStore) => user.user);
	const sample = useSelector(({ sample }: AppStore) => sample);
	const lab = (user && user.lab_technician.lab) || ({} as any);
	const { selectedRequest: pickedRequest } = sample;

	async function printClaimForm() {
		if (!pickedRequest) return;
		printHtmlContent('report');
	}

	function renderContent() {
		if (!pickedRequest)
			return (
				<EmptyState
					title="No Sample Request"
					description="No sample request has been selected. Please return to the samples page."
				/>
			);
		if (!pickedRequest.insurance)
			return <EmptyState title="No Insurance" description="The client has no insurance." />;
		return (
			<Widget className="claim-form" id="nhis">
				<NhisScheme request={pickedRequest} lab={lab} monthOfClaim={new Date()} />
			</Widget>
		);
	}

	return (
		<RestrictedAccess {...props} user={user}>
			<MainContent
				title="NHIS Claim Form"
				description="Claim form for lab test"
				toolbar={<Toolbar request={pickedRequest} onDownloadForm={printClaimForm} />}
			>
				<Container>{renderContent()}</Container>
			</MainContent>
		</RestrictedAccess>
	);
}

export default NHISClaimForm;
