import React, { Fragment, PureComponent, ReactNode } from 'react';
import { DatePicker, TextField } from '../components';
import { convertToDatePickerFormat } from '../services';

type Event = React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement, MouseEvent>;

interface IFormInputProps {
	[key: string]: any;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IFormInputState {
	showDatePicker: boolean;
	dateEvent: any;
}

export default class FormInput extends PureComponent<IFormInputProps, IFormInputState> {
	public readonly state: IFormInputState = {
		showDatePicker: false,
		dateEvent: null
	};

	public render(): ReactNode {
		return (
			<Fragment>
				{this.props.type === 'date' ? (
					this.renderDatePickerField()
				) : (
					<TextField {...this.props} />
				)}
			</Fragment>
		);
	}

	public renderDatePickerField = (): ReactNode => {
		const { onClick, onChange, ...props } = this.props;
		return (
			<Fragment>
				<TextField {...props} onClick={this.onDateChange} onChange={this.onDateChange} />
				<DatePicker
					open={this.state.showDatePicker}
					onSelect={this.onDateSelect}
					onClose={this.onCloseDatePicker}
				/>
			</Fragment>
		);
	};

	/**
	 * onDateSelect is a method which listens for any date change
	 * in the DatePicker component. it formats the date value into
	 * the default date form for the input element and passes the
	 * event state into the inputChange prop with the attached value
	 * @param data Date
	 * @returns void
	 */
	public onDateSelect = (date: Date) => {
		if (this.state.dateEvent && this.props.onChange) {
			const event = this.state.dateEvent;
			event.target.value = convertToDatePickerFormat(date);
			this.props.onChange(event);
			this.setState(() => ({ showDatePicker: false, dateEvent: null }));
		}
	};

	/**
	 * onDateChange is an event listener on the input field with the date
	 * type. it renders the date picker when the input element is clicked
	 * or the value changes
	 * @param event Events
	 * @returns void
	 */
	public onDateChange = (event: Event) => {
		event.persist();
		this.setState(() => ({ dateEvent: event, showDatePicker: true }));
	};

	public onCloseDatePicker = () => {
		this.setState(() => ({ showDatePicker: false }));
	};
}
