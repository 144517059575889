import React from 'react';
import { Table } from 'semantic-ui-react';
import {
	LAB_TECHNICIAN_RESPONSE_SCHEMA,
	getFullName,
	convertToReadableDate
} from '../../../resource/services';
import { ListViewProps } from '../../../resource/hocs';
import { Widget, TableWrap } from '../../../resource/components';

const TechniciansListView = (props: ListViewProps<LAB_TECHNICIAN_RESPONSE_SCHEMA>) => {
	return (
		<Widget>
			<TableWrap>
				<Table basic="very" sortable selectable>
					<Table.Header>
						<Table.Row style={{ backgroundColor: 'teal' }}>
							<Table.HeaderCell
								style={{ ...styles.header, ...styles.cell, width: '2rem' }}
							>
								#
							</Table.HeaderCell>
							<Table.HeaderCell
								style={{ ...styles.header, ...styles.cell }}
								onClick={() => props.onSort('name')}
								sorted={
									props.sortedColumn === 'name'
										? props.sortedDirection
										: undefined
								}
							>
								Name
							</Table.HeaderCell>
							<Table.HeaderCell
								style={{ ...styles.header, ...styles.cell }}
								onClick={() => props.onSort('community')}
								sorted={
									props.sortedColumn === 'community'
										? props.sortedDirection
										: undefined
								}
							>
								Date of Birth
							</Table.HeaderCell>
							<Table.HeaderCell
								style={{ ...styles.header, ...styles.cell }}
								onClick={() => props.onSort('district')}
								sorted={
									props.sortedColumn === 'district'
										? props.sortedDirection
										: undefined
								}
							>
								Telephone
							</Table.HeaderCell>
							<Table.HeaderCell
								style={{ ...styles.header, ...styles.cell }}
								onClick={() => props.onSort('region')}
								sorted={
									props.sortedColumn === 'region'
										? props.sortedDirection
										: undefined
								}
							>
								Username
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{props.dataToRender.map(
							(technician: LAB_TECHNICIAN_RESPONSE_SCHEMA, i: number) => (
								<Table.Row
									key={technician.id}
									onClick={() => props.onSelect(technician)}
								>
									<Table.Cell>
										{((props.activePage as number) - 1) * props.pageSize +
											i +
											1}
									</Table.Cell>
									<Table.Cell style={styles.cell}>
										{getFullName(technician)}
									</Table.Cell>
									<Table.Cell style={styles.cell}>
										{convertToReadableDate(technician.user.dob)}
									</Table.Cell>
									<Table.Cell style={styles.cell}>
										{technician.user.telephone}
									</Table.Cell>
									<Table.Cell style={styles.cell}>
										{technician.user.username}
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>
			</TableWrap>
		</Widget>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	header: {
		fontWeight: 300,
		fontFamily: 'Open sans',
		fontSize: '1.14rem',
		padding: '0.75rem 1rem',
		color: 'white'
	},
	cell: {
		padding: '0.85rem 1rem'
	}
};

export default TechniciansListView;
