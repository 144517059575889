import React, { HTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import './css/tools.css';

type Props = HTMLAttributes<HTMLParagraphElement> & {
	inverted?: boolean;
	text?: string | number | null;
	danger: boolean;
	warning: boolean;
	nospace: boolean;
	helper: boolean;
	info?: boolean;
};

export const Text = (props: Props) => {
	const { inverted, warning, text, danger, nospace, helper, info, ...other } = props;
	let className = `iw text ${props.className} `;
	className += info ? 'text-box ' : ''; // info styles
	className += warning ? 'warning ' : ''; // warning styles
	className += danger ? 'danger ' : ''; // danger styles
	className += nospace ? 'nospace ' : ''; // remove spacing
	className += helper ? 'helper ' : ''; // helper styling
	return (
		<p {...other} className={className.trim()} style={props.style}>
			{other.children || text}
		</p>
	);
};

Text.propTypes = {
	inverted: PropTypes.bool,
	text: PropTypes.string,
	danger: PropTypes.bool,
	warning: PropTypes.bool,
	nospace: PropTypes.bool,
	helper: PropTypes.bool,
	info: PropTypes.bool
};

Text.defaultProps = {
	inverted: false,
	className: '',
	text: '',
	danger: false,
	warning: false,
	nospace: false,
	helper: false,
	info: false
};
