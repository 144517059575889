import React from 'react';
import { ToolbarButton } from '../../../resource/components';
import { wrappedToolbar } from '../../../resource/hocs';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';

type Props = {
	request: SAMPLES_RESPONSE_SCHEMA | null;
	onDownloadForm: () => void;
};

const Toolbar = (props: Props) => {
	const { request, onDownloadForm } = props;

	return (
		<>
			{request && request.has_insurance && (
				<ToolbarButton iconName="print" content="Print Claim" onClick={onDownloadForm} />
			)}
		</>
	);
};

export default wrappedToolbar(Toolbar);
