import React, { Children } from 'react';
import styled from 'styled-components';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import { AppStore } from '../../../resource/store';
import '../css/claimform.css';
import UserClaimForm from './UserClaimForm';

interface Props {
	requests: SAMPLES_RESPONSE_SCHEMA[];
	user: NonNullable<AppStore['user']['user']>;
	printId: string;
	isRendered: boolean;
	monthOfClaim: string | Date;
}

const PrintNhisClaims: React.FC<Props> = ({
	requests,
	monthOfClaim,
	isRendered,
	user,
	printId
}) => {
	function renderContent() {
		if (!isRendered) return null;
		const claims = requests.map((request) => (
			<UserClaimForm request={request} monthOfClaim={monthOfClaim} user={user} />
		));
		return Children.toArray(claims);
	}

	return <PrintablePage id={printId}>{renderContent()}</PrintablePage>;
};

const PrintablePage = styled.div`
	display: none;
	@media print {
		section {
			page-break-after: always;
		}
	}
`;

export default PrintNhisClaims;
