import React from 'react';
import { SAMPLES_RESPONSE_SCHEMA } from '../../../resource/services';
import ClientInformation from './ClientInformation';
import ClientSummary from './ClientSummary';
import Heading from './Heading';
import Investigations from './Investigations';
import ServicesProvided from './ServicesProvided';

interface IProps {
	request: SAMPLES_RESPONSE_SCHEMA;
	lab: any;
	style?: React.CSSProperties;
	monthOfClaim: string | Date;
}

function NhisScheme({ request, lab, style = {}, monthOfClaim }: IProps) {
	return (
		<div id="report" style={{ position: 'relative', ...style }}>
			<div
				style={{
					width: '530pt',
					fontSize: '10pt',
					lineHeight: '10pt',
					fontFamily: 'sans-serif'
				}}
			>
				<Heading lab={lab} request={request} monthOfClaim={monthOfClaim} />
				<ClientInformation request={request} />
				<ServicesProvided request={request} />
				<Investigations request={request} />
				<ClientSummary request={request} lab={lab} />
			</div>
		</div>
	);
}

export default NhisScheme;
