import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Access } from '../resource/services';
import { AppStore } from '../resource/store';
import { routes } from './routes';

const App = () => {
	const user = useSelector(({ user }: AppStore) => user.user);

	useEffect(() => {
		Access.subscribe(user);
		return () => {
			Access.unsubscribe();
		};
	}, [user]);

	return (
		<Switch>
			{routes.map((route, index) => (
				<Route key={index} {...route} />
			))}
		</Switch>
	);
};

export default App;
