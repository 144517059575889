import isEmpty from 'lodash/isEmpty';
import {
	REGION_RESPONSE_SCHEMA,
	DISTRICT_RESPONSE_SCHEMA,
	SUB_DISTRICT_RESPONSE_SCHEMA,
	COMMUNITY_RESPONSE_SCHEMA
} from '../../../resource/services';

export class RegionFactory {
	public static EMPTY_COMMUNITY: COMMUNITY_RESPONSE_SCHEMA = {
		name: '',
		id: ''
	};

	public static EMPTY_SUB_DISTRICT: SUB_DISTRICT_RESPONSE_SCHEMA = {
		id: '',
		name: '',
		communities: [RegionFactory.EMPTY_COMMUNITY]
	};

	public static EMPTY_DISTRICT: DISTRICT_RESPONSE_SCHEMA = {
		name: '',
		id: '',
		region_id: '',
		sub_districts: [RegionFactory.EMPTY_SUB_DISTRICT]
	};

	public static EMPTY_REGION: REGION_RESPONSE_SCHEMA = {
		id: '',
		name: '',
		districts: [RegionFactory.EMPTY_DISTRICT]
	};

	public static getRegion = (region: REGION_RESPONSE_SCHEMA | null) => {
		if (!region) {
			return RegionFactory.EMPTY_REGION;
		}
		const data = region;
		if (isEmpty(data.districts)) {
			data.districts = [RegionFactory.EMPTY_DISTRICT];
		} else {
			data.districts = data.districts.map(district => {
				if (isEmpty(district.sub_districts)) {
					district.sub_districts = [RegionFactory.EMPTY_SUB_DISTRICT];
				} else {
					district.sub_districts = district.sub_districts.map(sub => {
						if (isEmpty(sub.communities)) {
							sub.communities = [RegionFactory.EMPTY_COMMUNITY];
						}
						return sub;
					});
				}
				return district;
			});
		}

		return data;
	};
}
