import PropTypes from 'prop-types';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import curvy from '../../resource/assets/images/curvy.svg';
import { ErrorHandler } from '../../resource/components';
import { Constants } from '../../resource/constants';
import { DataStorage } from '../../resource/dataService';
import { Access, USERS_RESPONSE_SCHEMA } from '../../resource/services';
import { AppStore } from '../../resource/store';
import './css/signin.css';
import { SignInForm } from './SignInForm';
import { authenticateUser, clearUserState } from './_helpers';

interface SignInProps extends RouteComponentProps {
	user: AppStore['user']['user'];
	signInUser: (data: any) => void;
	error: any;
	clearUserState: () => void;
}

interface SignInState {
	username: string;
	password: string;
	isDisabled: boolean;
	[x: string]: any;
	isActive: boolean;
}

export class SignIn extends Component<SignInProps, SignInState> {
	private _isMounted = false;
	public readonly state: SignInState = {
		username: '',
		password: '',
		isDisabled: true,
		isActive: false
	};

	public static propTypes: PropTypes.InferProps<SignInProps> = {
		signInUser: PropTypes.func.isRequired,
		user: PropTypes.oneOfType([
			PropTypes.shape<PropTypes.ValidationMap<USERS_RESPONSE_SCHEMA>>({}),
			PropTypes.any
		]),
		error: PropTypes.any,
		clearUserState: PropTypes.func.isRequired
	};

	public componentWillReceiveProps(props: SignInProps) {
		if (this.props !== props) {
			this.setState(() => ({ isActive: false }));
			if (props.user) {
				Access.subscribe(props.user);
				this.props.history.push(Constants.app.routes.dashboard.DASHBOARD);
			}
		}
	}

	public componentDidMount() {
		this._isMounted = true;
		const active = DataStorage.getActive();
		if (active && Object.keys(active).length > 1) {
			this.props.history.push(Constants.app.routes.dashboard.DASHBOARD);
		}
	}

	public render(): ReactNode {
		return (
			<ErrorHandler error={this.props.error} onExit={this.onErrorExit}>
				<div className="signin" style={styles}>
					<div className="container-wrapper">
						<Header className="title subbed" textAlign="center">
							{Constants.app.APP_NAME}
						</Header>
						<SignInForm
							username={this.state.username}
							password={this.state.password}
							disabled={this.state.isDisabled}
							active={this.state.isActive}
							onTextChange={this.handleTextChange}
							onSubmit={this.handleBtnClick}
						/>
					</div>
				</div>
			</ErrorHandler>
		);
	}

	public componentWillUnmount() {
		this._isMounted = false;
	}

	public handleIconClick = (): void => {
		this.props.history.push(Constants.app.routes.HOME);
	};

	public handleTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
		event.persist();
		this.setState(
			() => ({
				[event.target.name]: event.target.value
			}),
			() => {
				this.setState((prevState: Readonly<SignInState>) => ({
					isDisabled: !Boolean(prevState.username && prevState.password)
				}));
			}
		);
	};

	public handleBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.preventDefault();
		this.setState(() => ({ isActive: true }));
		this.props.clearUserState();
		this.props.signInUser({
			username: this.state.username,
			password: this.state.password
		});
	};

	public onErrorExit = (): void => {
		this.props.clearUserState();
	};
}

const styles: React.CSSProperties = {
	backgroundImage: `url(${curvy})`
};

const mapStateToProps = (state: AppStore) => ({
	user: state.user.user,
	error: state.user.error
});

export default connect(
	mapStateToProps,
	{
		signInUser: authenticateUser,
		clearUserState
	}
)(SignIn);
