import { Formik } from 'formik';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Location } from 'react-location-picker';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Header } from 'semantic-ui-react';
import {
	Alert,
	Container,
	ErrorHandler,
	MainContent,
	RestrictedAccess,
	Text,
	Widget
} from '../../../resource/components';
import { useLocationSelector } from '../../../resource/hooks';
import { getDateTimestamp, postLabForm } from '../../../resource/services';
import { AppStore, Lab as LabStateActions } from '../../../resource/store';
import { Constants, Lab, LabAdmin, labFormInitialState, labFormReducer } from '../_helpers';
import './css/index.css';
import LabAdminDetails from './LabAdminDetails';
import LabDetails from './LabDetails';
import LabFormActions from './LabFormActions';

interface Props extends RouteComponentProps {}

const LabForm: React.FC<Props> = props => {
	const user = useSelector(({ user }: AppStore) => user.user);
	const lab = useSelector(({ lab }: AppStore) => lab.lab);
	const storeDispatch = useDispatch();

	const [state, dispatch] = useReducer(labFormReducer, labFormInitialState);
	const formRef = useRef<any>(null);
	const [location, setLocation] = useState({ lat: 0, lng: 0 });
	const { region, community, district, sub_district } = state.lab;
	const area = useLocationSelector({ region, community, district, subDistrict: sub_district });

	const { activePage, status, error } = state;

	useEffect(() => {
		if (lab) {
			const admin = lab.admin.user;
			dispatch({
				type: 'SET LABORATORY DETAILS',
				payload: {
					lab: {
						name: lab.name,
						telephone: lab.telephone,
						community: lab.community,
						district: lab.district,
						region: lab.region,
						sub_district: lab.sub_district
					},
					admin: {
						dob: admin.dob,
						name: admin.first_name,
						otherName: admin.middle_name || '',
						gender: admin.gender,
						password: '',
						surname: admin.surname,
						telephone: admin.telephone,
						username: admin.username
					}
				}
			});
		}
	}, [lab]);

	useEffect(() => {
		return () => {
			dispatch({ type: 'RESET LABORATORY DETAILS' });
			storeDispatch({ type: LabStateActions.REMOVE_STORED_LABORATORY });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lab && lab.gps_coordinates) {
			setLocation({
				lat: lab.gps_coordinates.latitude,
				lng: lab.gps_coordinates.longitude
			});
		} else {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(position => {
					setLocation({
						lat: position.coords.latitude,
						lng: position.coords.longitude
					});
				});
			}
		}
	}, [lab]);

	const onPageChange = (isNextPage: boolean) => {
		const nextPage = isNextPage ? activePage + 1 : activePage - 1;
		dispatch({ type: 'CHANGE ACTIVE PAGE', payload: nextPage });
	};

	const onCancel = () => {
		dispatch({ type: 'RESET LABORATORY DETAILS' });
		props.history.push(Constants.app.routes.dashboard.LABS);
	};

	const onSubmit = async (data: { lab: Lab; admin: LabAdmin }) => {
		try {
			const { otherName, name, ...admin } = data.admin;
			const labFormData = {
				name: data.lab.name,
				telephone: data.lab.telephone,
				community_id: area.communityId,
				gps_coordinates: {
					longitude: location.lng,
					latitude: location.lat
				},
				'lab-admin': {
					user: {
						...admin,
						first_name: name,
						middle_name: otherName,
						dob: getDateTimestamp(admin.dob)
					}
				}
			};
			const response = await postLabForm(labFormData, (lab && lab.id) || '');
			let type = LabStateActions.UPDATE_STORED_LABORATORY;
			const payload = {
				...(response.lab || response),
				admin: { user: labFormData['lab-admin'].user }
			};
			if (response.admin.user) {
				payload.admin.user = response.admin.user;
				type = LabStateActions.ADD_LAB_SUCCESS;
			}
			storeDispatch({
				type,
				payload
			});
			dispatch({ type: 'SET FORM SUBMISSION STATUS', payload: true });
		} catch (error) {
			dispatch({ type: 'SET LAB FORM ERROR', payload: error });
		}
	};

	const onErrorExit = () => dispatch({ type: 'REMOVE LAB FORM ERROR' });

	const onLocationChange = ({ position }: Location) => {
		setLocation({
			lat: position.lat,
			lng: position.lng
		});
	};

	return (
		<RestrictedAccess {...props} user={user}>
			<ErrorHandler error={error} onExit={onErrorExit}>
				<MainContent title="HEALTH FACILITY">
					<Container>
						<Widget className="form-widget">
							<Header textAlign="center" className="header">
								HEALTH FACILITY
							</Header>
							<Formik
								initialValues={{ lab: state.lab, admin: state.admin }}
								onSubmit={onSubmit}
								ref={formRef}
								enableReinitialize
							>
								{formikProps => (
									<form
										style={{ maxWidth: 700, margin: 'auto' }}
										onSubmit={formikProps.handleSubmit}
									>
										<Text style={{ margin: '1.2rem 0' }}>
											Fill in the required fields for the health facility
										</Text>
										<LabDetails
											{...formikProps.values.lab}
											{...area}
											handleChange={formikProps.handleChange}
											pageNumber={1}
											activePage={activePage}
											location={location}
											onLocationChange={onLocationChange}
										/>
										<LabAdminDetails
											{...formikProps.values.admin}
											handleChange={formikProps.handleChange}
											pageNumber={2}
											activePage={activePage}
										/>
										<LabFormActions
											activePage={activePage}
											onPageChange={onPageChange}
											pages={2}
											onCancel={onCancel}
											loading={formikProps.isSubmitting}
											disabled={formikProps.isValid}
										/>
									</form>
								)}
							</Formik>
						</Widget>
					</Container>
					<Alert
						success
						open={status}
						onConfirmed={onCancel}
						title="Successful"
						message="The form has been uploaded successfully to the server."
					/>
				</MainContent>
			</ErrorHandler>
		</RestrictedAccess>
	);
};

export default LabForm;
