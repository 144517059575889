import isObject from 'lodash/isObject';
import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'semantic-ui-react';
import {
	COMMUNITY_RESPONSE_SCHEMA,
	convertListToDropdownItems,
	DISTRICT_RESPONSE_SCHEMA,
	getLevelsFromName,
	REGION_RESPONSE_SCHEMA,
	SUB_DISTRICT_RESPONSE_SCHEMA
} from '../services';
import { AppStore } from '../store';

type Props = {
	onLevelSelect: (data: any) => void;
	name: string;
	onlyDistrict?: boolean;
	onlySubDistrict?: boolean;
	showAll: boolean;
	value?: any;
};

type State = {
	regions: REGION_RESPONSE_SCHEMA[] | null;
	loadingRegions: boolean;
	regionId: string;
	districts: DISTRICT_RESPONSE_SCHEMA[];
	districtId: string;
	subDistricts: SUB_DISTRICT_RESPONSE_SCHEMA[];
	subDistrictId: string;
	communities: COMMUNITY_RESPONSE_SCHEMA[];
	communityId: string;
};

const LevelSelectorActionTypes = {
	STORE_REGIONS_IN_STATE: 'STORE_REGIONS_IN_STATE',
	INITIAILIZING_STATE: 'INITIAILIZING_STATE',
	STORE_SELECTED_REGION: 'STORE_SELECTED_REGION',
	STORE_SELECTED_DISTRICT: 'STORE_SELECTED_DISTRICT',
	STORE_SELECTED_SUB_DISTRICT: 'STORE_SELECTED_SUB_DISTRICT',
	GET_REGION_TO_STATE: 'GET_REGION_TO_STATE',
	STORE_SELECTED_COMMUNITY: 'STORE_SELECTED_COMMUNITY'
};

const initialState: State = {
	regions: null,
	loadingRegions: false,
	regionId: '',
	districts: [],
	districtId: '',
	subDistricts: [],
	subDistrictId: '',
	communities: [],
	communityId: ''
};

const getRegionFromValue = (regions: REGION_RESPONSE_SCHEMA[], data: any) => {
	const getLevelFromRegion = getLevelsFromName(regions);
	const values: any = {};
	if (data && isObject(data.district)) {
		const { id: districtId, region_id } = data.district;
		const [region] = regions.filter(({ id }) => id === region_id);
		values.regionId = region_id;
		values.districtId = districtId;
		values.districts = region.districts;
	}
	if (data && typeof data.region === 'string') {
		const { region: reg, district: dist, sub_district: sub, community: comm } = data;
		const level = getLevelFromRegion(reg)(dist)(sub)(comm);
		const { region, district, subDistrict, community } = level;
		values.regionId = region.id;
		values.districtId = district.id;
		values.subDistrictId = subDistrict.id;
		values.communityId = community.id;
		values.districts = district.items;
		values.subDistricts = subDistrict.items;
		values.communities = community.items;
	}
	return values;
};

const levelSelectorReducer = (state = initialState, { type, payload }: any): State => {
	switch (type) {
		case LevelSelectorActionTypes.INITIAILIZING_STATE:
			return { ...state, loadingRegions: true };

		case LevelSelectorActionTypes.STORE_REGIONS_IN_STATE:
			return { ...state, ...initialState, loadingRegions: false, regions: payload };

		case LevelSelectorActionTypes.STORE_SELECTED_REGION:
			const [region] = (state.regions as REGION_RESPONSE_SCHEMA[]).filter(
				({ id }) => payload === id
			);
			const { regions, ...other } = initialState;
			return {
				...state,
				...other,
				regionId: payload,
				districts: region.districts
			};

		case LevelSelectorActionTypes.STORE_SELECTED_DISTRICT:
			const [district] = (state.districts as DISTRICT_RESPONSE_SCHEMA[]).filter(
				({ id }) => id === payload
			);
			return {
				...state,
				districtId: payload,
				subDistricts: district.sub_districts,
				subDistrictId: '',
				communityId: '',
				communities: []
			};

		case LevelSelectorActionTypes.STORE_SELECTED_SUB_DISTRICT:
			const [subDistricts] = state.subDistricts.filter(({ id }) => id === payload);
			return {
				...state,
				subDistrictId: payload,
				communities: subDistricts.communities,
				communityId: ''
			};

		case LevelSelectorActionTypes.GET_REGION_TO_STATE:
			return { ...state, ...payload };

		case LevelSelectorActionTypes.STORE_SELECTED_COMMUNITY:
			return { ...state, communityId: payload };

		default:
			return state;
	}
};

const LevelSelector = (props: Props) => {
	const store = useSelector((store: AppStore) => store.regional.regions);
	const [state, dispatch] = useReducer(levelSelectorReducer, { ...initialState, regions: store });
	const {
		regions,
		districts,
		subDistricts,
		communities,
		subDistrictId,
		districtId,
		communityId,
		regionId
	} = state;
	const { value, name, onlyDistrict, onlySubDistrict, showAll, onLevelSelect } = props;

	useEffect(() => {
		dispatch({ type: LevelSelectorActionTypes.INITIAILIZING_STATE });
	}, [dispatch]);

	useEffect(() => {
		if (value && regions) {
			dispatch({
				type: LevelSelectorActionTypes.GET_REGION_TO_STATE,
				payload: getRegionFromValue(regions, value)
			});
		}
	}, [value, dispatch, regions]);

	const onAreaLevelChange = (id: any) => {
		const data = {
			[name]: onlySubDistrict
				? { sub_district_id: id }
				: onlyDistrict
				? { district_id: id }
				: { community_id: id }
		};
		onLevelSelect(data);
	};

	return (
		<>
			<Select
				options={convertListToDropdownItems(regions || [])}
				fluid
				placeholder="Select a region"
				value={regionId}
				onChange={(_, data) => {
					dispatch({
						type: LevelSelectorActionTypes.STORE_SELECTED_REGION,
						payload: data.value
					});
				}}
			/>
			{(onlyDistrict || showAll) && (
				<Select
					options={convertListToDropdownItems(districts)}
					fluid
					placeholder="Select a district"
					value={districtId}
					onChange={(_, data) => {
						dispatch({
							type: LevelSelectorActionTypes.STORE_SELECTED_DISTRICT,
							payload: data.value
						});
						onAreaLevelChange(data.value);
					}}
				/>
			)}
			{(onlySubDistrict || showAll) && (
				<>
					<Select
						options={convertListToDropdownItems(subDistricts)}
						fluid
						placeholder="Select a sub district"
						value={subDistrictId}
						onChange={(_, data) => {
							dispatch({
								type: LevelSelectorActionTypes.STORE_SELECTED_SUB_DISTRICT,
								payload: data.value
							});
							onAreaLevelChange(data.value);
						}}
					/>
					<Select
						options={convertListToDropdownItems(communities)}
						fluid
						placeholder="Select a community"
						value={communityId}
						onChange={(_, data) => {
							dispatch({
								type: LevelSelectorActionTypes.STORE_SELECTED_COMMUNITY,
								payload: data.value
							});
							onAreaLevelChange(data.value);
						}}
					/>
				</>
			)}
		</>
	);
};

export default LevelSelector;
