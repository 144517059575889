import { Dispatch as Action, USER_SIGN_SCHEMA } from '../../services';
import { Constants as K } from '../../constants';

export const User = {
	STORE_USER_TYPE: '@store/store-user',
	CLEAR_USER_TYPE: '@store/clear-user-state',
	USER_VERIFIED_TYPE: '@store/verified-account',
	USER_INVALID_TYPE: '@store/invalid-account'
};

export interface UserState {
	user: USER_SIGN_SCHEMA | null;
	error: any;
}

const defaultState: UserState = {
	user: null,
	error: null
};

export default (state: UserState = defaultState, action: Action) => {
	switch (action.type) {
		case User.STORE_USER_TYPE:
			return {
				...state,
				user: action.payload,
				error: null
			};

		case User.USER_INVALID_TYPE:
			return { ...state, user: null, error: action.payload };

		case K.store.types.CLEAR_USER_CACHE:
			return { ...state, ...defaultState };

		case User.CLEAR_USER_TYPE:
			return { ...state, ...defaultState };

		// stores the new user data in the user state
		case K.store.types.STORE_USER_DATA_TYPE:
			return { ...state, error: null, user: action.payload };

		default:
			return state;
	}
};
