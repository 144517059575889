import React from 'react';
import { ToolbarButton } from '../../../resource/components';
import { wrappedToolbar } from '../../../resource/hocs';

interface Props {
	onDownloadPdf: () => void;
	onDownloadExcel: () => void;
	onCashReport: () => void;
	isCashReport: boolean;
	disableToolbarActions: boolean;
	onPrintClaims: () => void;
}

const Toolbar: React.FC<Props> = ({
	onDownloadPdf,
	onDownloadExcel,
	onCashReport,
	isCashReport,
	disableToolbarActions,
	onPrintClaims
}) => {
	return (
		<>
			<ToolbarButton
				iconName="money"
				content={!isCashReport ? 'Cash Report' : 'NHIS Report'}
				onClick={onCashReport}
				disabled={disableToolbarActions}
			/>
			<ToolbarButton
				iconName="print"
				disabled={disableToolbarActions}
				content="Print NHIS Claims"
				onClick={onPrintClaims}
			/>
			<ToolbarButton
				iconName="download"
				disabled={disableToolbarActions}
				content="Download Pdf"
				onClick={onDownloadPdf}
			/>
			<ToolbarButton
				iconName="file excel"
				content="Download Excel"
				onClick={onDownloadExcel}
				disabled={disableToolbarActions}
			/>
		</>
	);
};

export default wrappedToolbar(Toolbar);
